import React, { ChangeEvent, ReactNode } from "react";
import { useClasses } from "../../hooks/useClasses"

interface CheckboxProps {
  label: string | ReactNode
  field: string
  required?: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const Checkbox = ({ label, field, required, onChange }: CheckboxProps) => {
    const classes = useClasses(["input-checkbox-field", {"required": required }])

    return (
        <div className={classes}>
            <input type="checkbox" id={field} required={required} onChange={(e) => onChange(e)}/>
            <label htmlFor={field}>{label}</label>
        </div>
    );
};

export default Checkbox;