//const current = (window.location.hostname).match(/local|cpv/gi);
const apiEndpoint: string = import.meta.env.VITE_APIENDPOINT as string
const vpThreshold: number = import.meta.env.VITE_VP_THRESHOLD as number
const tokenxUrl: string = import.meta.env.VITE_APIENDPOINT as string
const frontDomain: string = import.meta.env.VITE_FRONT_DOMAIN as string

export const environment = {
    api: apiEndpoint,
    vpThreshold: vpThreshold,
    tokenxUrl: tokenxUrl,
    checkoutPath: `/checkout`,
    url: frontDomain,
    policy: 'https://api.visi-global.com/client/media/images/return_and_cancellation_policy07222024.pdf',
    policyName: 'Return Policy'
} 