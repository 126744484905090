import { createContext, useState } from "react"
import { IReactNodeProviderChildren } from "../interfaces/provider-base.interface"

interface IResetPasswordProviderContext
{
    username: string
    currentState: string
    loading: boolean
    ready: boolean
    msg: string
    codeToken: string
    setMsg?: any
    setCurrentState?: any
    setLoading?: any
    setReady?: any
    setUsername?: any
    setCode?: any
    setCodeToken?: any
    setMsgType?: any
    msgType: string
}

export const ResetPasswordProviderContext = createContext<IResetPasswordProviderContext>({
    username: '',
    currentState: '',
    loading: false,
    ready: false,
    msg: '',
    codeToken: '',
    msgType: ''
})

export const ResetPasswordProvider = ({ children }: IReactNodeProviderChildren) => {
    const [ username, setUsername ] = useState<string>('')
    const [ currentState, setCurrentState ] = useState<string>('login')
    const [ msg, setMsg ] = useState<string>('')
    const [ msgType, setMsgType ] = useState<string>('success')
    const [ codeToken, setCodeToken ] = useState<string>('')
    const [ loading, setLoading ] = useState<boolean>(false)
    const [ ready, setReady ] = useState<boolean>(true)

    return (
        <ResetPasswordProviderContext.Provider value={{
            username, setUsername,
            currentState, setCurrentState,
            loading, setLoading,
            ready, setReady,
            msg, setMsg,
            codeToken, setCodeToken,
            msgType, setMsgType
        }}>
            { children }
        </ResetPasswordProviderContext.Provider>
    )
}