import { memo } from "react";
import img1 from './images/5.png'
import img2 from './images/6.png'
import img3 from './images/7.png'
import { NavLink } from "react-router-dom";

const HeroSetUpAccount = memo(function HeroSetUpAccount(props?: any) {
    return (
    <div className="wallet wallet4 pad--lg">
        <div className="container container--sm">
            <h2 className="title">set up a secure account</h2>
            <p className="mini-p">Putting your cryptocurrency to work for you is as easy as 1, 2, 3. With your Savvy Wallet you have access to multiple currencies like never before.</p>
            <div className="row">
                <div className="col-md-4">
                    <div className="plainicon">
                        <img src={img1} alt="" />
                        <p>1. Select the membership that best suits you.</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="plainicon">
                        <img src={img2} alt="" />
                        <p>2. Complete the KYC application.</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="plainicon">
                        <img src={img3} alt="" />
                        <p>3. Begin transferring fiat to your account to start accessing multiple currencies.</p>
                    </div>
                </div>
            </div>
            <NavLink to="/savvy-wallet/compare" className="button">View Wallets</NavLink>
        </div>
    </div>
    )
})

export default HeroSetUpAccount