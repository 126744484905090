import { useContext } from 'react'
import { CartCheckoutLoggedIn } from './loggedin'
import { CartCheckoutLoggedOut } from './loggedout'
import { AccountContext } from '../../providers/account'
import ProductMast from "../../components/shopping-cart/product-mast"

export default function PageCartCheckout(props?: any) {
    const { account } = useContext(AccountContext)
    const cid = account?.customerID

    return (
        <div id="page-checkout">
            <ProductMast title="Checkout" />
            { cid? <CartCheckoutLoggedIn /> : <CartCheckoutLoggedOut /> }
        </div>
    )
}