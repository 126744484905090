import { IResponse } from "../interfaces/http.interface"
import HttpClient from "./http"

export const PasswordResetCodeService = (code: string, username: string, token: string): Promise<IResponse<any>> => {
    return (new HttpClient).post<IResponse<any>>('reset-password/code', { code, username, token })
}

export const PasswordResetCodeSendNotifyService = (username: string): Promise<IResponse<any>> => {
    return (new HttpClient).get<IResponse<any>>('reset-password/code', { username })
}

export const PasswordResetPasswordService = (username: string, password: string, token: string): Promise<IResponse<any>> => {
    return (new HttpClient).post<IResponse<any>>('reset-password/password', { username, password, token })
}