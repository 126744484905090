import { useContext } from 'react'
import { ShoppingCartEngineContext } from '../../providers/shopping-cart-engine'
import PageShipAddress from '../ship-address'
import { itemsInCart } from '../../services/cart'
import { AlertGeneral } from '../../components/alert'

export const CartCheckoutLoggedIn = (props?: any) =>
{
    const { siteOwner } = useContext(ShoppingCartEngineContext)
    return (
        <div className={ `col-count-3 offset ${itemsInCart()? 'bkg-beige' : '' }`}>
            <div className="start2">
                { (itemsInCart())? <PageShipAddress /> : <div className="align-middle"><AlertGeneral type="info" content="Your cart is currently empty" /></div> }
            </div>
        </div>
    )
}