import { cacheGet, cache } from '../services/cache'
import { ICountry } from '../interfaces/country.interface'

const lsName = 'site.owner'
const prodFilterWH = `filter.product.wh`
const countryName = 'site.country'
const countries = 'site.settings.countries'

export const getSubDomain = () => {
    const hostnameParts = window.location.hostname.split('.');
    if (hostnameParts.length >= 3) {
      return hostnameParts[0]
    }
    return ''
}

export const toDistDomain = (subDomain: string) => {
    let hostnameParts = window.location.hostname.split('.');
    if (hostnameParts.length >= 3) {
      hostnameParts[0] = subDomain
    } else {
        return ''
    }
    return hostnameParts.join('.')
}

export const setSiteEnroller = (enrollerId: number | string | null) => {
    if(enrollerId === null) {
        cache(lsName, '', false)
        return false
    }

    let e = enrollerId.toString().replace(/[^\w]/gi, '')
    if(getSiteEnroller() !== e) {
        if(e.match(/localhost|www/gi)) {
            e = ''
        }
        cache(lsName, e, false)
    }
}

export const getSiteEnroller = (): string => cacheGet(lsName, false)

export const setProductFilterWH = (data: any[]) => cache(prodFilterWH, JSON.stringify(data), false)

export const getProductFilterWH = (): string[] => JSON.parse(cacheGet(prodFilterWH, false, '[]'))

export const setSiteCountry = (co: ICountry) => cache(countryName, JSON.stringify(co), false)

export const getSiteCountry = (): ICountry => {
    let co = cacheGet(countryName, false)
    if(co !== '') {
        return JSON.parse(co)
    } else {
        return {
            name: "United States",
            code: "US",
            flag: "https://flagcdn.com/us.svg"
        }
    }
}

export const getCountries = (): any => {
    const c = cacheGet(countries, false)
    if(c === '')
        return null
    return JSON.parse(c)
}

export const storeCountries = (countryList: any) => cache(countries, JSON.stringify(countryList))
