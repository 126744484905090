
interface IProductMast
{
    title: string
    shopby?: string
    shopbyLink?: string
    productFamily?: string
    productFamilyLink?: string
}

export default function ProductMast(props: IProductMast)
{
    const filter = [
        'cleanse-and-detox',
        'cleanse',
        'energy',
        'immune-support',
        'mood',
        'overall-wellness',
        'weightloss',
        '28-day-health',
        'collagen-bundle',
        'weightloss-bundle',
        'gut-and-glowup-bundle',
        'limited-time-offers',
        'lines-and-wrinkles',
        'exfoliate',
        'teeth-whitening',
        'shop-all-in-beauty',
        'relaxation',
        'focus',
        'healing',
        'odor-eliminator',
        'meditation',
        'skin-health',
        'shop-all-in-oils',
        'shop-all-in-digital',
        'snack'
    ]

    const exists = filter.includes(props.title)

    return (
        exists? <div className="banner3 ss ss1 gallery-product-mast">
            <div className="container">
                <h1 className="title">{props.title.replaceAll('-', ' ')}</h1>
                { props.shopby? <div className="breadcrumb-nav-holder">
                    <ul>
                        <li className="breadcrumb-item"><a href={ props.shopbyLink }>Shop By { props.shopby.replaceAll('-', ' ') }</a></li>
                        <li className="breadcrumb-item current"><a href={props.productFamilyLink} className="uptxtfo">{ props.productFamily?.replaceAll('-', ' ') }</a></li>
                    </ul>
                </div> :  null }
            </div>
        </div> : null
    )
}