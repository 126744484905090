import { memo } from "react";

import probita from './images/probita.png'
import mikla from './images/mikla.png'
import oils from './images/oils.png'
import './styles/styles.scss'
import { ButtonBuyCollagen } from "../../shopping-cart/button";
import { NavLink } from "react-router-dom";

export const HeroMainProductMast = memo(function HeroMainProductMast(props?: any) {
    return (
        <div className="hm1 pad main-product-mast">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="box1">
                            <img src={probita} alt="" />
                            <div className="box1__gradient"></div>
                            <div className="box1__inner">
                                <h2 className="boxtitle">Sip and Snack</h2>
                                <p>Your way to healthier skin &amp; less aches and pain the way nature wanted</p>
                                <ButtonBuyCollagen />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="box1">
                            <img src={mikla} alt="" />
                            <div className="box1__gradient"></div>
                            <div className="box1__inner">
                                <h2 className="boxtitle">Scandinavian secret</h2>
                                <p>Arctic Cloudberry is the key to flawless skin</p>
                                <NavLink to="/product/benefit/beauty/all" className='button corp'>SHOP</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="box1">
                            <img src={oils} alt="" />
                            <div className="box1__gradient"></div>
                            <div className="box1__inner">
                                <h2 className="boxtitle">Complete oils</h2>
                                <p>Get more than just nice smelling oils</p>
                                <NavLink to='/product/benefit/oils/all' className='button corp'>SHOP OILS</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default HeroMainProductMast