import { useContext } from 'react'
import { useNavigate } from "react-router"
import { Button, CircularProgress } from '@mui/material'
import { dollars } from "../../../helpers/currency"
import { ShoppingCartEngineContext } from "../../../providers/shopping-cart-engine"
import { itemsInCart } from '../../../services/cart'
import { AccountContext } from '../../../providers/account'

interface CartSummaryTotalBox
{
    buttonOption?: boolean
}

export function CartSummaryTotalBox(props: CartSummaryTotalBox)
{
    const showButton = (typeof props.buttonOption !== "undefined")? props.buttonOption : true
    const { order, orderReady } = useContext(ShoppingCartEngineContext)
    const { account } = useContext(AccountContext)
    const navigate = useNavigate()
    const valuesIfAvialable = (v: number): string => account?.customerID? dollars(v || 0) : (parseFloat((v || '0').toString()) > 0)? dollars(v || 0) : 'TBD'
    return (
        <div>
            <div className={`shadowed m-4 p-3 order-summary-table-container ${!orderReady? 'disabled' : ''}`}>
                <table>
                    <tbody>
                        <tr>
                            <th className="summary-title" colSpan={2}> { showButton? <Button variant="text" className="p-0 m-0 mr-2" onClick={() => navigate(-1)}><i className="fas fa-chevron-left"></i></Button> : null } Order Summary</th>
                        </tr>
                        {/* <tr>
                            <td>SRP Total</td><td>{ dollars(order.) }</td>
                        </tr> */}
                        {/* <tr>
                            <td>Total RV</td><td>{ order?.cv || 0 }</td>
                        </tr> */}
                        {/* { props.data.totalSavings?
                        <tr className="highlight">
                            <td>Total Savings</td><td>{ dollars(props.data.totalSavings) }</td>
                        </tr> : null } */}
                        <tr>
                            <td>Total CV/RV</td><td>{ order?.commissionableVolumeTotal || 0 }</td>
                        </tr>
                        <tr>
                            <td>Subtotal</td><td>{ dollars(order?.subTotal || 0) }</td>
                        </tr>
                        <tr>
                            <td>Taxes</td><td>{ valuesIfAvialable(order?.taxTotal || 0) }</td>
                        </tr>
                        <tr>
                            <td>Shipping</td><td>{ valuesIfAvialable(order?.shippingTotal || 0) }</td>
                        </tr>
                        <tr>
                            <td>Total</td><td>{ dollars(order?.total || 0) }</td>
                        </tr>
                    </tbody>
                </table>
            </div>
             { orderReady?
                <div className="align-middle">
                    {showButton? <Button variant='contained' className='corp'><a href="/checkout/customer" className="text-white">Checkout</a></Button> : null }
                </div> : (itemsInCart()? <div className='align-middle'><CircularProgress /></div> : null)
             }
        </div>
    )
}