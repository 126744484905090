import { memo } from "react";
import img from './images/ios.png'

const HeroIntroducingSavvyWallet = memo(function HeroIntroducingSavvyWallet(props?: any) {
    return (
    <>    
    <div className="wallet wallet5 pad--lg">
        <div className="container container--md">
            <div className="row align-items-center">
                <div className="col-md-4">
                    <h2 className="title-slant title-slant--invert"><small>introducing</small>the savvy app</h2>
                </div>
                <div className="col-md-8">
                    What good is crypto if you don’t have easy access to spend it? With your Savvy wallet  you have the opportunity to download our multi-currency wallet app. You have access to your finances like a bank and abilities to buy, sell and earn with the most simple and user-friendly crypto platforms all at your fingertips.
                </div>
            </div>
        </div>
    </div>
    <div className="wallet wallet6 pad--lg">
        <div className="container container--sm">
            <div className="applabel">
                <img src={img} className="applabel__img" alt="" />
                <div className="applabel__text"></div>
            </div>
        </div>
    </div>
    </>
    )
})

export default HeroIntroducingSavvyWallet