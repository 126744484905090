import { getToken, decodeJwt } from "../../services/token";
import { cacheDestroyTargets, cacheResetRegList } from "../../services/cache";

// type LoginDto = {
//     token: string
// }

export function Logout(token: string)//props: LoginDto)
{
    const jwt = decodeJwt(token)
    return (jwt.expired && !!jwt.id)
}

export function signOut()
{
    cacheDestroyTargets(true)
    cacheResetRegList()
    // window.location.search = ''
    // window.location.href = '/'
}