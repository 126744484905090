import { useContext, useEffect, useState } from "react"
import { Button, TextField } from "@mui/material"
import { dollars } from "../../../helpers/currency"
import { getItemQty, retail, subscriptionActive, updateCart, updateCartCount, useSitePriceTypeStatus } from "../../../services/cart"
import { ShoppingCartContext } from "../../../providers/cart"
import { IProductByCategory } from "../../../interfaces/product.interface"
import { processProduct } from "../../../helpers/product"
import { getShoppingCartPriceType } from "../../../helpers/cart"

export default function Product(props: IProductByCategory)
{
    const itemcode = props.ItemCode || ''
    const [ qty, setQty ] = useState<number>(0)
    const [ qtySet, setQtySet ] = useState<boolean>(false)
    const [ option, setOption ] = useState<string>('')
    const [ updateComp, setUpdateComp ] = useState<boolean>(true)
    const [ description, setDescription ] = useState<boolean>(false)

    const { type } = useSitePriceTypeStatus()
    const {
        toggleSidebar,
        setToggleUpdate,
        reloadSite
    } = useContext(ShoppingCartContext)

    // Update the cart - This should probably be worked into the provider
    if(updateComp && qtySet) {
        updateCart(processProduct(props, option, itemcode, qty, subscriptionActive(itemcode, option), type))
        setUpdateComp(false)
        setQtySet(false)
    }
    // useEffect(() => updateCartCount('cartcount'))
    /**
     * @description Runs the refresh for the page if the sidebar is updated with the itemcode qty
     */
    useEffect(() => {
        const newQty = getItemQty(itemcode, option)
        setQty(newQty)
    }, [ getItemQty(itemcode, option) ])

    return (
        <div className="product-card">
            <div className="product-image pointer" style={{backgroundImage: `url('${props.ShortDescription4 || ''}')`}} onClick={() => setDescription(!description)}>
                { props.PackContents? 
                <div className={`${ description? `showing` : `` } product-description`}>
                    <span dangerouslySetInnerHTML={{ __html: props.PackContents }}></span>
                    <div className="p-1 align-middle">
                        <i className="fas fa-chevron-circle-down fa-lg"></i>
                    </div>
                </div> : null }
            </div>
            <div className="product-name align-middle p-3">
                <h4>{ props.Title }</h4>
                <div className="legal">{ itemcode }</div>
            </div>
            <div className="product-price align-middle p3">
                <h2>{ dollars(getShoppingCartPriceType() !== retail? props.PricesAll.WH.Price : (props.Price || 0)) }</h2>
            </div>
            <div className="product-button align-middle p-3 pb-4">
                <TextField value={qty} type="number" variant="outlined" onChange={(e: any) => {
                    let val = parseInt(e.target.value)
                    if(val > 10)
                        val = 10
                    else if(val <= 0)
                        val = 0
                    setQty(val)
                }} InputProps={{ inputProps: { min: 0, max: 10 } }} />
                <Button variant="contained" className="corp mt-4" onClick={() => {
                    setQtySet(true)
                    setUpdateComp(true)
                    setToggleUpdate(true)
                    toggleSidebar(true)
                    reloadSite(true)
                }}>{ (getItemQty(itemcode, option) > 0)? `Update` : `Add To`} Cart</Button>
            </div>
        </div>
    )
}