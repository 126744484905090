import { memo } from "react"
import img from './images/1.png'
import { NavLink } from "react-router-dom"

const HeroMultiCurrencyWallet = memo(function HeroMultiCurrencyWallet(props?: any) {
    return (
    <div className="banner2">
        <img src={img} alt="" />
        <div className="banner2__inner">
            <div className="container">
                <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-6">
                        <div className="banner2__text">
                            <h2>multi-currency wallet</h2>
                            <p>Cryptocurrency is the future. And it is here now. Savvy Wallet is at the forefront, finding new ways to exchange crypto to fiat, spend and earn rewards.</p>
                            <div className="banner2__btns">
                                <NavLink to="/memberships" className="button">SHOP Wallets</NavLink>
                                <a href="#" className="button button--transter">Download Wallet PDF</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
})

export default HeroMultiCurrencyWallet