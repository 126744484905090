import { signOut } from "../components/logout"
import { decodeJwt, getToken } from "./token"
import { environment } from "../environmentals/config"

type IHttpClient = {
    "Access-Control-Allow-Headers": string,
    "Access-Control-Allow-Methods": string,
    'Content-Type': string,
    Authorization?: string
}


export default class HttpClient
{
    public type: string = 'application/json'

    private headers: IHttpClient = {
        "Access-Control-Allow-Headers": [
            "Content-Type",
            "Access-Control-Allow-Headers",
            "Authorization",
            "X-Requested-With"
        ].join(','),
        "Access-Control-Allow-Methods": [
            'POST',
            'GET',
            'PATCH',
            'PUT',
            'DELETE',
            'OPTION'
        ].join(','),
        'Content-Type': this.type
    }

    constructor()
    {
        // Checks if user token is valid
        const token = getToken()
        const jwt = decodeJwt(token)
        if(!!token) {
            // If the token is expired, sign out
            if(jwt.expired) {
                signOut()
            }
            // Assign the bearer token
            this.headers.Authorization =  `Bearer ${ token }`
        }
    }

    async get<T>(service?: string, body?: any): Promise<T>
    {
        let queryString = []
        if(body) {
            for (const k in body) {
                queryString.push(`${k}=${body[k]}`)
            }
        }
        return await fetch(`${environment.api}/${service}${(queryString.length > 0)? `?${queryString.join('&')}` : ``}`, {
            method: 'GET',
            headers: this.headers
        }).then(response => response.json())
    }

    async delete<T>(service: string, body: any = {}): Promise<T>
    {
        return fetch(`${environment.api}/${service}`, {
            method: 'DELETE',
            body: JSON.stringify(body),
            headers: this.headers
        }).then(response => response.json())
    }

    async post<T>(service: string, body?: any): Promise<T>
    {
        return await fetch(`${environment.api}/${service}`, {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify(body)
        }).then(response => response.json())
    }

    async patch<T>(service?: string, body?: any): Promise<T>
    {
        return await fetch(`${environment.api}/${service}`, {
            method: 'PATCH',
            headers: this.headers,
            body: JSON.stringify(body)
        }).then(response => response.json())
    }

    static init(): HttpClient
    {
        return new HttpClient()
    }

    upload(service: string, body: FormData): Promise<Response>
    {
        return fetch(`${environment.api}/${service}?jwtToken=${getToken()}`, {
            method: "POST",
            body: body,
        })//.then((res) => res.json());
    }
}