import { useState, ChangeEvent, useContext } from "react"
import { useNavigate } from "react-router"
import { Button, CircularProgress } from '@mui/material'
import { FormAddressComponent } from "../../components/form/address"
import { CheckoutContext } from "../../providers/checkout"
import { ShoppingCartEngineContext } from "../../providers/shopping-cart-engine"
import { CheckOutWizard } from "../../components/shopping-cart/checkout-wizard"
import { SitetContext } from "../../providers/site.provider"
import _ from "lodash"
import { ShoppingCartContext } from "../../providers/cart"
import { AccountContext } from "../../providers/account"

export default function PageShipAddress(props: any) {
    const { form, setForm, setFormReady, setFormStep } = useContext(CheckoutContext)
    const { setRefreshOrder, setOrderReady } = useContext(ShoppingCartEngineContext)
    const { setToggleUpdate, reloadSite } = useContext(ShoppingCartContext)
    const { account } = useContext(AccountContext)
    const [state, setState] = useState({
        form: form?.shipping || {
            fullName: "",
            company: "",
            address1: "",
            address2: "",
            city: "",
            country: "",
            state: "",
            zipcode: "",
            phone: ""
        }
    })
    const { countries, countriesReady } = useContext(SitetContext)
    const nav = useNavigate()
    const saveToForm = (form: any) => {
        setState(p => ({ ...p, shipping: form }));
        setForm ? setForm(p => ({ ...p, shipping: form })) : null
        setFormReady ? setFormReady(false) : null
    }
    const onChangeMainValue = (e: ChangeEvent<HTMLInputElement|HTMLSelectElement>, f: string, transform?: any) => {
        let form = state.form;
        _.set(form, f, transform? transform(e.target.value) : e.target.value)
        saveToForm(form)
    }

    const onNext = (e: any) => {
        e.preventDefault()
        setFormStep ? setFormStep('shipping') : null
        saveToForm(state.form)
        setRefreshOrder(true)
        setOrderReady(false)
        nav('/checkout/payment')
    }
    // Copy the shipping details over to the shipping block
    const copyToShipping = () => {
        let f = ({...form?.account, fullName: `${form?.account?.firstName} ${form?.account?.lastName}`})
        saveToForm(f)
        form?.account? setState({form: f}) : null
        setFormReady? setFormReady(false) : null
    }

    return (
        <div className="main" id="shipping-address-page-body">
            <div className="container">
                <CheckOutWizard
                    active="shipping"
                    summary={true}
                    account={true}
                    shipping={true}
                    payment={false}
                />
                <div className="address">
                    <form action="" onSubmit={e => onNext(e)}>
                        { countriesReady?
                            <div>
                                { !account?.customerID? <Button onClick={ copyToShipping } variant="outlined" className="small mb-0">Copy From Mailing</Button> :  null }
                                <FormAddressComponent countries={countries} title="Shipping Address" address={state.form} onChangeValue={ onChangeMainValue } />
                            </div> : null
                        }
                        { countriesReady? 
                        <div className="align-middle mb-4 pb-4">
                            <Button type="submit" variant="contained" className="corp">Payment</Button>
                        </div> : <div className="align-middle mb-4 pb-4"><CircularProgress /></div> }
                    </form>
                </div>
            </div>
        </div>
    )
}
