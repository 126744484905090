import { NavLink, useNavigate } from "react-router-dom"
import { Button} from '@mui/material'
import { cartCalculate, currentPriceType, hasQualifyingWHInCart } from '../../helpers/cart'
import { dollars } from "../../helpers/currency"
import { environment } from "../../environmentals/config"
import { retail } from "../../services/cart"
import { HeroJoinNowComponent } from '../../components/hero/join-now'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import WarningIcon from '@mui/icons-material/Warning'

export const CartCheckoutLoggedOut = (props?: any) =>
{
    const sitePriceType = currentPriceType()
    const orderTotal = ():number => {
        const totals = cartCalculate(sitePriceType)
        if(!totals)
            return 0
        return totals.subTotal
    }
    const threshold = orderTotal()
    // Wholesale and under the VP threshold
    const autoPC = (sitePriceType !== retail && threshold < environment.vpThreshold)
    // Wholesale with threshold
    const autoVP = (sitePriceType !== retail && threshold >= environment.vpThreshold)
    // Checks if use has added in an enrollment sku
    const hasPurchaseUpgrades = hasQualifyingWHInCart()
    return (
        <div className="main mt-4">
            <div className="opp opp1"></div>
            <div className="mt-1 mb-4 bgjoinoptions">
                <div className="container">
                    <div className="opp1__inneraa">
                        <div className="opp1__contentaa" style={{ backgroundColor: '#fcf8f2' }}>
                            <div className="opp1aa__cont"><br />
                                <p className="alreadyhaveaccount">Already have an account?</p>
                                <div className="opp1__buttons mb-4">
                                    <Button className='corp' variant='contained'>
                                        <NavLink to="/login" className="text-white">Login</NavLink>
                                    </Button>
                                    <br /><br />
                                    <NavLink className="retailcheckout" to={`/create/account`}>{ (sitePriceType !== retail || hasPurchaseUpgrades)? `Create Account` : `Don't want discounts? Pay full price here` }<ArrowForwardIcon /></NavLink>

                                    {sitePriceType !== retail? null : 
                                    <div className="col-count-4 gapped col-c1-lg">
                                        <div className="start2 span2 start1-lg  my-2 mx-4 span1-lg alert alert-info">When you add a Subscription to one, or more, of the items in your cart, you will be upgraded, at no extra charge, to a Visi Partner or Preferred Customer! <NavLink to='/checkout'><>Add Subscription? <ArrowForwardIcon /></></NavLink>
                                        </div>
                                    </div> }

                                    { ((autoPC || autoVP) && !hasPurchaseUpgrades)?
                                    <div className="col-count-4 gapped col-c1-lg">
                                        <div className="start2 span2 start1-lg span1-lg my-2 mx-4 alert alert-success"><span>Great news! Your customer status will be upgraded at no extra charge!</span> <WarningIcon /> { autoVP? <span>Your Visi Partner status will be removed if you remove the Subscription.</span> : <span>Your Preferred Customer status will be removed if you remove the Subscription.</span> } { !autoVP? <><strong>If you add</strong> {dollars(environment.vpThreshold - threshold)} <strong>of product to your order, you will automatically become a Visi Partner.</strong></> : null }</div>
                                    </div> : null }
                                </div>
                                <HeroJoinNowComponent />
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br /><br /><br />
                <div className="mt-1 mb-4" style={{ backgroundColor: '#edddc8' }}><br /></div>
            </div>
        </div>
    )
}