import { NavLink } from "react-router-dom"
import { ProductsBeauty, ProductsNutrition, ProductsOils } from "./menu-items"
import { useClasses } from "../../../hooks/useClasses";
import { useContext, useState } from "react";
import { MenuNavContext } from "../../../providers/menunav.provider";


const NavItem = (props: any) => {
    const { title, link, subMenu, onClick } = props;
    const [active, setActive] = useState(false);
    const classes = useClasses([{ "hasdrop": subMenu!==undefined, "opened": active }]);

    const clickAction = () => {
        if(subMenu !== undefined) setActive(prev => !prev);
        if(onClick) onClick();
    }

    return (
        <li>
            <NavLink to={link} onClick={clickAction} className={classes}>{title}</NavLink>
            {subMenu}
        </li>
    )
}

export const HeaderMenuMobile = () => {
    
    const { setToggleActive, toggleActive, setMenuToggled } = useContext(MenuNavContext)
    const doToggle = (name: string) => setToggleActive(toggleActive === name? '' : name)
    const reset = () => {
        doToggle('')
        setMenuToggled(false)
    }
    return (
    <ul>
        <NavItem 
            link="/"
            onClick={reset}
            title="HOME"
        />
        <NavItem 
            link="/product/category/allproducts"
            onClick={reset}
            title="Shop All Products"
        />
        <NavItem 
            link="#"
            title="nutrition"
            subMenu={<ProductsNutrition />}
        />
        
        <NavItem 
            link="#"
            title="beauty"
            subMenu={<ProductsBeauty />}
        />

        <NavItem 
            link="#"
            title="oils"
            subMenu={<ProductsOils />}
        />

        <NavItem 
            link="#"
            title="digital"
            subMenu={(
                <ul>
                    <li><NavLink to="/memberships" onClick={ reset }>Memberships</NavLink></li>
                    <li><NavLink to="/savvy-wallet/compare" onClick={ reset }>Savvy Wallet</NavLink></li>
                    <li><NavLink to="/broadcast-pro" onClick={ reset }>10X Academy</NavLink></li>
                    <li><NavLink to="/travel" onClick={ reset }>I Love Cheap Travel</NavLink></li>
                </ul>
            )}
        />

        <NavItem 
            link="/login"
            title="LOGIN"
        />

    </ul>
    )
}