import { memo, useContext } from "react";
import { Button } from "@mui/material";
import TenXdigitalwealthacademy from './images/10xdigitalwealthacademy.png'
import TenXdigitalwealthacademyAlt from './images/10x_digitalwealthacademy.png'
import broadcastpro_10xacademy from './images/broadcastpro_10xacademy.png'
import img2 from './images/2.png'
import img3 from './images/3.png'
import img4 from './images/4.png'
import img5 from './images/5.png'
import './styles/styles.scss'
import { useNavigate } from "react-router";
import { ShoppingCartEngineContext } from "../../providers/shopping-cart-engine";
import { dollars } from "../../helpers/currency";

const PageBroadcastPro = memo(function PageBroadcastPro(props?: any) {
    const nav = useNavigate()

    return (
        <div className="main">
            <div className="broad  broad1">
                <div className="banner4 banner4--new">
                    <img src={ TenXdigitalwealthacademy } alt="" />
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="banner4__left">
                                    <h1>10x digital wealth academy</h1>
                                    <p>You will receive education and mentorship from veteran master traders and learn the best strategies to enter into trades. You also don’t need any prior knowledge of forex market as you will get to learn step by step. </p>
                                    <p>Get full access to the 10x trading academy &amp; live mentorship  to learn while you earn.</p>
                                    <Button variant="contained" className="button corp">Pick Your Subscription</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="broad broad2">
                <div className="container container--sm">
                    <div className="row">
                        <TenExAcadamy />
                    </div>
                </div>
            </div>			
        <div className="broad broad4 mt-5">
            <div className="container container--sm">
                <div className="row">
                    <div className="col-md-6">
                        <ul className="ulbar">
                            <li>Learn from experienced  Master Traders</li>
                            <li>Improve your financial knowledge</li>
                            <li>Receive daily broadcast ideas with results</li>
                            <li>No long term commitment</li>
                            <li>Low minimum account requirements</li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <img src={img2} alt="" />
                    </div>
                </div>
            </div>
        </div>
        <div style={{visibility: "hidden"}}><div style={{visibility: "hidden"}}><span>$599.99 v7039abdg /mo</span></div><div style={{visibility: "hidden"}}><span>$299.99 v7038wadg /mo</span></div></div>	
        <div className="broad broad5 pad--lg">
            <div className="container">
                <h2 className="title title--sub">
                    success stories
                    <small>Disclaimer: Past profits do not guarantee future results.</small>
                </h2>
                <div className="row">
                    <div className="col-md-4 testi__col">
                        <div className="testi">
                            <img src={img3} alt="" />
                            <div className="testi__content">
                                <p><strong>BP is doing work for me while I’m asleep &amp; closing trades all day without having to touch my phone!</strong></p>
                                <h5>– J. B.</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 testi__col">
                        <div className="testi">
                            <img src={img4} alt="" />
                            <div className="testi__content">
                                <p><strong>Yes!!!! I’m in the system and making profits with no experience in Forex. </strong></p>
                                <h5>– Bunmi M.</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 testi__col">
                        <div className="testi">
                            <img src={img5} alt="" />
                            <div className="testi__content">
                                <p><strong>Easy profits.  Let’s go.</strong></p>
                                <h5>–&nbsp;Yassine M.</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <p className="txt-center"><small>PAST RESULTS DO NOT GUARANTEE FUTURE SUCCESS. VIDEOS, CONTENT PROVIDED, INCLUDING BUT NOT LIMITED TO SERVICES OF BIT PROOR TRADER COMMUNICATIONS, ANY AND ALL TRANSLATIONS OF CONTENT, AS WELL AS EMAILS, TELEGRAM MESSAGES, AND OTHER COMMUNICATIONS RELATED TO OR CONTAINED HEREIN ARE FOR EDUCATIONAL AND VIEWING PURPOSES ONLY. AT NO POINT ARE THE VIDEOS AND CONTENT PROVIDED INTENDED AS FINANCIAL INSTRUCTIONS OR FINANCIAL ADVICE. UNDER NO CIRCUMSTANCE DOES LURRAGLOBAL CREATE A BROKERAGE ACCOUNT FOR YOU, RECEIVE, MANAGE OR TRADE ANY MONEY FOR YOU OR ON YOUR BEHALF. TRADING CONTAINS SUBSTANTIAL RISK AND IS NOT FOR EVERY INDIVIDUAL OR EVEN AN INVESTOR. YOU COULD POTENTIALLY LOSE ALL OR MORE THAN THE INITIAL INVESTMENT YOU PLACE INTO A BROKERAGE ACCOUNT. RISK CAPITAL IS MONEY THAT CAN BE LOST WITHOUT JEOPARDIZING FINANCIAL SECURITY OR LIFESTYLE. YOU AS THE MEMBER OR INVESTOR SHOULD ONLY USE RISK CAPITAL FOR TRADING. ANY PAST PERFORMANCE IS NO GUARANTEE OR INDICATIVE OF FUTURE RESULTS.
                    </small>
                </p>
            </div>
        </div>
    </div>
    )
})

export default PageBroadcastPro

export const TenExAcadamy = () => {
    const { itemList } = useContext(ShoppingCartEngineContext)
    const nav = useNavigate()
    return (
        <div className="col-count-2 col-c1-md gapped">
            { itemList.map((v: any, k: number) => {
                return (
                    v.ShortDescription3.match(/10x_acadamy/gi)? 
                    <div key={`tenex-prod-${k}`} className="col-count-" id="picksubscription">
                        <div className="plansm plansm--2">
                            <img src={ v.ShortDescription4 !== ''? v.ShortDescription4 : TenXdigitalwealthacademyAlt } alt={v.Title} />
                            <h3>
                                <span>{v.Title}</span><br />
                                <span>{dollars(v.Price)}/mo</span>
                            </h3>
                            <div dangerouslySetInnerHTML={{__html: v.PackContents}}></div>
                            <div className="plansm__btn">
                                <span className="pbutton">
                                <Button variant="contained" className="button corp" onClick={() => nav(`/product/${v.ItemCode}`)}>ADD SUBSCRIPTION</Button>
                                </span>
                            </div>
                        </div>
                    </div> : null
                )
            })}
        </div>
    )
}

export const TenExAcadamyOld = () => {
    const nav = useNavigate()

    return (
        <>
            <div className="col-lg-1"></div>
            <div className="col-md-6 col-lg-5" id="picksubscription" style={{maxWidth: "500px"}}>
                <div className="plansm plansm--2">
                    <img src={ TenXdigitalwealthacademyAlt } alt="" />
                    <h3>
                        <span>10x Digital Wealth Academy</span><br />
                        <span>$179.99 /mo</span>
                    </h3>
                    <ul className="ulbar">
                        <li>10x Digital Wealth Academy</li>
                        <li>US Open Live Weekly Mentorship</li>
                        <li>London Live Weekly Mentorship </li>
                        <li>Asia Live Weekly Mentorship </li>
                        <li>Mindset and Psychology Sessions<br />&nbsp;</li>
                    </ul>
                    <div className="plansm__btn">
                        <span className="pbutton">
                        <Button variant="contained" className="button corp" onClick={() => nav(`/product/v7038wadg`)}>ADD SUBSCRIPTION</Button>
                        </span>
                    </div>
                </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-md-6 col-lg-5" style={{maxWidth: "500px"}}>
                <div className="plansm plansm--2">
                    <img src={ broadcastpro_10xacademy } alt="" />
                    <h3>
                        <span>10x Academy</span><br />
                        <span>$289.99 /mo</span>
                    </h3>
                    <ul className="ulbar">
                        <li>Smart Lot Strategy</li>
                        <li>Start trading with the world's<br />
                            #1 Forex broadcast provider.
                        </li>
                        <li>10X Digital Wealth Academy </li>
                        <li>Live Mentorship with Master Traders</li>
                        <li>I Love Cheap Travel Access</li>
                    </ul>
                    <div className="plansm__btn">
                        <span className="pbutton">							
                        <Button variant="contained" className="button corp" onClick={() => nav(`/product/v7033bfdg`)}>ADD SUBSCRIPTION</Button>
                        </span>
                    </div>
                </div>
            </div>
            <div className="col-lg-1"></div>
        </>
    )
}