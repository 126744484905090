import { useState } from 'react';
import { CircularProgress } from '@mui/material'
import './styles.scss';

export function ModalCustom(props: any)
{
    const [bodyContent, setBodyContent] = useState(props.content);
    
    return (
        <div className="modal-body">
            <div className={`modal-box ${!!props.width? props.width : null}`}>
                <div className="modal-head">
                    <h4 className='my-2'>{props.title}</h4> <div className='align-middle pointer' onClick={() => setBodyContent(props.closeEvent())}><i className="fas fa-times"></i></div>
                </div>
                <div className="modal-content">
                    { bodyContent }
                </div>
                <div className="modal-footer" id='modal-footer'>
                    { props.buttons }
                </div>
            </div>
        </div>
    )
}

export function ModalThinking(props: any)
{
    return (
        <div className="modal-body thinker">
            <div className="modal-box">
                <div className="modal-content align-middle">
                    <CircularProgress />
                </div>
            </div>
        </div>
    )
}