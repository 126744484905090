import { AlertGeneral } from "../../components/alert"
import { Button, CircularProgress, FormControl, TextField } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { ResetPasswordProviderContext } from "../../providers/reset-password.provider"
import { useContext, useState } from "react"
import { PasswordResetCodeSendNotifyService } from "../../services/password-reset.service"

export const PageLoginFormSubmitRequest = () =>
{
    const { currentState, setCurrentState, username, setUsername, loading, setLoading, setMsg, setCodeToken, setMsgType } = useContext(ResetPasswordProviderContext)
    const submitResetPassword = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setMsg('')
        setMsgType('success')
        setLoading(true)
        PasswordResetCodeSendNotifyService(username).then(r => {
            if(r.success) {
                setCurrentState('submit-code')
                setCodeToken(r.data.ID)
            } else {
                setMsg(r.error)
                setMsgType('danger')
            }
            setLoading(false)
        })
    }

    return (
        (currentState == 'forgot-password')?
        <div>
            <form action="#" onSubmit={(e) => submitResetPassword(e)}>
                <h2 className='mb-3'>Reset Password</h2>
                <div className='col-count-1 gapped'>
                    <TextField
                        variant='outlined'
                        label="Member Id"
                        type='text'
                        required
                        value={ username }
                        onChange={ (e: any) => setUsername(e.target.value.trim()) }
                        disabled={ loading }
                    />
                </div>
                <div className="col-count-1 gapped">
                    <div className="align-middle mt-4">
                        { loading? <CircularProgress /> : <Button type="submit" className='corp' variant='contained' disabled={ loading }>Send Email Validation</Button> }
                    </div>
                    <div className={`align-middle ${ loading? 'disabled' : '' }`}>
                        <a href="#" className='legal' onClick={ () => {
                            setCurrentState('login')
                        } } ><ArrowBackIcon />&nbsp;Back</a>
                    </div>
                </div>
            </form>
        </div> : null
    )
}