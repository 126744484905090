import { IResponse, IResponseCount } from "../interfaces/http.interface";
import { IProduct, IProductByCategory } from "../interfaces/product.interface";
import HttpClient from "./http";

export function ProductsGetService(): Promise<IResponseCount<any[]>>
{
    return (new HttpClient()).get<IResponseCount<any[]>>('products/get')
}

export function ProductsGetBySkuService(pid: string): Promise<IResponseCount<IProduct>>
{
    return (new HttpClient()).get<IResponseCount<IProduct>>(`products/get/${pid}`)
}
export function ProductsGetSelectedSkusService(pid: string[]): Promise<IResponseCount<IProduct[]>>
{
    return (new HttpClient()).get<IResponseCount<IProduct[]>>(`products/get/selected?itemcodes=${pid.join(',')}`)
}
export function ProductsGetByCategoryService(category: string, body: any = {}): Promise<IResponseCount<IProductByCategory[]>>
{
    return (new HttpClient()).get<IResponseCount<IProductByCategory[]>>(`products/get/category/${category}`, body)
}
export function ProductsGetItemsByCountryService(country: string, priceType: string): Promise<IResponseCount<IProductByCategory[]>>
{
    return (new HttpClient()).get<IResponseCount<IProductByCategory[]>>(`products/by-country`, { country, priceType })
}
// export function ProductsGetFilter(type: string, body: any = {}): Promise<IResponse<any[]>>
// {
//     return (new HttpClient()).get<IResponse<any[]>>(`products/filter/${type}`, body)
// }