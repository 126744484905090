import { memo } from "react";
import img1 from './images/rect1.png'
import img2 from './images/rect1.png'
import { ButtonBuyCollagen } from "../../shopping-cart/button";
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";

const HeroArcticCloudberry = memo(function HeroArcticCloudberry(props?: any){
    return (
        <div className="hm5 pad">
            <div className="container">
                <h2 className="bltitle bltitle--center">The Arctic Cloudberry helps improve collagen absorption and natural production in two ways:</h2>
                <div className="row">
                    <div className="col-md-6">
                        <div className="box2">
                            <img src={img1} alt="" />
                            <div className="box2__inner">
                                <div className="box2__content">
                                    <p>First, the antioxidant properties of the <b>Arctic Cloudberry reduce the damage to collagen</b> caused by free radicals—unstable molecules that attack collagen. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="box2">
                            <img src={img2} alt="" />
                            <div className="box2__inner">
                                <div className="box2__content">
                                    <p>Second, the Vitamin C in the Arctic Cloudberry is a precursor to collagen and helps your body boost <b>its natural production of this critical protein.*</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="align-middle mt-4 pt-3">
                    <div className="col-count-2 gapped col-c1-sm">
                        <Button variant="contained" className='button corp outlined'><NavLink to='/collagen-story' className="text-white txt-white">LEARN MORE</NavLink></Button> 
                        <ButtonBuyCollagen />
                    </div>
                </div>
            </div>
        </div>
    )
})

export default HeroArcticCloudberry