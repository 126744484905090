import { NavLink } from "react-router-dom"
import { useContext } from 'react'
import { ShoppingCartEngineContext } from "../../../providers/shopping-cart-engine"
import { IProductByCategory } from "../../../interfaces/product.interface"
import { MenuNavContext } from "../../../providers/menunav.provider"

export interface IProductsMenu
{
    resetMenu: any
    mostPopular: any
}

const createMenuItems = (
    products: any,
    path: string
) => {
    
    const { setToggleActive, setMenuToggled, mostPopular } = useContext(MenuNavContext)
    const toggledOff = () => {
        setToggleActive('')
        setMenuToggled(false)
    }
    return (
        <>
            { Object.keys(products).map((v, k) => {
                return (
                <li key={k}>
                    <NavLink to={`${path}${v}`} onClick={ toggledOff }>
                        <span dangerouslySetInnerHTML={{ __html: products[v] }}></span> { mostPopular.includes(v)? <span className="mptxt">Most Popular</span> : ''}
                    </NavLink>
                </li>)
            }) }
        </>
    )
}

const createMenuItemsFromObj = (
    products: IProductByCategory[],
    path: string
) => {
    const { setToggleActive, setMenuToggled, mostPopular } = useContext(MenuNavContext)
    const toggledOff = () => {
        setToggleActive('')
        setMenuToggled(false)
    }
    return (
        <>
            { products.map((v, k) => {
                return (
                <li key={k}>
                    <NavLink to={`${path}${v.ItemCode}`} onClick={ toggledOff }>
                        <span dangerouslySetInnerHTML={{ __html: v.Title || '' }}></span> { mostPopular.includes(v.ItemCode || '')? <span className="mptxt">Most Popular</span> : ''}
                    </NavLink>
                </li>)
            }) }
        </>
    )
}
/*********************************
 ****** ALL THINGS NUTRITION *****
 *********************************/
export const BenefitsNutrition = () => {
    return (
    <ul>
        {createMenuItems({
            "energy": "Energy",
            "cleanse-and-detox": "Cleanse &amp; Detox",
            "immune-support": "Immune Support",
            "mood": "Mood",
            "overall-wellness": "Overall Wellness",
            "snack": "Snack",
            "weight-loss": "Weight Loss",
        }, '/product/benefit/nutrition/')}
    </ul>
    )
}

export const BundleNutrition = () => {
    const { itemList } = useContext(ShoppingCartEngineContext)
    const items = getProductsInCategory(['nutrition-bundle'], itemList)
    return (
    <ul>
        { createMenuItemsFromObj(items, '/product/') }
    </ul>)
}

export const ProductsNutrition = () => {
    const { itemList } = useContext(ShoppingCartEngineContext)
    const items = getProductsInCategory(['nutrition'], itemList)
    const props = useContext(MenuNavContext)
    const resetMainMenu = () => {
        props.setToggleActive('')
    }
    return (
        <ul>
            <li><NavLink to="/product/benefit/nutrition/all" onClick={() => resetMainMenu()}>Shop All</NavLink></li>
            { createMenuItemsFromObj(items, '/product/') }
        </ul>
    )
}
/******************************
 ****** ALL THINGS BEAUTY *****
 ******************************/
export const BenefitsBeauty = () => {
    return (
        <ul>
            {createMenuItems({
            // "lines-and-wrinkles": "Lines and Wrinkles",
            "cleanse": "Cleanse",
            "exfoliate": "Exfoliate",
            "teeth-whitening": "Teeth Whitening",
        }, '/product/benefit/beauty/')}
        </ul>
    )
}

export const ProductsBeauty = () => {
    const { itemList } = useContext(ShoppingCartEngineContext)
    const items = getProductsInCategory(['beauty'], itemList)
    const {resetMenu, setMenuToggled } = useContext(MenuNavContext)
    const resetMainMenu = () => { 
        setMenuToggled(false)
    }
    return (
        <ul>
            <li><NavLink to="/product/benefit/beauty/all" onClick={() => resetMainMenu()}>Shop All</NavLink></li>
            { createMenuItemsFromObj(items, '/product/') }
        </ul>
    )
}
/****************************
 ****** ALL THINGS OILS *****
 ****************************/
export const BenefitsOils = () => {
    return (
        <ul>
            {
            createMenuItems({
                "relaxation": "Relaxation",
                "focus": "Focus",
                "healing": "Healing",
                "odor-eliminator": "Odor Eliminator",
                "meditation": "Meditation",
                "skin-health": "Skin Health",
            }, '/product/benefit/oils/')
            }
        </ul>
    )
}

export const ProductsOils = () => {
    const { itemList } = useContext(ShoppingCartEngineContext)
    const items = getProductsInCategory(['oils'], itemList)
    const {resetMenu, setMenuToggled } = useContext(MenuNavContext)
    const resetMainMenu = () => {
        setMenuToggled(false)
    }
    return (
        <ul>
            <li><NavLink to="/product/benefit/oils/all" onClick={() => resetMainMenu()}>Shop All</NavLink></li>
            { createMenuItemsFromObj(items, '/product/') }
        </ul>
    )
}
/*******************************
 ****** ALL THINGS DIGITAL *****
 *******************************/
export const BundlesDigital = () => {
    const { itemList } = useContext(ShoppingCartEngineContext)
    const items = getProductsInCategory(['digital-bundle'], itemList)
    const {resetMenu, setMenuToggled } = useContext(MenuNavContext)
    const resetMainMenu = () => {
        setMenuToggled(false)
    }
    return (
    <ul>
        { createMenuItemsFromObj(items, '/product/') }
        <li><NavLink to="/product/benefit/digital/all" onClick={() => resetMainMenu()}>Shop All</NavLink></li>
    </ul>
    )
}

export const SavvyWalletNav = () => {
    return (
        <ul>
            { createMenuItems({
            "how-it-works": "How It Works",
            "compare": "Compare Wallets"
        }, '/savvy-wallet/')}
        </ul>
    )
}

export const BroadcastProNav = () => {
    const { itemList } = useContext(ShoppingCartEngineContext)
    const items = getProductsInCategory(['10X_Acadamy'], itemList)
    return (
        <ul>
            { createMenuItemsFromObj(items, '/product/') }
        </ul>
    )
}

export const ILoveCheapTravelNav = () => {
    const { itemList } = useContext(ShoppingCartEngineContext)
    const items = getProductsInCategory(['ilct'], itemList)
    return (
        <ul>
            { createMenuItemsFromObj(items, '/product/') }
        </ul>
    )
}

const getProductsInCategory = (categories: string[], itemList: IProductByCategory[]): IProductByCategory[] => {
    let prods: IProductByCategory[] = []
    if(itemList === undefined) {
        return prods
    }
    categories.map((v, k) => {
        if(itemList.length > 0) {
            itemList.map((val, key) => {
                if(val.ShortDescription3?.includes(v)) {
                    prods.push(val)
                }
            })
        } else {
            return null
        }
    })

    return prods
}