import { cacheDestroyTargets } from "../services/cache"
import { cartHasAutoship, retail, wholesale } from "../services/cart"
import { setShoppingCartPriceType } from "./cart"

export const signOutReload = () => {
    cacheDestroyTargets(false)
    setShoppingCartPriceType(cartHasAutoship()? wholesale : retail)
    window.location.reload()
}

export const passwordFormatValid = (password: string) => /^(?=.*[A-Z])(?=.*\d)[\w!@#$%^&*()-+=<>?/\|{}[\]~`'".,:;_]{8,}$/.test(password)