import { useContext, useState } from 'react'
import { Button, CircularProgress } from '@mui/material'
import { CartSummaryTotalBox } from "../../components/shopping-cart/checkout/cart-summary-total-box"
import ShoppingCartCheckout from "../../components/shopping-cart/checkout"
import ProductMast from "../../components/shopping-cart/product-mast"
import { getCart, itemsInCart } from '../../services/cart'

export default function PageCartOrderSummary(props?: any)
{
    return (
        <div id="page-checkout">
            <ProductMast title="Checkout" />
            <div className="col-count-3 offset">
                <div className="start2">
                    <div className="checkout-summary col-count-2 gapped col-c1-lg">
                        <div className="item-order-summary">
                            <ShoppingCartCheckout />
                        </div>
                        { (itemsInCart())? <div className="price-summary">
                            <CartSummaryTotalBox />
                        </div> : null }
                    </div>
                </div>
            </div>
        </div>
    )
}