import { useState, ChangeEvent, useContext } from "react"
import { Button, Checkbox, FormControlLabel } from '@mui/material'
import { expMonth, expYear, filterValidateAlphaNumeric } from "../../helpers/util"
import { useNavigate } from "react-router"
import { CheckoutContext, clearCheckoutForm } from "../../providers/checkout"
import { itemsInCart, resetCart } from "../../services/cart"
import { CartSummaryTotalBox } from "../../components/shopping-cart/checkout/cart-summary-total-box"
import { AlertGeneral } from "../../components/alert"
import { OrderService } from "../../services/order.service"
import { CircularProgress } from '@mui/material'
import { useClasses } from "../../hooks/useClasses"
import { CheckOutWizard } from "../../components/shopping-cart/checkout-wizard"
import { SitetContext } from "../../providers/site.provider"
import { ShoppingCartPaymentTokenexComponent } from "../../components/shopping-cart/payment/tokenex"
import { ShoppingCartEngineContext } from "../../providers/shopping-cart-engine"
import { SiteServiceGetRegions } from "../../services/site.service"
import { getSiteCountry } from "../../helpers/site"
import ShoppingCartCheckout from "../../components/shopping-cart/checkout"
import FormGroup from "../../components/form/form-group"
import Input from "../../components/form/input"
import Select from "../../components/form/select"
import AmexImg from './images/amex.gif'
import DiscImg from './images/discover.gif'
import JBCImg from './images/jcb.gif'
import MCImg from './images/mastercard.gif'
import UnionImg from './images/unionpay_card.png'
import VSImg from './images/visa.gif'
import _ from "lodash"
import './styles/styles.scss'
import { environment } from "../../environmentals/config"

interface IPageOrderProduct
{
    distid: any
    orderid: any
}

export default function PageOrderProduct(props: any) {
    const [state, setState] = useState({
        form: {
            name: "",
            country: getSiteCountry().code,
            cardNumber: "",
            city: "",
            address: "",
            year: "",
            month: "",
            cvc: "",
            state: "",
            error: ""
        }
    })
    const [ termsChecked, setTermsChecked ] = useState<boolean>(false);
    const [ loaded, setLoaded ] = useState(false)
    const [ error, setError ] = useState<string>("")
    const [ tokenizeReady, setTokenizeReady ] = useState(false)
    const [ paymentSubmitting, setPaymentSubmitting ] = useState(false) 
    const { form, formReady, setFormStep, iframe } = useContext(CheckoutContext)
    const { countries, countriesReady } = useContext(SitetContext)
    const { orderReady } = useContext(ShoppingCartEngineContext)
    const nav = useNavigate()
    const styling = useClasses([{"disabled" : paymentSubmitting}])
    const [ orderSubmitting, setOrderSubmitting ] = useState(false)

    const [ states, setStates ] = useState({states: {}, loading: false, ready: false})
    const [ country, setCountry ] = useState<string>(state.form.country)

    const getCountry = () => SiteServiceGetRegions(country).then(r => {
        setStates(arr => ({states: r.data.regions, loading: false, ready: true}))
    })

    const resetOrderButton = () => {
        setPaymentSubmitting(false)
        setOrderSubmitting(false)
    }

    if(!states.ready) {
        if(!states.loading) {
            setStates(arr => ({...arr, loading: true}))
            getCountry()
        }
    }

    const changeValue = (e: ChangeEvent<HTMLInputElement|HTMLSelectElement>, f: string, transform?: any) => {
        let form = state.form;
        let val = e.target.value
        _.set(form, f, transform? transform(val) : val);
        setState(p => ({ ...p, form: form }));
    };
    
    const changeValueUseVal = (e: string, f: string, transform?: any) => {
        let form = state.form;
        let val = e
        _.set(form, f, transform? transform(val) : val);
        setState(p => ({ ...p, form: form }));
    };

    const onSubmit = (e: any) => {
        e.preventDefault()
        setError('')
        setPaymentSubmitting(true)
        setFormStep ? setFormStep('payment') : null
        iframe.validate()
        iframe.tokenize()
    }
    
    if(paymentSubmitting) {
        if(tokenizeReady) {
            if(!orderSubmitting) {
                setOrderSubmitting(true)
                OrderService<IPageOrderProduct>({ ...form, payment: state.form }, 'submit').then(r => {
                    if(!r.success && r.error) {
                        setError(r.error)
                    } else {
                        if(r.success && r.data.orderid) {
                            resetCart()
                            clearCheckoutForm()
                            window.location.href = `/order/success/${r.data.distid}/${r.data.orderid}`
                        } else {
                            setError('An unknown error occurred.')
                        }
                    }
                    resetOrderButton()
                })
            }
        } else {
            console.log('not ready')
        }
    }

    if(!loaded) {
        setLoaded(true)
    }

    return (
        itemsInCart()? <div className="main py-4" id="order-product-page-body">
            {/* <div className="content">
                <div className="container">
                    <div className="title-d" data-cat="health">
                        <h2>CHOOSE A PAYMENT OPTION</h2>
                    </div>
                </div>
            </div> */}
            <div className="container">
                <CheckOutWizard
                    active="payment"
                    summary={true}
                    account={true}
                    shipping={true}
                    payment={false}
                />

                <form action="" className={styling} onSubmit={onSubmit}>
                    <FormGroup title="Credit Card Payment" className="col-count-2 gapped col-c1-md form-group__content">

                        <div className="align-middle span2 span1-md border p-2 bkg-gray-light rounded-large">
                            <div className="cc-options-container col-count-2 gapped col-c1-md">
                                <h6 className="align-middle uppercase mb-0">We Accept:</h6>
                                <div className="col-count-6 gapped col-c3-sm">
                                    <div><img src={ AmexImg } alt="AMEX Logo" /></div>
                                    <div><img src={ DiscImg } alt="Discover Logo" /></div>
                                    <div><img src={ JBCImg } alt="JBC Logo" /></div>
                                    <div><img src={ MCImg } alt="MasterCard Logo" /></div>
                                    <div><img src={ UnionImg } alt="Union Pay Logo" /></div>
                                    <div><img src={ VSImg } alt="Visa Logo" /></div>
                                </div>
                            </div>
                        </div>
                            
                        <div className="span2 span1-md">
                            <Input
                                type="text"
                                label="Name on Card"
                                required={true}
                                form={state.form}
                                field="name"
                                onChange={e => changeValue(e, "name")} 
                            />
                        </div>
                        <div className="span2 span1-md">
                            <ShoppingCartPaymentTokenexComponent changeValue={changeValueUseVal} setTokenizeReady={setTokenizeReady} setError={setError} />
                        </div>
                        <div className="col-count-2 gapped">
                            <Select 
                                label="Expiration Month"
                                required={true}
                                field="month"
                                form={state.form} 
                                options={expMonth().map((v, k) => {
                                    return {
                                        value: v,
                                        label: v
                                    }
                                })} 
                                onChange={e => changeValue(e, "month")} 
                            />
                            <Select 
                                label="Expiraton Year"
                                required={true}
                                field="year"
                                form={state.form} 
                                options={expYear().map((v, k) => {
                                    return {
                                        value: v[1],
                                        label: v[1]
                                    }
                                })} 
                                onChange={e => changeValue(e, "year")} 
                            />
                        </div>
                        
                        <Input
                            type="text"
                            label="CVV2 Security Code"
                            required={true}
                            form={state.form}
                            field="cvc"
                            maxLength={5}
                            onChange={e => changeValue(e, "cvc", (v: string) => filterValidateAlphaNumeric(v))} 
                        />

                        <Input
                            type="text"
                            label="Billing Address"
                            required={true}
                            form={state.form}
                            field="address"
                            onChange={e => changeValue(e, "address")} 
                        />
                        { countriesReady? <Select 
                            label="Billing Country"
                            required={true}
                            field="country"
                            form={state.form} 
                            options={countries.map((v, k) => {
                                return { value: v.code, label: v.name }
                            })} 
                            onChange={e => {
                                changeValue(e, "country")
                                setCountry(e.target.value)
                                setStates(arr => ({...arr, ready: false, loading: false}))
                            }} 
                        /> : null }
                        <Input
                            type="text"
                            label="Billing City"
                            required={true}
                            form={state.form}
                            field="city"
                            onChange={e => changeValue(e, "city") } 
                        />
                        
                        { states.ready? <Select
                            field="state"
                            label="Select State/Province"
                            required={false}
                            form={state.form}
                            options={Object.keys(states.states).map((v: string, k) => {
                                return { value: v, label: states.states[v as keyof typeof states.states] }
                            })}
                            onChange={e => changeValue(e, "state")}
                        /> : null }

                        <Input
                            type="text"
                            label="Billing Post Code"
                            required={true}
                            form={state.form}
                            field="zipcode"
                            maxLength={8}
                            onChange={e => changeValue(e, "zipcode", (v:string) => filterValidateAlphaNumeric(v))} 
                        />
                    </FormGroup>
                    { (itemsInCart() && formReady)?
                    <div className="price-summary py-2 px-4 shadowed rounded-large bkg-white mb-4">
                        <ShoppingCartCheckout editable={true} />
                        <CartSummaryTotalBox buttonOption={false} />
                    </div> : null }
                    <div className="align-middle pb-4">
                        <div style={{maxWidth: 600}} className="mb-3">
                            <FormControlLabel
                                control={<Checkbox onChange={(e) => setTermsChecked(e.target.checked)} value={termsChecked} />}
                                label={<>I agree to the Visi <a href={environment.policy} target="_blank" referrerPolicy="same-origin" className="pointer">{environment.policyName}</a> and understand that all digital products including Full and Gold Service Expert Advisor Packs are NOT refundable.</>}
                            />
                        </div>
                        { error !== ''? <AlertGeneral type="danger" content={error} /> : null }
                        { (paymentSubmitting || !orderReady)? <CircularProgress /> : <Button type="submit" variant="contained" className="corp bold" disabled={!termsChecked}>Place Order</Button>}
                    </div>
                </form>
            </div>
        </div> : <div className="align-middle"><AlertGeneral type="info" content="Your cart is currently empty" /></div>
    )
}