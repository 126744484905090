import { useState } from 'react'
import { year } from '../../helpers/util'
import { useNavigate } from 'react-router'
import { ResetPasswordProvider } from '../../providers/reset-password.provider'
import { PageLoginFormLogin } from './form-login'
import { PageLoginFormSubmitCode } from './form-submit-code'
import { PageLoginBlockAlerts } from './block-alerts'
import { cacheGet } from '../../services/cache'
import { PageLoginFormSubmitRequest } from './form-submit-request'
import { PageLoginFormSubmitNewPassword } from './form-submit-newpassword'
import VisiLogo from '../../templates/visi-2023/images/visi_logo.png'
import VisiLogoWhite from './images/visi_wlogo.png'
import './styles/styles.scss'

export default function PageLogin(props?: any)
{
    const navigate = useNavigate()
    const location = () => window.location.href = '/'//window.location.reload()//navigate(-1)
    // Stop user from trying to log in if already logged in
    if(cacheGet('token') !== '') {
        window.location.href = '/'
    }

    return (
        <div id="login-container" className='col-count-2 col-c1-lg'>
            <aside className='align-middle'>
                <p className="mvdtlgo"><img src={VisiLogoWhite} alt="Visi logo" /></p>
            </aside>
            <main className='col-count-'>
                <div className="header-login px-4 py-4 align-middle">
                    <a href="/"><img src={ VisiLogo } alt="Visi logo" /></a>
                </div>
                <div className="align-middle text-left txt-left">
                    <div className='login-container'>
                        <ResetPasswordProvider>
                            <PageLoginBlockAlerts />
                            <PageLoginFormLogin location={ location } />
                            <PageLoginFormSubmitRequest />
                            <PageLoginFormSubmitCode />
                            <PageLoginFormSubmitNewPassword />
                        </ResetPasswordProvider>
                    </div>
                </div>
                <div className="align-middle">
                    <p className='legal'>&copy; { year() } Visi. All rights reserved.</p>
                </div>
            </main>
        </div>
    )
}