import { useNavigate } from 'react-router'
import { useContext } from 'react'
import { useClasses } from '../../../hooks/useClasses'
import { AccountContext } from '../../../providers/account'
import './styles/styles.scss'
import { ShoppingCartEngineContext } from '../../../providers/shopping-cart-engine'

interface ICheckOutWizard
{
    summary: boolean
    account: boolean
    shipping: boolean
    payment: boolean
    active: string
}

export const CheckOutWizard = (props: ICheckOutWizard) => {

    const { account } = useContext(AccountContext)
    const str: string = 'checkout-wizard-'
    const loggedIn = account?.customerID? true : false

    const stylesSummary = useClasses([ `${str}summary`, { active: props.active === 'summary', pointer: props.summary }])
    const stylesAccount = useClasses([ `${str}account`, { active: props.active === 'account', pointer: props.account }])
    const stylesShipping = useClasses([ `${str}shipping`, { active: props.active === 'shipping', pointer: props.shipping }])
    const stylesPayment = useClasses([ `${str}payment`, { active: props.active === 'payment', pointer: props.payment }])

    const { setRefreshOrder } = useContext(ShoppingCartEngineContext)

    const nav = useNavigate()

    return (
        <div className="checkout-wizard-container">
            <div className={stylesSummary} onClick={() => {
                if(props.summary) {
                    setRefreshOrder(true)
                    nav('/checkout')
                }
            } }>
                <p>Summary</p>
            </div>
            { !loggedIn?
            <div className={stylesAccount} onClick={() => props.account? nav('/create/account') : null }>
                <p>Account</p>
            </div> : null }
            <div className={stylesShipping} onClick={() => props.shipping? nav('/checkout/shipping') : null }>
                <p>Shipping</p>
            </div>
            <div className={stylesPayment} onClick={() => props.payment? nav('/checkout/payment') : null }>
                <p>Payment</p>
            </div>
        </div>
    )
}