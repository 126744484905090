import { useEffect, useState, useContext } from 'react'
import { TokenexService } from '../../../services/tokenex.service'
import { CheckoutContext } from '../../../providers/checkout'


declare global {
  interface Window {
      TokenEx: any;
  }
}

export const ShoppingCartPaymentTokenexComponent = (props: any) => {
    const [ tokenExConfig, setTokenExConfig ] = useState<any>({})
    const [ tokenExReady, setTokenExReady ] = useState<boolean>(false)
    const [ tokenExFetching, setTokenExFetching ] = useState(false)
    const [ tokenExData, setTokenExData ] = useState<any>({})
    // const [ iframe, setIframe ] = useState<any>()
    const [ iframeReady, setIframeReady ] = useState<boolean>(false)
    const [ tokenexToken, setTokenexToken ] = useState<string>('')
    const { form, setForm, formReady, setFormReady, setFormStep, setIframe } = useContext(CheckoutContext)
    // See if tokenex is ready
    if(!tokenExReady) {
        // If not ready and not fetching
        if(!tokenExFetching) {
          // Mark as being fetched
          setTokenExFetching(true)
          // Start fetching
          TokenexService().then(r => {
              // If it runs assign data and update state
              if(r.success) {
                setTokenExConfig(r.data.config)
              }
              // Attempted to fetch
              setTokenExReady(true)
              // Let interface know it's not fetching anymore
              setTokenExFetching(false)
          })
        }
    }
    useEffect(() => {
      if(tokenExReady) {
        // Once the script is loaded, you can access the class
        const TokenEx = window?.TokenEx;
        // Use the class as needed
        const f = new TokenEx.Iframe('tokenex-container', tokenExConfig)
        f.on('validate', (v: any) => {
          props.setError(!v.isValid? 'Please double check your credit card number or verify with your bank that the charge can be accepted.' : '')
        })
        // Set what happens on tokenize
        f.on('tokenize', (v: any) => {
          if(v?.token) {
            props.changeValue(`${v.token.slice(0, 2)}X${v.token.slice(2)}`, 'cardNumber')
            // let formData = ({...form, payment: {...form?.payment, cardNumber: `${v.token.slice(0, 2)}X${v.token.slice(2)}`}})
            // // Needs to have an X spliced in there
            // setForm? setForm(formData) : null
          } else {
            props.setError(v)
          }
          props.setTokenizeReady(true)
        })
        f.on('error', (e: any) => {
          props.setTokenizeReady(true)
          // props.setError(e.error)
        })
        f.load()
        setIframe(f)
        setIframeReady(true)
      }
    }, [ tokenExReady, tokenExConfig ])

  return (
      <div id="tokenex-container"></div>
  )
}