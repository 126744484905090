import { Button, TextField } from "@mui/material"
import { useContext, useState } from "react"
import { ResetPasswordProviderContext } from "../../providers/reset-password.provider"
import { PasswordResetPasswordService } from "../../services/password-reset.service"

export const PageLoginFormSubmitNewPassword = () => {

    const { currentState, setCurrentState, username, setMsg, loading, setLoading, codeToken, setMsgType } = useContext(ResetPasswordProviderContext)
    const [ password, setPassword ] = useState<string>('')
    const [ passwordConfirm, setPasswordConfirm ] = useState<string>('')

    const submitPassword = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setMsg('')
        setMsgType('success')
        setLoading(true)
        if(password !== passwordConfirm) {
            setMsg('Passwords do not match')
            setMsgType('danger')
            setLoading(false)
        } else {
            PasswordResetPasswordService(username, password, codeToken).then(r => {
                if(r.success) {
                    setCurrentState('login')
                    setMsg('Password has been reset!')
                } else {
                    setMsg(r.error)
                    setMsgType('danger')
                }
                setLoading(false)
            })
        }
    }

    return (
        (currentState === 'submit-newpassword')?
        <div>
            <h2 className='mb-3'>Create New Password</h2>
            <form action="#" onSubmit={(e) => submitPassword(e)} className={ `col-count-1 gapped ${loading? 'disabled' : ''}` }>
                <TextField label="Password" required={true} variant='outlined' value={password} onChange={(e) => {
                    setPassword(e.target.value)
                }} />
                <TextField label="Confirm Password" required={true} variant='outlined' value={passwordConfirm} onChange={(e) => {
                    setPasswordConfirm(e.target.value)
                }} />
                <Button type="submit" variant='contained' className='corp'>Update</Button>
            </form>
        </div> : null
    )
}