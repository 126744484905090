import { memo } from "react";
import img from './images/4.png'

const HeroUseYourWallet = memo(function HeroUseYourWallet(props?: any) {
    return (
    <div className="wallet wallet3 pad--lg">
        <img src={img} alt="" />
        <div className="container">
            <div className="col-md-6">
                <h2 className="title-slant">use your card <small>on any purchase</small></h2>
                <p>Depending on your membership status, you will receive an exclusive 24k Gold debit card. But this isn’t just an ordinary card. It’s a key to luxury. One that you can use to purchase premium goods and services around the world.</p>
                <ul className="ulbar">
                    <li>The lowest conversion rates</li>
                    <li>No staking required</li>
                    <li>Accepted by any Visa approved retailers.</li>
                </ul>
            </div>
        </div>
    </div>
    )
})

export default HeroUseYourWallet