import { useParams } from 'react-router-dom'
import { useState } from "react"
import { ProductsGetByCategoryService } from '../../services/products.service'
import Product from "../../components/shopping-cart/product"
import ProductMast from "../../components/shopping-cart/product-mast"
import CircularProgress from '@mui/material/CircularProgress'
import { currentPriceType } from '../../helpers/cart'
import { getSiteCountry } from '../../helpers/site'
import { AlertGeneral } from '../../components/alert'

export default function ProductGallery() {
    let { shopby, category, subcategory } = useParams()
    const [ pageReady, setPageReady ] = useState<boolean>(false)
    const [ products, setProducts ] = useState<any[]>([])
    const [ productsCount, setProductsCount ] = useState<number>(0)
    const [ currentCategory, setCurrentCategory ] = useState<string>(category || '')
    const [ currentSubCategory, setCurrentSubCategory ] = useState<string>(subcategory || '')
    const [ categoriesLoading, setCategoriesLoading ] = useState(false)

    // Make sure if the category is not working, make it switch to current
    if(category !== currentCategory) {
        setPageReady(false)
        setCurrentCategory(category || '')
    }

    if(currentSubCategory !== subcategory) {
        setCurrentSubCategory(subcategory || '')
        setPageReady(false)
    }

    if(!pageReady) {
        if(!categoriesLoading) {
            setCategoriesLoading(true)
            ProductsGetByCategoryService(category || '', {
                country: getSiteCountry().code,
                pricetype: currentPriceType(),
                subcategory: subcategory
            }).then(r => {
                setProducts(r.data)
                setProductsCount(r.count)
                setPageReady(true)
                setCategoriesLoading(false)
            })
        }
    }

    const inCategory = (v: any) => v.ShortDescription3.includes(category?.toLowerCase())
    const inSubCategory = (v: any) => v.ShortDescription3.includes(subcategory?.toLowerCase()) && inCategory(v)
    const inAllCategory = (v: any) => subcategory?.toLowerCase() === 'all' && inCategory(v)
    const getProducts = () => {
        let p: any[] = []
        products.map((v, k) => inSubCategory(v) || inAllCategory(v)? p.push(<Product key={k} {...v} />) : null)
        return p
    }
    return (
        pageReady? 
        <div id="product-gallery-container">
            { (productsCount > 0)? <ProductMast shopby={ shopby || '' } title={ subcategory || '' } productFamily={ subcategory || '' } productFamilyLink="" shopbyLink="" /> : null }
            { (productsCount > 0)?
            <div className="col-count-3 offset">
                <div className="start2 py-3">
                    <div id="product-gallery-grid" className="col-count-4 gapped col-c3-lg col-c2-md col-c1-sm">
                        { (pageReady && getProducts().length > 0)? getProducts().map((v, k) => v) : (!pageReady? <CircularProgress /> : <div className="align-middle span4 span3-lg span2-md span1-sm"><AlertGeneral type='info' content='There are no products in this category or are currently out of stock.' /></div>) }
                    </div> 
                </div>
            </div>
            
            : 
            <div className="full-height align-middle">
                <div className="alert alert-info">There are no products in this category. Perhaps you are trying to reach something that has been removed.</div>
            </div>
            }
        </div> : <div className="align-middle full-height"><CircularProgress /></div>
    )
}