import { IProcessProductByParam } from "../interfaces/shopping-cart-product.interface"

export interface IGetFirstOption
{
    ItemCode: any
}
export interface IGetOptionAttributes
{
   Key: string
   Checked: boolean
   ItemId: number
   Image: string
   ItemCode: string
   ShortDescription4: string
   StockLevels: any[]
}

export const getFirstOption = (props: any): IGetFirstOption | null => {
    try {
        return props.Options[0]
    } catch (Exception) {
        return null
    }
}

export const getOptionByOptionSku = (props: any, optionName: string): string => {
    let name = ''
    props.Options.map((v: any, k: any) => {
        if(v.ItemCode === optionName)
            name = v.Title
    })
    return name
}

export const getOptionAttributesBySku = (props: any, optionName: string): IGetOptionAttributes => {
    let option: any = {}
    props.Options.map((v: any, k: any) => {
        if(v.ItemCode === optionName)
            option = v
    })
    return option
}

export const processProduct = (
    props: any,
    option: string | null,
    itemcode: string,
    qty: number,
    subscribe: boolean,
    type: string
) => {
    return processProductByParam({
        props: props,
        isDefault: !props.HasOptions || false,
        itemcode: itemcode,
        productName: props.Title,
        qty: qty,
        image: props.ShortDescription4,
        price: props.Price,
        priceSubscribe: props.PricesAll.WH.Price,
        cv: props.CommissionableVolume,
        option: option,
        subscribe: subscribe,
        type: type
    })
}

export const processProductByParam = (props: IProcessProductByParam) => {
    return {
        itemcode: props.itemcode,
        qty: props.qty,
        image: props.isDefault? (props.image || '') : getOptionAttributesBySku(props.props, props.option).ShortDescription4 || (props.image || ''),
        title: (props.productName || '') + (props.option? ` - ${getOptionByOptionSku(props.props, props.option) }` : ''),
        tiers: props.price,
        tiersSubscribe: props.priceSubscribe,
        cv: props.cv,
        selected: ((props.isDefault && (props.option === 'none' || props.option === ''))? getFirstOption(props.props)?.ItemCode : props.option) || '',
        autoship: props.subscribe,
        type: props.type
    }
}