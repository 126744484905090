import { useState } from "react"
import { ProductsGetByCategoryService } from "../../../services/products.service"
import { CircularProgress } from "@mui/material"
import { IProductByCategory } from "../../../interfaces/product.interface"
import { IShoppingCartGallery } from "."
import { NavLink } from "react-router-dom"
import { getSiteCountry } from "../../../helpers/site"
import { currentPriceType } from "../../../helpers/cart"
import HeroLikeFollowTag from "../../hero/like-follow-tag"
import './styles/styles.scss'

export interface IShoppingCartFeaturetteGallery extends IShoppingCartGallery
{
    title: string
    body: string
}

export default function ShoppingCartFeaturetteGallery(props: IShoppingCartFeaturetteGallery)
{
    const [ products, setProducts ] = useState<IProductByCategory[]>([])
    const [ pageLoading, setPageLoading ] = useState<boolean>(false)
    const [ pageReady, setPageReady ] = useState<boolean>(false)
    const [ category, setCategory ] = useState<string>(props.category)
    
    if(!pageReady) {
        if(!pageLoading) {
            setPageLoading(true)
            ProductsGetByCategoryService(category, {
                country: getSiteCountry().code,
                pricetype: currentPriceType()
            }).then(r => {
                setProducts(r.data)
                setPageReady(true)
                setPageLoading(false)
            })
        }
    }

    return (
        pageReady? 
        <>
        <div className="col-count-3 offset">
            <div className="span3">
                <div className="banner3">
                    <div className="container">
                        <h1 className="title">{ props.title }</h1>
                        <p>{ props.body }</p>
                    </div>
                </div>
            </div>
            <div className="start2 overlap col-count- gapped">
            {   (products !== undefined) && products.length > 0?
                    products.map((v, k) => {
                        return (
                            (v.ShortDescription3 || []).includes(category)? 
                            <div className="rounded-large shadowed bkg-white p-4" key={k}>
                                <div className="col-count-2 gapped col-c1-lg">
                                    <div className="rounded-large"><img className="m-4" src={v.ShortDescription4 || ''} /></div>
                                    <div className="align-middle text-left mb-4 pb-4"><div dangerouslySetInnerHTML={{ __html: v.PackContents || '' }}></div></div>
                                </div>
                                <div className="align-middle pb-4">
                                    <NavLink className="button corp" to={`/product/${ v.ItemCode }`}>BUY NOW</NavLink>
                                </div>
                            </div> : null
                        )
                    })
                : null
            }
            </div>
        </div>
        <HeroLikeFollowTag />
        </> : <CircularProgress />
    )
}
