import { Button, CircularProgress, TextField } from "@mui/material"
import { useContext, useState } from "react"
import { ResetPasswordProviderContext } from "../../providers/reset-password.provider"
import { PasswordResetCodeService } from "../../services/password-reset.service"

export const PageLoginFormSubmitCode = () => {

    const { setMsgType, loading, currentState, username, setCurrentState, setLoading, setMsg, codeToken } = useContext(ResetPasswordProviderContext)
    const [ code, setCode ] = useState<string>('')

    const submitCode = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setLoading(true)
        PasswordResetCodeService(code, username, codeToken).then(r => {
            if(r.success) {
                setCurrentState('submit-newpassword')
            } else {
                setMsg(r.error)
                setMsgType('danger')
            }
            setLoading(false)
        })
    }

    return (
        (currentState === 'submit-code')?
        <div>
            <h2 className='mb-3'>Verification Code</h2>
            <p>Fill in the code you received from your email to finish your password reset.</p>
            <form action="#" onSubmit={(e) => submitCode(e)} className={`col-count-1 gapped ${loading? 'disabled' : ''}`}>
                <TextField className="large-code" variant='outlined' value={code} onChange={(e) => {
                    const v = e.target.value.replace(/[^\d]/gi,'')
                    let val = (v.length > 8)? v.substring(0, 8) : v
                    setCode(val)
                }} />
                { loading? <CircularProgress /> : <Button type="submit" variant='contained' className='corp'>Verfiy</Button> }
            </form>
        </div> : null
    )
}