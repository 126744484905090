import { IResponse } from "../interfaces/http.interface"
import { IShoppingCartOrder } from "../interfaces/shopping-cart-order.interface"
import { getCheckoutForm } from "../providers/checkout"
import { getCart } from "./cart"
import HttpClient from "./http"

export function OrderService<T>(body: any | void, action: string | void) {

    const a = action && ['submit'].includes(action) ? action : 'calculate'

    return (new HttpClient).post<IResponse<T>>(`order/${a}`, {
        cart: getCart(),
        account: getCheckoutForm(),
        data: body || {}
    })
}

export const OrderDetailsService = (distid: string, orderid: string) => {
    return (new HttpClient).get<IResponse<any>>('order/details', {distid, orderid})
}