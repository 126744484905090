import { useState, useContext } from 'react'
import { useNavigate, useParams } from "react-router"
import { SSOValidateLogin } from '../../services/sso.service'
import { CircularProgress } from '@mui/material'
import { AccountContext } from '../../providers/account'
import { AlertGeneral } from '../../components/alert'
import CacheService from '../../services/cache'
import { useSearchParams } from 'react-router-dom'

export const PageSSO = () => {

    const { setAccount } = useContext(AccountContext)
    const [ state, setState ] = useState({loading: false, ready: false, error: ''})
    const nav = useNavigate()
    const [ searchParams, setSearchParams ] = useSearchParams();
    
    const token = searchParams.get('token') || ''

    if(token !== '') {
        if(!state.ready) {
            if(!state.loading) {
                setState(arr => ({...arr, loading: true}))
                SSOValidateLogin(token || '').then(r => {
                    if(r.success) {
                        CacheService('token', r.data.jwt, true, true)
                        setAccount(r.data.account)
                        nav('/')
                        setState({ready: true, loading: false, error: ''})
                    } else {
                        setState(arr => ({...arr, error: r.error || 'An error occurred'}))
                    }
                })
            }
        }
    }

    return (
        <div className="full-height align-middle">
            { (state.error !== '')? <AlertGeneral type='danger' content={state.error} /> : <CircularProgress /> }
        </div>
    )
}