import { useContext, useState } from 'react'
import { Button, Tooltip } from "@mui/material"
import { ShoppingCartEngineContext } from '../../../providers/shopping-cart-engine'
import { deleteFromCart, getCart, subscriptionActive, updateCart, useSitePriceTypeStatus, wholesale } from '../../../services/cart'
import { dollars } from '../../../helpers/currency'
import { VisiToggle } from '../../form'
import { processProduct } from '../../../helpers/product'
import { ShoppingCartContext } from '../../../providers/cart'
import { currentPriceType } from '../../../helpers/cart'
import { NavLink } from 'react-router-dom'
import InfoIcon from '@mui/icons-material/Info'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import './styles/styles.scss'

interface IShoppingCartCheckout
{
    editable?: boolean
}

export default function ShoppingCartCheckout(props: IShoppingCartCheckout)
{
    const isEditable = (typeof props.editable !== "undefined")? props.editable : true

    const {
        setItem,
        setItemcode,
        setQty,
        setSubscribe,
        setUpdateComp,
        order,
        orderReady,
        setRefreshOrder,
        setOrderReady,
        itemsOnPromo
    } = useContext(ShoppingCartEngineContext)

    const { reloadSite, setToggleUpdate } = useContext(ShoppingCartContext)

    const refresh = () => {
        // This will tell parent to reload
        reloadSite(true)
        // Need to refresh the original order summary
        setRefreshOrder(true)
        setOrderReady(false)
    }

    const deleteItem = (v: any) => {
        // Remove from cart
        deleteFromCart(v.ItemCode || '', '', null)
        // Refresh cart an order
        refresh()
    }

    const alterQty = (v: any, qty: number) => {
        setItem(v)
        setItemcode(v.ItemCode)
        setQty(qty)
        setSubscribe(subscriptionActive(v.ItemCode,''))
        setUpdateComp(true)
        setOrderReady(false)
        setToggleUpdate(true)
        reloadSite(true)
        setRefreshOrder(true)
    }

    const add = (itemCode: any): number => {
        const i = +getCart()[itemCode]['options'][itemCode]['qty'] + 1
        return (i > 10)? 10 : i
    }

    const minus = (itemCode: any): number => +getCart()[itemCode]['options'][itemCode]['qty'] - 1

    return (
        
        <div className={`${orderReady? '' : 'order-summary-loading'}`}>
            <table className='my-4'>
                <tbody>{
                (order?.details && order.details.length > 0)? order?.details.map((v, k) => {
                    
                    const hasDiscount = (typeof itemsOnPromo?.products[v.ItemCode || ''] !== "undefined")
                    return (
                        <tr key={`${k} ${v.ItemCode}`} className='order-summary'>
                            <td><NavLink to={`/product/${v.ItemCode}`}>
                                    <img src={ v.ShortDescription4 } alt={`${v.ItemCode} product`} />
                                </NavLink>
                            </td>
                            <td>
                                <div className='p-4 order-summary-details col-count-3 gapped col-c1-md'>
                                    <div>
                                        <h4>{ v.Title }</h4>
                                        <p className={hasDiscount? 'has-discount' : ''}><strong className='wg-exclude'>{ dollars( currentPriceType() === wholesale? v.PricesAll.WH.Price : v.PricesAll.RE.Price ) } &times; {v.Quantity}</strong> | <span className='wg-exclude discounted-item'>{ Math.round(v.BusinessVolume || 0) }CV</span>
                                        
                                        { (hasDiscount)? <strong className='discounted-item'><br /><i className="fas fa-tags"></i>&nbsp;Price after discount: ${itemsOnPromo?.products[v.ItemCode || ''].totalAfterDiscount}</strong> : null }
                                        </p>
                                        { (v.ShortDescription2?.cartLock || []).includes('qty') || !isEditable? null : 
                                        <div className="col-count-3">
                                            <div className="plus-minus">
                                                <Button onClick={() => {
                                                    alterQty(v, minus(v.ItemCode || ''))
                                                }}><i className="fas fa-minus"></i></Button>
                                                <div>{ v.Quantity || 0 }</div>
                                                <Button onClick={() => {
                                                    alterQty(v, add(v.ItemCode || ''))
                                                }}><i className="fas fa-plus"></i></Button>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    <div>
                                        { v.AllowOnAutoOrder !== "0"? <ShoppingCartCheckoutSubscriptionToggle props={v} readOnly={!isEditable} { ...v } /> : null }
                                    </div>
                                    <div className='align-middle'>
                                        { isEditable?
                                            <><Button variant='contained' className='show-mobile' onClick={() => deleteItem(v)}>Delete</Button>
                                            <DeleteForeverIcon onClick={() => deleteItem(v)} className='show-desktop pointer' /></> : null
                                        }
                                        
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )
                }) : <tr><td><div className="align-middle corp">{ (Object.keys(getCart()).length > 0)? <CircularProgress /> : <h3><ProductionQuantityLimitsIcon />&nbsp;Nothing in the cart.</h3> }</div></td></tr>
                }</tbody>
            </table>
        </div>
    )
}

export const ShoppingCartCheckoutSubscriptionToggle = (props: any) => {
    const [ subsciptionToggled, setSubscriptionToggled ] = useState(subscriptionActive(props.ItemCode || '', ''))
    const {
        setItem,
        setOption,
        setItemcode,
        setQty,
        setSubscribe,
        setUpdateComp,
        setOrderReady
    } = useContext(ShoppingCartEngineContext)
    const { reloadSite } = useContext(ShoppingCartContext)

    const {type} = useSitePriceTypeStatus()
    // const getShoppingCartPriceType = (): string => {
    //     return cacheGet('cart.setting.priceType') || type
    // }
    // This will reset the toggle if the current item in the cart is conflicting with the stored status
    // Happens when the product is toggled off elsewhere in the app
    if(subsciptionToggled && !subscriptionActive(props.ItemCode || '', '')){
        setSubscriptionToggled(false)
    }
    
    const subscriptionMsg: JSX.Element = <>Your card will be charged and product will be shipped to you every 28 days. Cancel up to 5 days before your next order billing date by emailing <a href='mailto:support@iamvisi.com'>support@iamvisi.com</a></>

    return (
        <>
            <h6 className='corp'><strong>Subscribe &amp; Save { !subsciptionToggled?  <Tooltip className='inline corp' title={<span>{subscriptionMsg}</span>}><InfoIcon fontSize="small" /></Tooltip> : null }</strong></h6>
            <VisiToggle className={ (props?.readOnly || false)? 'readonly' : '' } checked={ subsciptionToggled } onChange={(e) => {
                setItemcode(props.ItemCode || '')
                setOption(null)
                setItem(props)
                setQty(props.Quantity || 1)
                setSubscriptionToggled(!subsciptionToggled)
                setSubscribe(e.target.checked)
                updateCart(processProduct(props, null, props.ItemCode || '', props.Quantity || 1, e.target.checked, type))
                reloadSite(true)
                setOrderReady(false)
                setUpdateComp(true)
            }} />
            { subsciptionToggled? <p className='legal mt-2'>{ subscriptionMsg }</p> : null }
        </>
    )
}