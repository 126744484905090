import { memo, useContext, useEffect, useState } from "react"
import { useParams } from "react-router"
import { ProductsGetBySkuService } from "../../../services/products.service"
import { IProduct } from "../../../interfaces/product.interface"
import { CircularProgress } from "@mui/material"
import { ShoppingCartContext } from "../../../providers/cart"
import ShoppingCartProductBuy from "./buy"
import DynamicComponentService from "../../../services/dynamic-component"
import HeroLikeFollowTag from "../../hero/like-follow-tag"

function ShoppingCartProductFeature()
{
    let { id } = useParams<string>()
    const [ pid, setPid ] = useState<string>(id || '')
    const [ productReady, setProductReady ] = useState<boolean>(false)
    const [ productLoading, setProductLoading ] = useState<boolean>(false)
    const [ data, setData ] = useState<IProduct>({})
    const {
        toggleSidebar,
        toggleSidebarStatus,
        setToggleUpdate,
        toggleUpdate,
        setModal,
        modal,
        setModalAttr,
        modalAttr,
        reloadSite,
        reloaded
      } = useContext(ShoppingCartContext)
    // Fetch and assign the product
    if(!productReady && id) {
        if(!productLoading) {
            setProductLoading(true)
            ProductsGetBySkuService(id).then(r => {
                setData(r.data)
                setProductReady(true)
                setProductLoading(false)
            })
        }
    }
    useEffect(() => {
        setProductReady(false)
    }, [id])
    
    return ((productReady && data?.ItemCode)?
        <div className="main">
            <div className="main probi" id="rmwcustfi">
                <div className="ptri sootheptri">
                    <div className="container container--sm">
                        {data.Image? 
                        <div className="row align-items-end">
                            <div className="col-md-5">
                                <div className="ptri__img">
                                    { data.ShortDescription4? <img src={ data.ShortDescription4 } alt={data.Title || 'Product Image'} className="v4015awusspidd" /> : '' }
                                </div>
                            </div>
                            <div className="col-md-7">
                                <h2>{ data.Title }<br /><small dangerouslySetInnerHTML={{ __html: data.ShortDescription || '' }}></small></h2>
                                <div className="ptri__bar"><span>&nbsp;</span></div>
                                <div className="ptri__text" dangerouslySetInnerHTML={{ __html: data.PackContents || '' }}></div>
                            </div>
                        </div>
                        :
                        <div className="align-middle">
                            <div className="alert alert-info">This product is coming soon!</div>
                        </div>
                        }
                    </div>
                </div>
            </div>
            { data.Image? <ShoppingCartProductBuy { ...data } /> : null }
            <DynamicComponentService itemcode={ id || '' } />
            <HeroLikeFollowTag />
        </div> :
        <div className="full-height align-middle">{ (productReady && !data?.ItemCode)? <div className="alert alert-info">&nbsp;Whoops! We can't find this product.</div> : <CircularProgress /> }</div>
    )
}

export default ShoppingCartProductFeature