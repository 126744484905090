import { createContext, useState } from "react"
import { IReactNodeProviderChildren } from "../interfaces/provider-base.interface"

interface IMenuNavContext
{
    setToggleActive?: any
    toggleActive?: any
    resetMenu?: any
    setMenuToggled?: any
    setActive?: any
    setMenuOption?: any
    isActive?: any
    menuToggled: boolean
    menuOption: string
    mostPopular: string[]
}

export const MenuNavContext = createContext<IMenuNavContext>({
    menuToggled: false,
    menuOption: '',
    mostPopular: []
})

export const MenuNavProvider = ({ children }: IReactNodeProviderChildren) =>
{
    const [ toggleActive, setToggleActive ] = useState<string>('')
    const [ menuOption, setMenuOption ] = useState<string>('')
    const [ menuToggled, setMenuToggled ] = useState<boolean>(false)

    return (
        <MenuNavContext.Provider
            value={{
                setToggleActive,
                setMenuToggled,
                setMenuOption,
                toggleActive,
                menuToggled,
                menuOption,
                mostPopular: [
                    'v3002cpus',
                    'v2003mrus',
                    'cleanse-and-detox',
                    'v6802cous',
                    'v6800wlus'
                ]
            }}
        >
            { children }
        </MenuNavContext.Provider>
    )
}