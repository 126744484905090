import { useContext } from "react"
import { NavLink } from "react-router-dom"
import { BenefitsBeauty, BenefitsNutrition, BenefitsOils, BroadcastProNav, BundleNutrition, BundlesDigital, ILoveCheapTravelNav, ProductsBeauty, ProductsNutrition, ProductsOils, SavvyWalletNav } from "./menu-items"
import { MenuNavContext } from "../../../providers/menunav.provider"
import mnuNut from '../images/header/mnu_nutrition.png'
import mnuBeauty from '../images/header/mnu_beauty.png'
import mnuDig from '../images/header/mnu_digital.png'
import mnuOil from '../images/header/mnu_oils.png'
import mnuOpp from '../images/header/mnu_opportunity.png'

export const HeaderMenuDesktop = () => {

    const { setToggleActive, toggleActive } = useContext(MenuNavContext)
    const doToggle = (name: string) => setToggleActive(toggleActive === name? '' : name)
    const toggleClass = (name: string) => toggleActive === name? 'active' : ''

    return (
    <nav className="naver main-nav">
        <ul className="navmenu">
            <li>
                <NavLink to="#" onClick={()=> doToggle('nutrition')}>nutrition</NavLink>
                <div className={`msub ${ toggleClass('nutrition') }`}>
                    <div className="container">
                        <div className="msub__inner">
                            <div className="msub__col">
                                <ul>
                                    <li><img src={ mnuNut } alt="nutrition menu" /></li>
                                </ul>
                            </div>
                            <div className="msub__col">
                                <h5>SHOP BY BENEFIT</h5>
                                <BenefitsNutrition />
                            </div>
                            <div className="msub__col">
                                <h5>SHOP BY PRODUCT</h5>
                                <ProductsNutrition />
                            </div>
                            <div className="msub__col">
                                <h5>SHOP BY BUNDLE</h5>
                                <BundleNutrition />
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <NavLink to="#" onClick={()=> doToggle('beauty')}>beauty</NavLink>
                <div className={`msub ${ toggleClass('beauty') }`}>
                    <div className="container">
                        <div className="msub__inner">
                            <div className="msub__col">
                                <ul>
                                    <li><img src={mnuBeauty} alt="beauty menu" /></li>
                                </ul>
                            </div>
                            <div className="msub__col">
                                <h5>SHOP BY BENEFIT</h5>
                                <BenefitsBeauty />
                            </div>
                            <div className="msub__col">
                                <h5>SHOP BY PRODUCT</h5>
                                <ProductsBeauty />
                            </div>
                            <div className="msub__col">
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <NavLink to="/product/v6736fous" onClick={()=> doToggle('oils')}>oils</NavLink>
                <div className={`msub ${ toggleClass('oils') }`}>
                    <div className="container">
                        <div className="msub__inner">
                            <div className="msub__col">
                                <ul>
                                    <li><img src={mnuOil} alt="oils menu" /></li>
                                </ul>
                            </div>
                            <div className="msub__col">
                                <h5>SHOP BY BENEFIT</h5>
                                <BenefitsOils />
                            </div>
                            <div className="msub__col">
                                <h5>SHOP BY PRODUCT</h5>
                                <ProductsOils />
                            </div>
                            <div className="msub__col">
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <NavLink to="/collagen-story" onClick={ () => doToggle('') }>collagen story</NavLink>
            </li>
            <li>
                <NavLink to="/memberships" onClick={ ()=> doToggle('digital') }>digital</NavLink>
                <div className={`msub ${ toggleClass('digital') }`}>
                    <div className="container">
                        <div className="msub__inner">
                            <div className="msub__col flex-0-0-5">
                                <ul>
                                    <li><img src={mnuDig} alt="digital menu" /></li>
                                </ul>
                            </div>
                            <div className="msub__col">
                                <h5><NavLink to="/memberships" onClick={() => doToggle('')}>BUNDLES</NavLink></h5>
                                <BundlesDigital />
                            </div>
                            <div className="msub__col">
                                <h5><NavLink to="/savvy-wallet/how-it-works" onClick={() => doToggle('')}>SAVVY Wallet</NavLink></h5>
                                <SavvyWalletNav />
                            </div>
                            <div className="msub__col">
                                <h5><NavLink to="/broadcast-pro" onClick={() => doToggle('')}>10X Academy</NavLink></h5>
                                <BroadcastProNav />
                            </div>
                            <div className="msub__col">
                                <h5><NavLink to="/travel" onClick={ () => doToggle('') }>I Love Cheap Travel</NavLink></h5>
                                <ILoveCheapTravelNav />
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <NavLink to="#" onClick={ ()=> doToggle('opportunity') }>opportunity</NavLink>
                <div className={`msub ${ toggleClass('opportunity') }`}>
                    <div className="container">
                        <div className="msub__inner">
                            <div className="msub__col">
                                <ul>
                                    <li><img src={mnuOpp} alt="opportunity menu" /></li>
                                </ul>
                            </div>
                            <div className="msub__col">
                                <h5><NavLink to="/success-stories" onClick={ () => doToggle('') }>Success Stories</NavLink></h5>
                            </div>
                            <div className="msub__col">
                                <h5><NavLink to="/opportunity" onClick={ () => doToggle('') }>Opportunity &amp; Promotions</NavLink></h5>
                            </div>
                            <div className="msub__col"></div>
                            <div className="msub__col"></div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </nav>
    )
}