import { useContext, useState, useEffect } from "react"
import { ResetPasswordProviderContext } from "../../providers/reset-password.provider"
import { setShoppingCartPriceType } from "../../helpers/cart"
import { Button, CircularProgress, TextField } from "@mui/material"
import AuthService from "../../services/auth"
import CacheService from "../../services/cache"
import { AccountContext } from "../../providers/account"

interface IPagesLoginFormLogin
{
    location: any
}

export const PageLoginFormLogin = ({location}: IPagesLoginFormLogin) => {
    
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const { setMsg, setMsgType, username, setLoading, loading, setUsername, currentState, setCurrentState } = useContext(ResetPasswordProviderContext);
    const { setAccount } = useContext(AccountContext);

    useEffect(() => {
        if (submitting) {
            setMsg('');
            if (username === '' || password === '') {
                setMsg(`Invalid ${username === '' ? 'email address' : 'password'}`);
                setSubmitting(false);
                setLoading(false);
            } else {
                setSubmitting(false);
                AuthService(username, password).then(r => {
                    if (r.success) {
                        // Make sure to update the pricetype
                        setShoppingCartPriceType(r.account.priceTypeDescription);
                        // Store the account
                        setAccount(r.account);
                        // Store the token
                        CacheService('token', r.jwt, true, true);
                        // Redirect
                        location();
                    } else {
                        setMsg(r.msg || 'Invalid Email or Password');
                        setMsgType('danger');
                        setLoading(false);
                    }
                });
            }
        }
    }, [ submitting, username, password ]);

    return ( (currentState === 'login')? 
        <form action="#sign-in">
            <div className="col-count-1 gapped">
                <h2 className='mb-3' id="sign-in">Sign In</h2>
                    <TextField disabled={loading} variant='outlined' label="Member Id or Username" required type='text' value={username} onChange={ (e: any) => setUsername(e.target.value.trim()) } />
                    <TextField disabled={loading} variant='outlined' label="Password" required type='password' value={password} onChange={ (e: any) => setPassword(e.target.value.trim()) } />
                <div className="align-middle mt-4">
                { loading? <CircularProgress /> : <Button className='corp' variant='contained' disabled={submitting} onClick={() => {
                        setSubmitting(true)
                        setLoading(true)
                    } }>Sign In</Button> }
                </div>
                <div className="align-middle">
                    <a href="#" className='legal' onClick={() => {
                        setCurrentState('forgot-password')
                        setMsg('')
                    }} >Forgot your password?</a>
                </div>
            </div>
        </form>
        : null
    )
}