import React, { useEffect, useRef } from "react"
import CustomModal from "../../../components/modal/custom-modal"
import { setSiteCountry } from "../../../helpers/site"
import { ICountry } from "../../../interfaces/country.interface"
import { resetCart } from "../../../services/cart"

interface CountrySelectModalProps {
    showModal: boolean,
    countries: ICountry[]
    country: ICountry
    handleClose: () => void,
    setCountry: (country: ICountry) => void,
}

const CountrySelectModal = ({ showModal, countries, country, handleClose, setCountry}: CountrySelectModalProps) => {
    const modalRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
          if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
            handleClose()
          }
        }
    
        if (showModal) {
          document.addEventListener("mousedown", handleOutsideClick)
        }
    
        return () => {
          document.removeEventListener("mousedown", handleOutsideClick)
        }
    }, [ showModal, handleClose ])

    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const co = countries.filter((el) => el.code === e.target.value)[0]
        // If the country is korea, redirect
        if(co.code === 'KR') {
            // Redirect to illsong
            window.location.href = import.meta.env.VITE_KOREAN_URL as string
        } else {
            // Set the country
            setSiteCountry(co)
            setCountry(co)
            // Reset the cart so the user has to use country-appropriate products
            resetCart()
            // Reload the page (mostly because products are dependant on the country and
            // easiest thing is to reload than to create a provider and all that)
            window.location.reload()
        }
    }

    return (
        <CustomModal title="Select Country" showModal={showModal} handleClose={ handleClose }>
            <div className="country-select-modal-body" ref={modalRef}>
                <select onChange={ onChange } value={country?.code}>
                { countries && countries.map((country: ICountry) => (
                    <option value={country.code} key={country.code}>
                        {country.name}
                    </option>
                    ))}
                </select>
            </div>
        </CustomModal>
    )
}

export default CountrySelectModal
