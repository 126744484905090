import { ShoppingCartProvider } from './providers/cart'
import { AccountProvider } from './providers/account'
import { SiteProvider } from './providers/site.provider'
import React from 'react'
import ReactDOM from 'react-dom/client'
import VisiRouter from './router'
import 'bootstrap/dist/css/bootstrap.css'
import './media/styles/global.scss'
import * as Sentry from '@sentry/react' 


Sentry.init({
  dsn: "https://7ff8c64747424f9d81e04a9f8bb08af8@o4504770119073792.ingest.sentry.io/4505536684097536",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["https:visi-global.com"],
    }),
    new Sentry.Replay({
      networkDetailAllowUrls: [
        window.location.origin
      ],
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <SiteProvider>
      <AccountProvider>
        <ShoppingCartProvider>
          <VisiRouter />
        </ShoppingCartProvider>
      </AccountProvider>
    </SiteProvider>
  </React.StrictMode>
)