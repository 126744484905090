import React, { useContext, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { CircularProgress } from '@mui/material'
import { updateCartCount } from './services/cart'
import { ModalCustom, ModalThinking } from './components/modal'
import { ShoppingCartContext } from './providers/cart'
import { ShoppingCartEngineProvider } from './providers/shopping-cart-engine'
import { CheckoutProvider } from './providers/checkout'
import Template from './templates/visi-2023/template'
import Header from './templates/visi-2023/header'
import Footer from './templates/visi-2023/footer'
import ProductGallery from './templates/visi-2023/product-gallery'
import ShoppingCartSidebar from './components/shopping-cart/sidebar'
import ShoppingCartProductFeature from './components/shopping-cart/product-feature'
import PageHome from './pages/home'
import PageSavvyWalletCompare from './pages/savvywallet/compare'
import PageSavvyWalletHowItWorks from './pages/savvywallet/how-it-works'
import PageTravel from './pages/travel'
import PageAccount from './pages/account'
import PageBroadcastPro from './pages/broadcast-pro'
import ShoppingCartFeaturetteGallery from './components/shopping-cart/gallery/featurettes'
import PageLogin from './pages/login'
import PageCartOrderSummary from './pages/cart-order-summary'
import PageCartCheckout from './pages/cart-checkout'
import PageShipAddress from './pages/ship-address'
import PageOrderProduct from './pages/order-product'
import { setSiteEnroller, getSubDomain } from './helpers/site'
import OrderSuccessPage from './pages/order-success'
import PageJoin from './pages/join'
import { MenuNavProvider } from './providers/menunav.provider'
import { PageSSO } from './pages/sso'

// Lazy load for better performance
const PageCollagenStory = React.lazy(() => import('./pages/collagen-story'));
const PageSuccessStories = React.lazy(() => import('./pages/success-stories'));
const PageOpportunity = React.lazy(() => import('./pages/opportunity'));

export interface IModal
{
  title: string
  content: string
  buttons: string
  thinking: boolean
}

export default function VisiRouter()
{
  const {
    setModal,
    modal,
    modalAttr,
    reloaded
  } = useContext(ShoppingCartContext)

  useEffect(() => {
    updateCartCount('cartcount')
  })
  
  setSiteEnroller(getSubDomain())

  return (
    <div id="visi-application">
        <BrowserRouter>
          <ShoppingCartEngineProvider>
            <CheckoutProvider>
              <MenuNavProvider>
                {/** Lazy loading content fallback when content is not fully loaded yet. Don't remove or the site won't load */}
                <React.Suspense fallback={<div className='full-height align-middle'><CircularProgress /></div> }>
                  <Routes>
                      <Route path='/' element={ <Template body={ <PageHome  /> } header={ <Header /> } footer={ <Footer /> } /> } />
                      
                      <Route path='/login' element={ <Template body={ <PageLogin /> } header={ '' } footer={ '' } /> } />
                      
                      <Route path='/order/success/:distid/:orderid' element={ <Template body={<OrderSuccessPage />} header={ <Header /> } footer={ <Footer /> } /> } />
                      
                      <Route path='/checkout' element={ <Template body={ <PageCartOrderSummary />} header={ <Header /> } footer={ <Footer /> } /> } />
                      <Route path='/checkout/customer' element={ <Template body={ <PageCartCheckout />} header={ <Header /> } footer={ <Footer /> } /> } />
                      <Route path='/create/account' element={ <Template body={ <PageAccount /> } header={ <Header /> } footer={ <Footer /> } /> } />
                      <Route path='/checkout/shipping' element={ <Template body={ <PageShipAddress /> } header={ <Header /> } footer={ <Footer /> } /> } />
                      <Route path='/checkout/payment' element={ <Template body={ <PageOrderProduct /> } header={ <Header /> } footer={ <Footer /> } /> } />
                      <Route path='/membership/join' element={ <Template body={ <PageJoin />} header={ <Header /> } footer={ <Footer /> } /> } />

                      <Route path='/product/:shopby/:category/:subcategory' element={ <Template body={ <ProductGallery /> } header={ <Header /> } footer={ <Footer /> } /> } />
                      <Route path='/product/:id' element={ <Template body={!reloaded? <ShoppingCartProductFeature />  : <CircularProgress /> } header={ <Header /> } footer={ <Footer /> } /> } />

                      <Route path='/memberships' element={ <Template body={!reloaded? <ShoppingCartFeaturetteGallery category='bundle' title='digital bundles' body='Each digital bundle offers its own unique set of benefits. Review the features below to find out which one is right for you.' />  : <CircularProgress /> } header={ <Header /> } footer={ <Footer /> } /> } />
                      <Route path='/collagen-story' element={ <Template body={ <PageCollagenStory /> } header={ <Header /> } footer={ <Footer /> } /> } />
                      <Route path='/success-stories' element={ <Template body={ <PageSuccessStories /> } header={ <Header /> } footer={ <Footer /> } /> } />
                      <Route path='/opportunity' element={ <Template body={ <PageOpportunity /> } header={ <Header /> } footer={ <Footer /> } /> } />
                      <Route path='/travel' element={ <Template body={ <PageTravel /> } header={ <Header /> } footer={ <Footer /> } /> } />
                      <Route path='/broadcast-pro' element={ <Template body={ <PageBroadcastPro /> } header={ <Header /> } footer={ <Footer /> } /> } />
                      <Route path='/savvy-wallet/how-it-works' element={ <Template body={ <PageSavvyWalletHowItWorks /> } header={ <Header /> } footer={ <Footer /> } /> } />
                      <Route path='/savvy-wallet/compare' element={ <Template body={ <PageSavvyWalletCompare /> } header={ <Header /> } footer={ <Footer /> } /> } />

                      <Route path='/sso' element={ <Template body={ <PageSSO /> } header={ <Header /> } footer={ <Footer /> } /> } />

                      <Route path='*' element={ <Template body={ <PageHome  />} header={ <Header  /> } footer={ <Footer /> } /> } />
                  </Routes>
                </React.Suspense>
              </MenuNavProvider>
              </CheckoutProvider>
            <ShoppingCartSidebar />
          </ShoppingCartEngineProvider>
        </BrowserRouter>
        { modal && !modalAttr?
          <div className="py-3">
            <ModalCustom closeEvent={() => setModal(false)} { ...modalAttr } />
          </div> :
          (modal && modalAttr.thinking?
          <div className="py-3">
            <ModalThinking />
          </div> : null ) }
    </div>
  )
}