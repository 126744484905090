import { memo } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export const HeroProteinAbsorbtion = memo(function HeroProteinAbsorbtion(props?: any) {

    // pieChart('chart1', 95, 5);
    // pieChart('chart2', 53, 47);
    // pieChart('chart3', 26, 74);
    // pieChart('chart4', 16, 84);

    
    return (
        <div className="hm4 pad hero-protein-absorbtion">
            <div className="container">
                <h2 className="bltitle bltitle--center uptrm">How much protein your body is actually able to use:</h2>
                <div className="charts col-count-4 gapped col-c2-lg col-c1-md">
                    <PieChart title="Hydrolyzed Collagen" value={95} />
                    <PieChart title="Whey" value={53} />
                    <PieChart title="Soy" value={26} />
                    <PieChart title="Pea" value={16} />
                </div>
            </div>
        </div>
    )
})

export default HeroProteinAbsorbtion

interface IPieChart
{
    value: number
    title: string
}


export function PieChart(props: IPieChart)
{
    const createChartOpts = (value: number) => {
        return {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie',
				height: 200
			},
			credits: {
				enabled: false
			},
			title: {
				text: ''
			},
			tooltip: {
				pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
				enabled: false
			},
			accessibility: {
				enabled: false,
				point: {
					valueSuffix: '%'
				}
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true,
						format: '{point.percentage:.1f} %',
						distance: -50,
					}
				}
			},
			series: [{
				name: 'Brands',
				colorByPoint: true,
				data: [{
					name: 'Chrome',
					y: value,
					selected: true,
					color: '#9fd7dc'
				},{
					name: 'Other',
					y: +100 - +value,
					color: '#edddc8',
					dataLabels: {
						enabled: false
					}
				}]
			}]
		}
    }

    return (
        <div className="chart-container poppin">
            <HighchartsReact highcharts={Highcharts} options={ createChartOpts(props.value) } />
            <p className="align-middle">{ props.title }</p>
        </div>
    )
}