import { memo } from "react";
import './styles/styles.scss'
import followerImg from './images/follower.png'
import customerImg from './images/customer.png'
import earnImg from './images/earn.png'
import instaImg from './images/instagram.png'
import milkImg from './images/milk-shake.png'
import tikImg from './images/tiktok.png'
import { NavLink } from "react-router-dom";


export const HeroCashinSocialMedia = memo(function HeroCashinSocialMedia(props?: any){
    return (
        <div className="hm8 pad hero-cashin-social-media">
            <div className="container">
                <div className="l1">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="l1__left">
                                <div className="l1__item">
                                    <p className="vplblc">Gain 20,000 new followers!</p>
                                    <img src={followerImg} alt="" />
                                </div>
                                <div className="l1__item">
                                    <p className="vplblc">New customers every week</p>
                                    <img src={customerImg} alt="" />
                                </div>
                                <div className="l1__item">
                                    <p className="vplblc">Earn an extra $1,000 / week </p>
                                    <img src={earnImg} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="l1__big">
                                <div>
                                    <h2 className="ttline">Cash in on social media</h2>
                                    <ul className="cashinsocialmedia">
                                        <li>- &nbsp;how to make short video content </li>
                                        <li>- &nbsp;30 days of content made for YOU</li>
                                        <li>- &nbsp;proven viral marketing strategies</li>
                                        <li>- &nbsp;get more comfortable creating content</li>
                                        <li>- &nbsp;grow your account &amp; get customers.</li>
                                        <li>- &nbsp;back end business model</li>
                                        <li>- &nbsp;custom landing page tutorial</li>
                                        <li>- &nbsp;link your saved shopping cart</li>
                                    </ul>
                                    <div className="l1__soc">
                                        <img src={tikImg} alt="" />
                                        <img src={instaImg} alt="" />
                                        <img src={milkImg} alt="" />
                                    </div>
                                </div>
                                <NavLink to="/memberships" className="button corp">PURCHASE COURSE</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default HeroCashinSocialMedia