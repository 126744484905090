import CacheService, { cacheGet } from "../services/cache"
import { getCart, getItemQty, useSitePriceTypeStatus, retail } from "../services/cart"
import { getProductFilterWH } from "./site"

export interface IOrderSummary
{
    subTotal: number
    totalCV: number
    total: number
    tax?: number
    lineItems: any[]
}

export const cartCalculate = (priceType: string = retail): IOrderSummary | null => {
    const priceTypeFromSite = getShoppingCartPriceType()
    const cart = getCart()
    let subTotal: number = 0
    let totalCV: number = 0
    let lineItems: any = []
    if(cart.length === 0)
        return null
    
    Object.keys(cart).map((v, k) => {
        Object.keys(cart[v]['options']).map((itemcode, key) => {
            const item = cart[v]['options'][itemcode]
            const selectedPrice = ((priceTypeFromSite !== retail)? item.tiersSubscribe : item.tiers)
            const itemSubtotal = item.qty * selectedPrice
            totalCV += (item.qty * item.cv)
            subTotal += itemSubtotal
            lineItems.push({
                itemcode,
                ...item,
                selectedPrice,
                itemSubtotal
            })
        })
    })
    const total = subTotal
    return { lineItems, totalCV, subTotal, total }
}

export const setShoppingCartPriceType = (type: string = retail) => {
    CacheService('cart.setting.priceType', type, true, true)
}

export const getShoppingCartPriceType = (): string => {
    const {type} = useSitePriceTypeStatus()
    return cacheGet('cart.setting.priceType') || type
}

export const shoppingCartStatusSet = () => cacheGet('cart.setting.priceType') !== ''

export const currentPriceType = () => cacheGet('cart.setting.priceType') || 'RE'

export const hasQualifyingWHInCart = (): boolean => {
    const filter = getProductFilterWH()
    let hasQualifying = false
    for(const k of filter) {
        if(getItemQty(k, '')) {
            hasQualifying = true
            break
        }
    }
    return hasQualifying
}