import HeroArcticCloudberry from "../../components/hero/arctic-cloudberry";
import HeroBusinessBenefits from "../../components/hero/business-benefits";
import HeroCashinSocialMedia from "../../components/hero/cashin-social-media";
import HeroCollagenChallenge from "../../components/hero/collagen-challenge";
import HeroFamilyCarousel from "../../components/hero/family-carousel";
import { HeroJoin } from "../../components/hero/join";
import HeroLikeFollowTag from "../../components/hero/like-follow-tag";
import HeroMainProductMast from "../../components/hero/main-product-mast";
import HeroProteinAbsorbtion from "../../components/hero/protein-absorbtion";
import { ICoreFeatures } from "../../interfaces/core";

export default function PageHome(props: ICoreFeatures) {
    return (
        <div className="main">
            <HeroMainProductMast />
            <HeroFamilyCarousel />
            {/* <HeroCollagenChallenge /> */}
            <HeroProteinAbsorbtion />
            <HeroArcticCloudberry />
            <HeroJoin />
            <HeroBusinessBenefits />
            <HeroCashinSocialMedia />
            <HeroLikeFollowTag />
        </div>
    )
}