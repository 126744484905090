import { memo, useContext, useState, useEffect } from "react"
import { NavLink } from "react-router-dom"
import { ShoppingCartContext } from "../../providers/cart"
import { HeaderMenuMobile } from "./header/menu-mobile"
import { HeaderMenuDesktop } from "./header/menu-desktop"
import { cacheGet } from "../../services/cache"
import { getUnitCount, updateCartCount } from "../../services/cart"
import { ShoppingCartEngineContext } from "../../providers/shopping-cart-engine"
import { signOutReload } from "../../helpers/account"
import { ICountry } from "../../interfaces/country.interface"
import { getSiteCountry } from "../../helpers/site"
import { SitetContext } from "../../providers/site.provider"
import { MenuNavContext } from "../../providers/menunav.provider"
import { AccountGetSSO } from "../../services/account"
import { AlertGeneral } from "../../components/alert"
import { Button } from "@mui/material"
import { AccountContext } from "../../providers/account"
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import LogoutIcon from '@mui/icons-material/Logout'
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout'
import CountrySelectModal from "./header/country-select-modal"
import visiLogo from './images/visi_logo.png'
import './styles/_style.scss'

const Header = memo(function Header() {
    const [ country, setCountry ] = useState<ICountry>(getSiteCountry())
    const [ countryModal, setCountryModal ] = useState(false)
    
    // Fetch from providers
    const { toggleSidebar, toggleSidebarStatus } = useContext(ShoppingCartContext)
    const { countries, countriesReady } = useContext(SitetContext)
    const { setMenuToggled, menuToggled, setToggleActive } = useContext(MenuNavContext)

    const fetchCountries = async () => {
        if(countriesReady) {
            setCountry(country);
        }
    }

    useEffect(() => { fetchCountries() }, [])
    useEffect(() => updateCartCount('cartcount'))

    return (
    <header className="header">
        <div className="ht">
            <div className="container">
                <div className="ht__inner">
                </div>
            </div>
        </div>
        <div className="container container---full">
            <div className="row align-items-center-on-mobile">
                <div className="col-md-2 logo-container">
                    <div className="logo">
                        <span className="menu-tog">
                            <span className="fa fa-bars" aria-hidden="true" onClick={() => {
                                setMenuToggled(!menuToggled)
                            }}></span>
                        </span>
                        <NavLink to="/" className="pointer" onClick={() => {
                            setMenuToggled(false)
                            setToggleActive('')
                        }}><img src={ visiLogo } alt="Visi Logo" /></NavLink>
                    </div>
                </div>
                <div className="col-md-8 header-menu-desktop-container">
                    <HeaderMenuDesktop />
                </div>
                <div className="col-md-2 right-menus-container">
                    <div className="header__right">
                        <nav className="naver">
                            <ul className="navmenu">
                                <li className={`dropdown-container`}>
                                    <AccountMenu />
                                </li>
                                <li><NavLink to='#' onClick={() => toggleSidebar(!toggleSidebarStatus)}><i className="fa fa-shopping-cart" aria-hidden="true"></i> <span id="cartcount" className="cartcount">0</span></NavLink> </li>
                                {country && <li>
                                    <NavLink to="#" onClick={() => setCountryModal(true)}>
                                        <img src={country.flag} alt="flag" className="country"/>
                                    </NavLink>
                                </li>}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <div className={ `resmenu ${ menuToggled? 'active' : '' }` }>
            <HeaderMenuMobile />
        </div>
        <CountrySelectModal
            showModal={countryModal}
            countries={countries}
            country={country}
            handleClose={() => setCountryModal(false)}
            setCountry={ setCountry } 
        />
            
    </header>
    )
})

export default Header

export const AccountMenu = (props?: any) => {

    const [ toggleAccount, setToggleAccount ] = useState<boolean>(false)
    const { toggleSidebar } = useContext(ShoppingCartContext)
    const { setRefreshOrder, setOrderReady } = useContext(ShoppingCartEngineContext)
    const [ token, setToken ] = useState({loading: false, ready: false})

    const { setModal, setModalAttr } = useContext(ShoppingCartContext)
    const { account } = useContext(AccountContext)
    const loggedIn = cacheGet('token')
    const itemsInCart = getUnitCount()
    const refresh = () => {
        setToggleAccount(false)
        setRefreshOrder(true)
        toggleSidebar(false)
        setOrderReady(false)
    }

    const goToBackOffice = () => {
        if(!token.ready) {
            setModal(true)
            setModalAttr({
                title: '',
                content: '',
                buttons: '',
                thinking: true
            })

            if(!token.loading) {
                setToken(arr => ({...arr, loading: true}))
                const id = account?.customerID
                if(id !== undefined) {
                    AccountGetSSO(id).then(r => {
                        window.location.href = r.data.url
                    })
                } else {
                    setModalAttr({
                        title: 'Invalid Request',
                        content: <AlertGeneral type="danger" content="An error occurred signing into your back office. Reload your page, perhaps your login has expired." />,
                        buttons: <div className="align-middle"><Button onClick={() => window.location.reload() } variant="contained">Okay</Button></div>,
                        thinking: false
                    })
                    throw Error("User is invalid when attempting to single sign on.")
                }
            }
        }
    }

    return (
        <>
        <NavLink to={loggedIn? '#' : '/login' } className="mobh__lang mr-1"><AccountCircleIcon onClick={() => { loggedIn? setToggleAccount(!toggleAccount) : null }} /></NavLink>
        { loggedIn? 
        <ul className={`drop-menu ${toggleAccount? ' active' : ''}`}>
            { itemsInCart? <li><NavLink to="/checkout" onClick={refresh}><ShoppingCartCheckoutIcon />&nbsp;Checkout</NavLink></li> : null }
            <li><NavLink to="#" onClick={ goToBackOffice }><ManageAccountsIcon />&nbsp;Back Office</NavLink></li>
            <li><NavLink to='#' onClick={ signOutReload }><LogoutIcon />&nbsp;Sign Out</NavLink></li>
        </ul>
        : null }
        </>
    )
}