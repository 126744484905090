import { IAccount } from "../interfaces/account.interface"
import { IResponse } from "../interfaces/http.interface"
import HttpClient from "./http"

export interface AccountResponse extends IAccount
{
    error?: boolean
    msg?: string
}

export default function Account(setAccount: any)
{
    (new HttpClient).get<AccountResponse>('account').then(r => {
        if(r.customerID && r.customerID > 0) {
            setAccount(r)
        }
    })
}

export const AccountService = (): Promise<IResponse<AccountResponse>> =>
{
    return (new HttpClient).get<IResponse<AccountResponse>>('account')
}

interface IAccountGetSSO
{
    url: string
}

export const AccountGetSSO = (id: number) =>
{
    return (new HttpClient).get<IResponse<IAccountGetSSO>>('sso', { customerID: id })
}