import ReactDOM from 'react-dom';
import React, { ReactNode } from "react"

interface CustomModalProps {
  showModal: boolean;
  handleClose: () => void;
  children: ReactNode;
  title?: string;
}
const CustomModal = ({ title, showModal, handleClose, children }: CustomModalProps) => {
  return (
    <React.Fragment>
      {showModal &&
        ReactDOM.createPortal(
          <div className="custom-modal">
            <div className='custom-modal__container'>
              <div className='custom-modal__header'>
                <h3>{title}</h3>
                <span onClick={handleClose}>&times;</span>
              </div>
              <div className='custom-modal__content'>
                {children}
              </div>
              <div className='custom-modal__footer'>
                <button type="button" className="button button--transter">Close</button>
              </div>
            </div>
          </div>, // Wrap the children prop in JSX
          document.getElementById('root')!
        )}
    </React.Fragment>
  );
};

export default CustomModal;