import { memo } from "react"
import HeroLikeFollowTag from "../../components/hero/like-follow-tag"
import img1 from './images/1.png'
import img2 from './images/2.png'
import img3 from './images/3.png'
import img5 from './images/5.png'
import img6 from './images/6.png'
import './styles/styles.scss'
import { NavLink } from "react-router-dom"

const PageTravel = memo(function PageTravel(props?: any) {
    return (
    <div className="main mt-4">
        <div className="img3">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-4">
                        <div className="img3__item">
                            <img src={img1} alt="" />
                        </div>
                    </div>
                    <div className="col-md-4 col-4">
                        <div className="img3__item">
                            <img src={img2} alt="" />
                        </div>
                    </div>
                    <div className="col-md-4 col-4">
                        <div className="img3__item">
                            <img src={img3} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="travel travel1">
            <div className="container container--sm">
                <h2 className="title-slant">travel the world<small>without the expensive price tag</small></h2>
                <p>Travel to the world’s most amazing destinations at a rate everyone can afford. Vísi has partnered with the aggregators that supply properties to all the big online travel sites, you get the views you want at the price you want. Who doesn’t love cheap travel?!</p>
            </div>
            <NavLink to="/memberships" className="button corp">VIEW MEMBERSHIPS</NavLink>
        </div>

        <div className="travel travel2">
            <div className="container container--sm">
                <div className="tbox">
                    <h2>included with every<br />Digital Membership</h2>
                    <h3>Retails for $49.95 a month</h3>
                    <p>Access the LOWEST RATES ONLINE! You’ll pay for your<br /> membership in savings after just 1-2 bookings!</p>
                </div>
            </div>
        </div>

        <div className="travel3 pad--lg">
            <div className="container">
                <div className="soc4__inner">
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="tertiary">member’s only booking portal</h2>
                            <ul className="ulbar">
                                <li>Access to over 1,000,000 hotel properties worldwide</li>
                                <li>Save up to 65% off your hotel bookings</li>
                                <li>5-star properties</li>
                                <li>Access to 700+ airlines</li>
                                <li>Book your favorite Cruise line</li>
                                <li>Book your favorite car rentals</li>
                            </ul>
                            <a href="/login" className="button">LOGIN</a>
                        </div>
                        <div className="col-md-6">
                            <div className="soc4__img"><img src={img5} alt="" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="travel4 pad--lg">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="travel4__img">
                            <img src={img6} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="travel4__text">
                            <h2>how does it work?</h2>
                            <p>1. PURCHASE YOUR MEMBERSHIP. Our low cost monthly membership will unlock all the travel deal where you can save up-to 65% on your favorite hotels around the world.</p>
                            <p>2. SEARCH. BOOK &amp; SAVE. Search with confidence. Our search engine displays the lowest available rates compared to 70 other public travel search engines.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <HeroLikeFollowTag />
    </div>
    )
})

export default PageTravel