export function count(obj: any): number
{
    return (typeof obj === "object")? Object.keys(obj).length : obj.length
}

export function compare(obj: any, obj2: any): boolean
{
    let same = true
    Object.keys(obj).map((v, k) => {
        if(typeof obj2[v] === "undefined")
            same = false
    })

    return same;
}

export function year(): number
{
    return (new Date()).getFullYear()
}

export function isNumeric(value: any): boolean
{
    if (typeof value === "number") {
        return true
    }

    if (typeof value === "string" && !isNaN(Number(value))) {
        return value !== ''
    }

    return false
}

export const filterValidateAlpha = (v: string) => v.replaceAll(/[^\A-Z]/gi, '')
export const filterValidateNumeric = (v: string) => v.replaceAll(/[^\d]/gi, '')
export const filterValidateAlphaNumeric = (v: string) => v.replaceAll(/[^\w]/gi, '')
export const filterValidateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
}

export const expYear = (): any[] => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const yearSpan = []
  
    for (let i = 0; i < 10; i++) {
      const year = currentYear + i
      yearSpan.push([year.toString().slice(-2), year])
    }
  
    return yearSpan
  }

export const expMonth = (): any[] => {
    const months = [];
  
    for (let i = 1; i <= 12; i++) {
      const month = i.toString().padStart(2, '0');
      months.push(month);
    }
  
    return months;
  }