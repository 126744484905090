import { memo } from "react"
import img1 from "./images/1.png";
import img2 from "./images/2.png";
import img3 from "./images/3.png";
import img4 from "./images/4.png";
import img5 from "./images/5.png";
import img6 from "./images/6.png";
import './styles/styles.scss'

const HeroLikeFollowTag = memo(function HeroLikeFollowTag(props?: any){
    return (
        <div className="insta pad hero-like-follow-tag">
            <div className="container">
                <div className="insta__title">
                    <h2>like. follow. tag.</h2>
                    <h2>@visiglobal</h2>
                </div>
                <div className="insta__items">
                    <div className="insta__item insta__item--1">
                        <div><img src={img1} alt="" /></div>
                    </div>
                    <div className="insta__item insta__item--2">
                        <div><img src={img2} alt="" /></div>
                        <div><img src={img3} alt="" /></div>
                    </div>
                    <div className="insta__item insta__item--1">
                        <div><img src={img4} alt="" /></div>
                    </div>
                    <div className="insta__item insta__item--2">
                        <div><img src={img5} alt="" /></div>
                        <div><img src={img6} alt="" /></div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default HeroLikeFollowTag