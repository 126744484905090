import { memo } from "react";
import img1 from './images/arctic.png'
import img2 from './images/independent.png'
import './styles/styles.scss'

export const HeroJoin = memo(function HeroJoin(props?: any) {
    return (
        <div className="hm6 pad hero-join-now">
            <div className="container container--sm">
                <div className="row">
                    <div className="col-md-6">
                        <div className="box1">
                            <img src={img1} alt="" />
                            <div className="box1__gradient"></div>
                            <div className="box1__inner">
                                <h2 className="boxtitle">preferred customer</h2>
                                <h3>15% discount</h3>
                                <a href="/membership/join" className="button">JOIN NOW</a>
                            </div>
                        </div>
                    </div><div className="col-md-6">
                        <div className="box1">
                            <img src={img2} alt="" />
                            <div className="box1__gradient"></div>
                            <div className="box1__inner">
                                <h2 className="boxtitle">independent partner</h2>
                                <h3>25% discount + commissions</h3>
                                <a href="/membership/join" className="button">JOIN NOW</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})