import AliceCarousel from 'react-alice-carousel'
import './styles/styles.scss'
import arctic from './images/arctic-white.png'
import immun from './images/immuniti.png'
import lyfta from './images/lyfta.png'
import prob from './images/probita.png'
import relief from './images/relief.png'

export default function HeroFamilyCarousel(props?: any)
{
    const images = [
        <CarouselContent image={relief} content="essential oils" />,
        <CarouselContent image={prob} content="collagen" />,
        <CarouselContent image={arctic} content="28-day kits" />,
        <CarouselContent image={lyfta} content="energy+mood" />,
        <CarouselContent image={immun} content="weight loss" />,
    ]
    return (
        <div className="hm2 pad family-carousel">
            <div className="container">
                <div className="carousel-tray">
                    <div>
                        <h3>clean products you’ll love <span className="circle-icon"><i className="fa fa-arrow-right" aria-hidden="true"></i></span></h3>
                    </div>
                    <div>

                        <AliceCarousel
                            touchTracking={true}
                            disableButtonsControls={true}
                            responsive={{
                                0: {
                                    items: 1,
                                },
                                768: {
                                    items: 3,
                                    itemsFit: 'contain',
                                },
                                1024: {
                                    items: 5,
                                    itemsFit: 'contain',
                                }
                            }}
                            items={images}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

interface ICarouselContent 
{
    image: string
    content: any
}

export function CarouselContent(props: ICarouselContent)
{
    return (
        <div className="pcarousel__item slick-slide slick-active">
            <div className="pcarousel__img">
                <div>
                    <img src={ props.image } alt="" />
                </div>
            </div>
            { props.content }
        </div>
    )
}