import React, { ChangeEvent } from "react";
import { useClasses } from "../../hooks/useClasses"

interface IpnutProps {
  label: string;
  required: boolean;
  field: string;
  form?: any;
  maxLength?: number;
  placeholder?: string;
  type: string;
  fullWidth?: boolean;
  error?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Input = ({ label, required, type, field, fullWidth, placeholder, form, maxLength, onChange, error }: IpnutProps) => {
    const classes = useClasses(["input-field", { "w-full": fullWidth, "required": required }]);

    return (
        <div className={classes}>
            <label htmlFor={field}>
                {label}
            </label>
            <input required={required} type={type} onChange={onChange} id={field} placeholder={placeholder? placeholder : ""} className="form-control" value={ form[field] || '' } { ...typeof maxLength !== "undefined"? {maxLength} : null } />
            {error && <p className="error">{error}</p>}
        </div>
    );
};

export default Input;