import { useState } from "react"
import { filterValidateAlpha } from "../../helpers/util"
import FormGroup from "../form/form-group"
import Input from "../form/input"
import Select from "../form/select"
import { SiteServiceGetRegions } from "../../services/site.service"
import { getSiteCountry } from "../../helpers/site"

interface IFormAddressComponent
{
    address: any
    onChangeValue: any
    title: any
    countries: any[]
}

export const FormAddressComponent = (props: IFormAddressComponent) =>
{
    const [ states, setStates ] = useState({states: {}, loading: false, ready: false})
    const [ country, setCountry ] = useState<string>(getSiteCountry().code)

    const getCountry = () => SiteServiceGetRegions(country).then(r => {
        setStates(arr => ({states: r.data.regions, loading: false, ready: true}))
    })

    if(!states.ready) {
        if(!states.loading) {
            setStates(arr => ({...arr, loading: true}))
            getCountry()
        }
    }

    return (
        <FormGroup title={ props.title }>
            <Input
                type="text"
                label="Full Name"
                required={true}
                form={props.address}
                field="fullName"
                placeholder="Enter full name here"
                onChange={e => props.onChangeValue(e, "fullName")}
            />
            <Input
                type="text"
                label="Company"
                required={false}
                field="company"
                form={props.address}
                placeholder="Enter company name (optional)"
                onChange={e => props.onChangeValue(e, "company")}
            />
            <Input
                type="text"
                label="Address Line 1"
                required={true}
                form={props.address}
                field="address1"
                placeholder="Enter Street Address here"
                onChange={e => props.onChangeValue(e, "address1")}
            />
            <Input
                type="text"
                label="Address Line 2"
                required={false}
                form={props.address}
                field="address2"
                placeholder="Address Line 2"
                onChange={e => props.onChangeValue(e, "address2")}
            />
            <Input
                type="text"
                label="City"
                required={true}
                form={props.address}
                field="city"
                placeholder="Enter City here"
                onChange={e => props.onChangeValue(e, "city")}
            />
            <Select
                field="country"
                label="Select Country"
                required={true}
                form={props.address}
                options={props.countries.map((v, k) => {
                    return { value: v.code, label: v.name }
                })}
                onChange={e => {
                    props.onChangeValue(e, "country")
                    setCountry(e.target.value)
                    setStates(arr => ({...arr, ready: false, loading: false}))
                }}
            />

            { states.ready? <Select
                field="state"
                label="Select State/Province"
                required={false}
                form={props.address}
                options={Object.keys(states.states).map((v: string, k) => {
                    return { value: v, label: states.states[v as keyof typeof states.states] }
                })}
                onChange={e => props.onChangeValue(e, "state")}
            /> : null }
            
            <Input
                type="text"
                label="Post Code"
                required={true}
                field="zipcode"
                form={props.address}
                placeholder="Enter Post Code here"
                onChange={e => props.onChangeValue(e, "zipcode")}
            />
            <Input
                type="text"
                label="Phone Number"
                required={true}
                form={props.address}
                field="phone"
                placeholder="Enter Phone Number"
                onChange={e => props.onChangeValue(e, "phone")}
            />
        </FormGroup>
    )
}