import HttpClient from "./http";
import { IResponse } from "../interfaces/http.interface";

interface IUserExistCheckService
{
    success: boolean
    error?: string
    exists: boolean
}

export function UserExistCheckService(username: string): Promise<IUserExistCheckService>
{
    return (new HttpClient()).get<IUserExistCheckService>(`customer/exists?username=${username}`)
}