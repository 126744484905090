import './styles/styles.scss'

export interface ITemplate
{
    header: any
    footer: any
    body: any
    sidebar?: any
}


export default function Template(props: ITemplate)
{
    return (
        <div className="full-height">
            <header>
                { props.header }
            </header>
            <div className="body-content">
                { props.body }
            </div>
            <footer>
                { props.footer }
            </footer>
        </div>
    )
}