import { memo } from "react";

import elevateImg from './images/elevate.png'
import iglifeImg from './images/iglife.png'
import keyImg from './images/key.png'
import './styles/styles.scss'
import { NavLink } from "react-router-dom";

export const HeroBusinessBenefits = memo(function HeroBusinessBenefits(props?: any) {
    return (
        <div className="hm7 pad hero-business-benefits">
            <div className="container">
                <h2 className="title title--center">multiply your wealth <span>from your phone</span></h2>
                <div className="row">
                    <div className="col-md-4">
                        <div className="box1">
                            <img src={keyImg} alt="" />
                            <div className="box1__gradient"></div>
                            <div className="box1__inner">
                                <h2 className="boxtitle">key to luxury</h2>
                                <p>Elevate your status with a 24k gold card  with multi-currency capability</p>
                                <NavLink to="/savvy-wallet/how-it-works" className="button corp">LEARN MORE</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="box1">
                            <img src={elevateImg} alt="" />
                            <div className="box1__gradient"></div>
                            <div className="box1__inner">
                                <h2 className="boxtitle">elevate your wealth</h2>
                                <p>Earn more with auto-sync tech, to make more money in less time</p>
                                <NavLink to="/broadcast-pro" className="button corp">LEARN MORE</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="box1">
                            <img src={iglifeImg} alt="" />
                            <div className="box1__gradient"></div>
                            <div className="box1__inner">
                                <h2 className="boxtitle">ig life = your reality</h2>
                                <p>Travel the world and enhance your life like the influencer you are</p>
                                <NavLink to="/travel" className="button corp">LEARN MORE</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default HeroBusinessBenefits