import { Button, TextField } from "@mui/material"
import { AlertGeneral } from "../../components/alert"
import { HeroJoinNowComponent } from "../../components/hero/join-now"
import { getSiteEnroller, toDistDomain } from "../../helpers/site"
import { CircularProgress } from '@mui/material'
import { useState } from "react"
import { UserExistCheckService } from "../../services/user.service"
import { environment } from "../../environmentals/config"
import './styles/styles.scss'

export default function PageJoin(props?: any)
{
    const [ username, setUsername ] = useState<string>('')
    const [ msg, setMsg ] = useState<string>('')
    const [ unameLoading, setUnameLoading ] = useState<boolean>(false)
    const enroller = getSiteEnroller()
    const inValidDistId = (enroller === '')

    const submitChange = (e: any) => {
        if(!unameLoading) {
            setUnameLoading(true)
            // Restore message
            setMsg('')
            // Stop submission
            e.preventDefault()
            // Check if the user exists
            UserExistCheckService(username).then(r => {
                if(r.success) {
                    if(r.exists) {
                        window.location.href = `https://${username}.${environment.url}` 
                    } else {
                        setMsg('Invalid Member Id')
                    }
                } else {
                    setMsg(r.error || '')
                }
                setUnameLoading(false)
            })
        }
    }

    return (
        <div className="col-count-3 offset bgjoinoptions">
            <div className="start2 bgjoinoptions" id="bgjoinoptions">
                { inValidDistId? 
                    <div className="mt-1 mb-4 bgjoinoptions">
                        <div className="container">
                            <div className="opp1__inneraa">
                                <div className="opp1__contentaa" style={{ backgroundColor: "#fcf8f2" }}>
                                    <div className="opp1aa__cont">
                                        <p className="alreadyhaveaccount">Already have an account?</p>
                                        <div className="opp1__buttons mb-4">
                                            <a href="/login" className="button joinlogin">LOGIN</a>
                                        </div>
                                        <div className="row mt-4 mb-4">
                                            <div className="col-md-2"></div>
                                            <div className="col-md-8">
                                                <div className="joinpanel">
                                                    <div className="joinpcontent">
                                                        <h2 className="mb-4 jnhding">Referred By</h2>
                                                        <p>Please enter your sponsor code<br />or referral code to continue.</p>
                                                        { msg !== ''? <AlertGeneral type="danger" content={msg} /> : null }
                                                        <form action="#" onSubmit={(e) => { submitChange(e) }} className="col-count- gapped">
                                                            <TextField variant="outlined" value={username} className="corp" disabled={unameLoading} onChange={(e) => {
                                                                setUsername(e.target.value)
                                                            }} />
                                                            <Button variant="contained" className="corp" type="submit">{ !unameLoading? 'Check' : <CircularProgress /> }</Button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="mt-1 mb-4" style={{ backgroundColor: "#edddc8" }}></div>
                    </div>
                :
                <HeroJoinNowComponent
                    contentOnEitherTrue={
                        <AlertGeneral
                            type="info"
                            content="You have qualified for an automatic upgrade or you have already added a Membership to your cart."
                        />}
                />
                }
            </div>
        </div>
    )
}