import { ReactNode, createContext, useState } from "react"
import { IPageAccountForm } from "../pages/account"
import { cache, cacheGet } from "../services/cache"

const cacheName = 'checkout.form'

export const getCheckoutForm = (): ICheckoutContextData => JSON.parse(cacheGet(cacheName, false) || '{}')
export const clearCheckoutForm = () => cache(cacheName, '{}')
const toCheckoutForm = (form: ICheckoutContextData) => {
    let f = JSON.parse(JSON.stringify(form))
    if(f?.account?.password || false) {
        f.account.password = ''
    }
    cache(cacheName, JSON.stringify(f))
}

export interface ICheckoutContextData
{
    account?: IPageAccountForm
    shipping?: any
    payment?: any
}

export interface ICheckoutContext
{
    form?: ICheckoutContextData
    formReady?: boolean
    setForm?: React.Dispatch<React.SetStateAction<ICheckoutContextData>>
    setFormReady?: React.Dispatch<React.SetStateAction<boolean>>
    setFormSubmit?: React.Dispatch<React.SetStateAction<boolean>>
    setFormStep?: React.Dispatch<React.SetStateAction<string>>
    formStep: string
    iframe: any
    setIframe: any
}

export const CheckoutContext = createContext<ICheckoutContext>({formStep: '', iframe: () => {}, setIframe: () => {}})

type ICheckoutContextChildren = {
    children: ReactNode
}

export const CheckoutProvider = ({ children }: ICheckoutContextChildren) => {
    const [ form, setForm ] = useState<ICheckoutContextData>(getCheckoutForm())
    const [ formReady, setFormReady ] = useState<boolean>(true)
    const [ formSubmit, setFormSubmit ] = useState<boolean>(false)
    const [ formStep, setFormStep ] = useState<string>('')
    const [ iframe, setIframe ] = useState<any>({})

    if(!formReady) {
        toCheckoutForm(form)
        setFormReady(true)
    }

    if(formSubmit) {
        toCheckoutForm(form)
        setFormSubmit(false)
    }
    
    return (
        <CheckoutContext.Provider value={ { form, formStep, formReady, setForm, setFormReady, setFormSubmit, setFormStep, iframe, setIframe } }>
            { children }
        </CheckoutContext.Provider>
    )
}