import { ReactNode, createContext, useState } from "react"
import { cacheDestroyTargets, cacheGet } from "../services/cache"
import { decodeJwt } from "../services/token"
import { setShoppingCartPriceType, shoppingCartStatusSet } from "../helpers/cart"
import { retail } from "../services/cart"
import { IComponentState } from "../interfaces/component-state.interface"
import { AccountService } from "../services/account"

export interface IAccountContext
{
    account?: any
    setAccount?: any
}

export const AccountContext = createContext<IAccountContext>({})

type IAccountContextChildren = {
    children: ReactNode
}

export const AccountProvider = ({children}: IAccountContextChildren) => {
    const [ account, setAccount ] = useState<any>({})
    const [ state, setState ] = useState<IComponentState>({ready: false, loading: false})
    const token = cacheGet('token')
    let jwt: any = {}
    if(token) {
        jwt = decodeJwt(token)
        if(jwt.expired) {
            cacheDestroyTargets(true)
        }
        if(!state.ready) {
            if(!state.loading) {
                setState(arr => ({...arr, loading: true}))
                AccountService().then(r => {
                    if(!r.error) {
                        setAccount(r.data)
                    } else {
                        setAccount({})
                    }
                    
                    if(!shoppingCartStatusSet()) {
                        setShoppingCartPriceType((!r.error)? r.data.priceTypeDescription : retail)
                    }
                    setState({ready: true, loading: false})
                })
            }
        }
    } else {
        if(!state.ready) {
            if(!shoppingCartStatusSet())
                setShoppingCartPriceType(retail)
            setAccount({})
            setState({ready: true, loading: false})
        }
    }

    return (
        state.ready?
        <AccountContext.Provider value={{ account, setAccount }}>
            { children }
        </AccountContext.Provider> :
        null
    )
}