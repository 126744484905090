import { IResponse } from "../interfaces/http.interface"
import HttpClient from "./http"

interface ITokenexService
{
    config: any
    token: string
}

export const TokenexService = (): Promise<any> => {
    return (new HttpClient).get<IResponse<any>>('order/tokenex')
}