import { ReactNode, createContext, useState, useEffect, useContext } from 'react'
import { cartHasAutoship, useSitePriceTypeStatus, retail, subscriptionActive, updateCart, wholesale } from '../services/cart'
import { processProduct } from '../helpers/product'
import { IShoppingCartOrder } from '../interfaces/shopping-cart-order.interface'
import { OrderService } from '../services/order.service'
import { useLocation } from 'react-router'
import { hasQualifyingWHInCart, setShoppingCartPriceType } from '../helpers/cart'
import { getSiteCountry, getSiteEnroller } from '../helpers/site'
import { ShoppingCartContext } from './cart'
import { ProductsGetItemsByCountryService } from '../services/products.service'
import { IPromoItems, PromotionsService } from '../services/promotions.service'


interface IItemsOnPromo
{
    loading: boolean
    products: IPromoItems
}

export interface IShoppingCartEngineContext
{
    itemList?: any
    setItemListReady?: any
    setItem?: any
    setOption?: any
    setItemcode?: any
    setQty?: any
    setSubscribe?: any
    setUpdateComp?: any
    setOrder?: any
    order?: IShoppingCartOrder
    setRefreshOrder?: any
    orderReady?: boolean
    setOrderReady?: any
    siteOwner?: string,
    itemsOnPromo?: IItemsOnPromo
    // sitePriceType: string
}

export const ShoppingCartEngineContext = createContext<IShoppingCartEngineContext>({})

type IShoppingCartEngineChildren = {
    children: ReactNode
}

export const ShoppingCartEngineProvider = ({children}: IShoppingCartEngineChildren) => {

    const [ siteOwner, setSiteOwner ] = useState<string>('')
    const [ item, setItem ] = useState<any>({})
    const [ qty, setQty ] = useState<number>(0)
    const [ option, setOption ] = useState<string>('')
    const [ itemcode, setItemcode ] = useState<string>('')
    const [ updateComp, setUpdateComp ] = useState(false)
    const [ refreshOrder, setRefreshOrder ] = useState(false)
    const [ subscribe, setSubscribe ] = useState(subscriptionActive(itemcode, option))
    const [ order, setOrder ] = useState<IShoppingCartOrder>({})
    const [ orderReady, setOrderReady ] = useState<boolean>(false)
    const [ itemList, setItemList ] = useState<any[]>([])
    const [ itemListReady, setItemListReady ] = useState<boolean>(false)
    const [ itemLisLoading, setItemListLoading ] = useState<boolean>(false)
    const [ orderFetching, setOrderFetching ] = useState(false)
    const [ itemsOnPromo, setItemsOnPromo ] = useState<IItemsOnPromo>({ loading: false, products: {} })

    const { setToggleUpdate } = useContext(ShoppingCartContext)
    const { type } = useSitePriceTypeStatus()
    const location = useLocation()
    const reset = () => {
        setToggleUpdate(true)
        setRefreshOrder(true)
        setOrderReady(false)
    }
    // Set products for use
    if(!itemListReady) {
        if(!itemLisLoading) {
            setItemListLoading(true)
            ProductsGetItemsByCountryService(getSiteCountry().code, type).then(r => {
                setItemList(r.data)
                setItemListReady(true)
                setItemListLoading(false)
            })
        }
    }
    
    if(updateComp) {
        updateCart(processProduct(item, option, itemcode, qty, subscribe, type))
        setUpdateComp(false)
    }

    if(refreshOrder) {
        setOrderReady(false)
        setRefreshOrder(false)
    }

    // This will refresh the order summary data
    if(!orderReady) {
        // If actually the the summary page run the order summary check
        if(location.pathname.match(/checkout|payment/gi)) {
            if(!orderFetching) {
                // Update to let interface know calc is running
                setOrderFetching(true)
                // Run the calculation service
                OrderService<IShoppingCartOrder>(null).then(r => {
                    // Assign the data to the order state
                    if(r.success)
                        setOrder(r.data)
                    // Set it ready to be checked again
                    setRefreshOrder(false)
                    // Set the order fetching status to done
                    setOrderFetching(false)
                    // Make sure the order is marked ready to go
                    setOrderReady(true)
                })
            }
        } else {
            setRefreshOrder(false)
            setOrderReady(true)
        }
    }
    // Check to see if any product is on promo
    useEffect(() => {
        if(!itemsOnPromo.loading) {
            setItemsOnPromo((arr: any)=> ({...arr, loading: true}))
            PromotionsService().then(r => {
                setItemsOnPromo({loading: false, products: r.data })
            })
        }
    }, [ orderReady ])

    // Change the pricetype if there are certain skus in the cart
    useEffect(() => {
        setSiteOwner(getSiteEnroller())
    }, [ getSiteEnroller() ])
    // Checks if there are items in the cart that qualify for wholesale
    useEffect(() => {
        let s = hasQualifyingWHInCart()? wholesale : retail
        if(cartHasAutoship() && s === retail) {
            s = wholesale
        }
        setShoppingCartPriceType(s)
        reset()
    }, [ hasQualifyingWHInCart() ])

    return (
        <ShoppingCartEngineContext.Provider value={{
            setItem,
            setOption,
            setItemcode,
            setQty,
            setSubscribe,
            setUpdateComp,
            setOrder,
            setRefreshOrder,
            setOrderReady,
            setItemListReady,
            order,
            orderReady,
            siteOwner,
            itemList,
            itemsOnPromo       
            // sitePriceType
        }}>
            { children }
        </ShoppingCartEngineContext.Provider>
    )
}