import { lazy, Suspense, useState } from 'react';
import { CircularProgress } from '@mui/material';
import ProductComponentNull from '../components/product/dynamic-null';
/**
 * @description This is allows for autoloading components from the products folder
 */
export interface IDynamicComponentService
{
  itemcode: string | null;
}
/* @vite-ignore */
export default function DynamicComponentService(props: IDynamicComponentService)
{
    // Set whether the component exists
    const [ exists, setExists ] = useState(true)
    let DynamicComponent;
    // Load the component
    DynamicComponent = lazy(() => import(`../components/product/dynamic-${props.itemcode}.tsx`).catch(r => setExists(false)))
    // This is required or else the thing will stop displaying due to error
    if(DynamicComponent) {
        if(exists) {
            // Return back the component
            return (
                <Suspense fallback={<div className='align-middle full-height'><CircularProgress /></div>}>
                    <DynamicComponent />
                </Suspense>
            )
        }
    }
    // Return empty by default
    return <ProductComponentNull />
}