import { useState, useContext } from 'react'
import { setShoppingCartPriceType } from "../helpers/cart"
import { getProductFilterWH } from "../helpers/site"
import { count } from "../helpers/util"
import { IUpdateCart } from "../interfaces/shopping-cart-product.interface"
import CacheService, { cache, cacheGet } from "./cache"
import { AccountContext } from '../providers/account'

const cartName = 'cart.main'
const cartSubscription = 'cart.subscription'

export const wholesale: string = 'WH'
export const retail: string = 'RE'

interface IuseSitePriceTypeStatus
{
    type: string
}

export const useSitePriceTypeStatus = (): IuseSitePriceTypeStatus => {
    const [ type, setType ] = useState<string>(retail)
    const { account } = useContext(AccountContext)
    if(account?.priceTypeDescription)
        setType(account?.priceTypeDescription)

    return { type }
}

export const resetCart = () => cache(cartName, '{}')

export function setCartToCache(name: string, body: any)
{
    cache(name, JSON.stringify(body))
}
/**
 * @description This is the main add/remove from cart function
 * @param product The product details from the API
 * @returns The assembled cart
 */
export function updateCart(product: IUpdateCart) {
    const whprods = getProductFilterWH()
    let cart: any = getCart()
    let cartStatus: string = product.type
    const productFocus = (product.selected === '')? product.itemcode : product.selected
    // Stop if adding no qty when it doesn't exist anyway
    if(product.qty === 0 && typeof cart[product.itemcode] === "undefined") {
        // Set retail or whatever the default user type is
        setShoppingCartPriceType(cartStatus)
        return cart
    }
    // Assemble any arrays necessary
    if(typeof cart[product.itemcode] === "undefined") {
        cart[product.itemcode] = {}
        if(typeof cart[product.itemcode]['options'] === 'undefined')
            cart[product.itemcode]['options'] = {}
    }
    if(typeof cart[product.itemcode]['options'][productFocus] === "undefined")
        cart[product.itemcode]['options'][productFocus] = {}
    // Set the quantity now   
    cart[product.itemcode]['options'][productFocus]['qty'] = (product.qty > 0) ? product.qty : 0
    // If the the quantity not a plus value, remove it
    if (cart[product.itemcode]['options'][productFocus]['qty'] <= 0) {
        delete cart[product.itemcode]['options'][productFocus]
    } else {
        cart[product.itemcode]['options'][productFocus]['title'] = product.title
        cart[product.itemcode]['options'][productFocus]['tiers'] = Math.round(+product.tiers * 100) / 100
        cart[product.itemcode]['options'][productFocus]['tiersSubscribe'] = Math.round(+product.tiersSubscribe * 100) / 100
        cart[product.itemcode]['options'][productFocus]['image'] = product.image
        cart[product.itemcode]['options'][productFocus]['cv'] = product.cv
        cart[product.itemcode]['options'][productFocus]['autoship'] = product.autoship || false
        // If the autoship for the product is on OR there is a qualifying itemcode
        if(product.autoship || whprods.includes(product.itemcode)) {
            // Set wholesale
            cartStatus = wholesale
        }
    }
    setCartToCache(cartName, cartCleanUp(cart, cartStatus))
    return getCart()
}

export function getCart()
{
    return JSON.parse(cacheGet(cartName) || '{}')
}

export function deleteFromCart(itemcode: string, selected: string, qty: number | null)
{
    let cart: any = getCart()
    const opt = (selected === '')? itemcode : selected
    if(typeof cart[itemcode] === "undefined")
        return getCart()
    
    if(typeof cart[itemcode]['options'] === "undefined") {
        if(qty === null) {
            delete cart[itemcode]
        }
    } else {
        if(typeof cart[itemcode]['options'][opt] === "undefined")
            return getCart()

        if(qty === null) {
            delete cart[itemcode]['options'][opt]
        } else {
            cart[itemcode]['options'][opt]['qty'] = +cart[itemcode]['options'][opt]['qty'] - +qty
            if(cart[itemcode]['options'][opt]['qty'] <= 0) {
                delete cart[itemcode]['options'][opt]
            }
        }
        if(count(cart[itemcode]['options']) === 0) {
            delete cart[itemcode]
        }
    }
    // Save the cart
    setCartToCache(cartName, cartCleanUp(cart))
    return getCart()
}

export function updateCartCount(className: string)
{
    // let count = 0
    // const cart = getCart()

    // Object.keys(cart).map((v, k) => {
    //     Object.keys(cart[v]['options']).map((val, key) => {
    //         count += 1
    //     })
    // })
    const counts = document.getElementsByClassName(className)
    Object.keys(counts).map((v, k) => {
        counts[k].innerHTML = getUnitCount().toString() //count.toString()
    })
}

export function getUnitCount()
{
    let count = 0
    const cart = getCart()

    Object.keys(cart).map((v, k) => {
        Object.keys(cart[v]['options']).map((val, key) => {
            
            count += +cart[v]['options'][val]['qty']
        })
    })
    return count
}

export function getItemQty(itemcode: string, selected: string)
{
    const cart = getCart()
    if(typeof cart[itemcode] === "undefined")
        return 0
    if(typeof cart[itemcode]['options'] === "undefined")
        return 0
    if(typeof cart[itemcode]['options'][selected] === "undefined") {
        if(selected === '') {
            if(typeof cart[itemcode]['options'][itemcode] === "undefined") {
                return 0
            } else {
                return cart[itemcode]['options'][itemcode]['qty'] || 0
            }
        } else {
            return 0
        }
    }
    return cart[itemcode]['options'][selected]['qty'] || 0
}

export function getSubscriptionCart()
{
    return JSON.parse(cacheGet(cartSubscription) || '{}')
}
/**
 * @description This will add or remove the autoship from an itemcode the determine if there are any items in the cart that allow for wholesale status
 */
export function updateSubscription(itemcode: string, selected: string, add: boolean = true)
{
    const {type} = useSitePriceTypeStatus()
    // Fetch the status from the token or else set retail
    let cartStatus: string = type
    // Fetch the cart
    const cart = getCart()
    try {
        // Try and remove or add the autoship
        cart[itemcode]['options'][selected === ''? itemcode : selected]['autoship'] = add
    } catch (Exception) {
        console.log(Exception)
    }
    // Save the cart
    setCartToCache(cartName, cartCleanUp(cart, cartStatus))
    return cart
}


const cartCleanUp = (cart: any, cartStatus: string = retail) => {
    const whprods = getProductFilterWH()
    Object.keys(cart).map((v, k) => {
        Object.keys(cart[v]['options']).map((val, key) => {
            if (cart[v]['options'][val] === null && cart[v]['options'][val].qty === 0) {
                delete cart[v]['options'][val]
            } else {
                if(cart[v]['options'][val].autoship || whprods.includes(val)) {
                    cartStatus = wholesale
                }
            }
        })
        if(count(cart[v]['options']) === 0) {
            delete cart[v]
        }
    })
    setShoppingCartPriceType(cartStatus)
    return cart
}



export function subscriptionActive(itemcode: string, selected: string): boolean
{
    const cart = getCart()
    if(typeof cart[itemcode] === "undefined")
        return false
    if(typeof cart[itemcode]['options'] === "undefined")
        return false
    if(typeof cart[itemcode]['options'][selected] === "undefined") {
        if(selected === '') {
            if(typeof cart[itemcode]['options'][itemcode] === "undefined") {
                return false
            } else {
                return cart[itemcode]['options'][itemcode]['autoship'] || false
            }
        } else {
            return false
        }
    }
    return cart[itemcode]['options'][selected]['autoship'] || false
}


export function setSupportCart(data: any)
{
    cache('cart.support', JSON.stringify(data))
}

export function getSupportCart()
{
    return JSON.parse(cacheGet('cart.support') || '{}')
}

export const itemsInCart = () => Object.keys(getCart()).length > 0

export const cartHasAutoship = (): boolean => {
    let hasAS = false
    if(!itemsInCart())
        return hasAS
    
    const items = getCart()
    Object.keys(items).map((v, k) => {
        const sku = v
        if(items[sku]['options'][sku]['autoship']) {
            hasAS = true
        }
    })
    return hasAS
}