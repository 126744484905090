import { useState } from "react"
import { useParams } from "react-router"
import { CircularProgress } from "@mui/material"
import { OrderDetailsService } from "../../services/order.service"

export default function OrderSuccessPage()
{
    let { distid, orderid } = useParams()
    const [ order, setOrder ] = useState<any>({
        details: '',
        ready: false,
        loading: false
    })

    if(!order.ready && orderid && distid) {
        if(!order.loading) {
            setOrder((arr: any) => ({...arr, loading: true}))
            OrderDetailsService(distid || '', orderid || '').then(r => {
                setOrder({ready: true, loading: false, details: r.data})
            })
        }
    }

    return (
        <div className="col-count-3 offset">
            <div className="start2 my-4 py-4">
                { order.ready? null : <div className="align-middle"><CircularProgress /></div> }
                { order.ready && order.details !== ''? <div dangerouslySetInnerHTML={{ __html: order.details || '' }}></div> : null }
            </div>
        </div>
    )
}