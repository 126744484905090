import { useState, ChangeEvent, useContext } from "react"
import { useNavigate } from "react-router"
import { CheckoutContext } from "../../providers/checkout"
import { getSiteCountry, getSiteEnroller, setSiteEnroller } from "../../helpers/site"
import { CircularProgress, Tooltip } from "@mui/material"
import { filterValidateAlphaNumeric, filterValidateEmail, filterValidateNumeric, isNumeric } from "../../helpers/util"
import { AlertGeneral } from "../../components/alert"
import { SiteServiceGetEnrollerId, SiteServiceGetRegions } from "../../services/site.service"
import { UserExistCheckService } from "../../services/user.service"
import { CheckOutWizard } from "../../components/shopping-cart/checkout-wizard"
import { TermsServiceGet } from "../../services/terms.service"
import { getCart } from "../../services/cart"
import { countryPhoneCodes } from "../../providers/form.provider"
import { SitetContext } from "../../providers/site.provider"
import InfoIcon from '@mui/icons-material/Info';
import Checkbox from "../../components/form/checkbox"
import FormGroup from "../../components/form/form-group"
import Input from "../../components/form/input"
import Select from "../../components/form/select"
import agreeTerms from '../../media/downloads/2211v7-US_PoliciesProcedures.pdf'
import agreeRewards from '../../media/downloads/rewards_plan2211v1.pdf'
import docTermsConditions  from '../../media/downloads/docs-partner-terms-conditions.pdf'

import _ from "lodash"
import { passwordFormatValid } from "../../helpers/account"

interface ITerms
{
    ready: boolean
    agreed: boolean
    terms: ITermsAvailable
    loading: boolean
}

interface ITermsAvailable
{
    mainPolicies: boolean
    partner: boolean
    policies: boolean
    rewards: boolean
    use: boolean
    bp?: boolean
}

interface IPageAccount {
    form: IPageAccountForm
}

export interface IPageAccountForm {
    firstName: string
    lastName: string
    businessName: string
    username: string
    password: string
    address1: string
    address2: string
    city: string
    state: string
    zipcode: string
    country: string
    phone: string
    email: string
    birthday: string
    enrollerID: string
    checkOpt: boolean
    privacyNotice: boolean
}

export default function PageAccount(props: any) {
    const { form, setForm, setFormReady, setFormStep } = useContext(CheckoutContext)
    const [ error, setError ] = useState<string>('')
    const [ errors, setErrors ] = useState<any> ({})
    const [ termsAgreed, setTermsAgreed ] = useState(false)
    const [ loadState, setLoadState ] = useState({loading: false, ready: false})
    const [state, setState] = useState<IPageAccount>({
        form: form?.account || {
            firstName: "",
            lastName: "",
            businessName: "",
            username: "",
            password: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            country: getSiteCountry().code,
            zipcode: "",
            phone: "",
            email: "",
            birthday: "",
            checkOpt: false,
            privacyNotice: false,
            enrollerID: getSiteEnroller(),
        }
    });
    const [ terms, setTerms ] = useState<ITerms>({
        ready: false,
        agreed: false,
        loading: false,
        terms: {
            mainPolicies: false,
            partner: false,
            policies: false,
            rewards: false,
            use: false
        }
    })

    const [ states, setStates ] = useState({states: {}, loading: false, ready: false})
    const [ country, setCountry ] = useState<string>(state.form.country)

    const getCountry = () => SiteServiceGetRegions(country).then(r => {
        setStates(arr => ({states: r.data.regions, loading: false, ready: true}))
    })

    if(!states.ready) {
        if(!states.loading) {
            setStates(arr => ({...arr, loading: true}))
            getCountry()
        }
    }

    const { countries } = useContext(SitetContext)
    const nav = useNavigate()
    const validateUserUnique = (v: string) => {
        UserExistCheckService(v).then((r: any) => {
            if(r.success){
                if(r.exists) {
                    const new_errors = errors
                    new_errors.username = "User is already existing";
                    setErrors(new_errors);
                } else {
                    setErrors({});
                }
            } else {

            }
        }).catch(e => console.log(e))
    }

    const onChangeValue = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>, f: string, transform?: any) => {
        if (error !== '')
            setError('')
        let form = state.form
        let val = e.target.value
        if (transform)
            val = transform(val, e)
        _.set(form, f, val)
        setState(p => ({ ...p, form: form }))
        if (isNumeric(val) && f === 'enrollerID') {
            setSiteEnroller(val)
        }
        setForm ? setForm(p => ({ ...p, account: form })) : null
        setFormReady ? setFormReady(false) : null
    }

    const onNext = (e: any) => {
        e.preventDefault()
        setFormStep ? setFormStep('account') : null
        setForm ? setForm(p => ({ ...p, account: state.form })) : null
        setFormReady ? setFormReady(false) : null
        const valid = {
            Email: filterValidateEmail(state.form.email),
            Password: passwordFormatValid(state.form.password)
        }
        if (Object.keys(valid).map((v, k) => valid[v as keyof typeof valid]).includes(false)) {
            let err = Object.keys(valid).map((v, k) => [v]).join(', ')
            setError(`Invalid fields: ${err}`)
        } else {
            if (_.isEmpty(errors)) {
                nav('/checkout/shipping')
            }
        }
    }
    // Checks if all terms have been agreed to
    const allTermsChecked = () => {
        let valid = true
        const termsList = Object.keys(terms.terms)
        const count = termsList.length
        if(count === 0) {
            valid = true
        } else {
            termsList.map((v, k) => {
                if(!terms.terms[v as keyof typeof terms.terms]) {
                    valid = false
                } 
            })
        }
        return valid
    }
    // Checkbox event for terms
    const checkTerm = (key: any, value: boolean) => {
        let formTerms = terms.terms
        formTerms[key as keyof typeof terms.terms] = value
        setTerms(arr => ({...arr, terms: formTerms }))
        setTermsAgreed(true)
    }
    // Check if there are extra terms required
    if(!terms.ready) {
        if(!terms.loading) {
            setTerms(arr => ({...arr, loading: true}))
            TermsServiceGet<ITermsAvailable>(Object.keys(getCart())).then(r => {
                let f = ({...terms})
                if(r.success)
                    f.terms = {...f.terms, ...r.data}
                f.ready = true
                f.loading = false
                setTerms(f)
            })
        }
    }

    if(termsAgreed) {
        setTerms(arr => ({...arr, agreed: allTermsChecked()}))
        setTermsAgreed(false)
    }

    if((getSiteEnroller() !== '' && !isNumeric(getSiteEnroller())) && (!isNumeric(state.form.enrollerID) && state.form.enrollerID !== '')) {
        if(!loadState.ready) {
            if(!loadState.loading) {
                setLoadState(arr => ({...arr, loading: true}))
                SiteServiceGetEnrollerId(state.form.enrollerID).then(r => {
                    if(r.success) {
                        setSiteEnroller(r.data)
                        let f = ({...state.form, enrollerID: r.data})
                        setState(arr => ({...arr, form: f}))
                        setForm? setForm(arr => ({...arr, account: f})) : null
                    }
                    setLoadState({ready: true, loading: false})
                })
            }
        }
    }

    const transformCountryCodes = () => countryPhoneCodes.map((v, k) => {
        return {
            label: `${v.label} (+${v.value})`,
            value: v.value
        }
    })

    return (
        <div className="main" id="accont-page-body">
            <div className="container">
                <CheckOutWizard
                    active="account"
                    summary={true}
                    account={true}
                    shipping={false}
                    payment={false}
                />
                <form action="" onSubmit={(e) => onNext(e)} className="mb-4 pb-4">
                    <FormGroup title="Account Information">
                        <Input
                            form={state.form}
                            required={true}
                            label="First Name"
                            field="firstName"
                            type="text"
                            onChange={e => onChangeValue(e, "firstName")}
                        />
                        <Input
                            required={true}
                            form={state.form}
                            label="Last Name"
                            field="lastName"
                            type="text"
                            onChange={e => onChangeValue(e, "lastName")}
                        />
                        <Input
                            required={false}
                            form={state.form}
                            label="Business Name"
                            field="businessName"
                            type="text"
                            onChange={e => onChangeValue(e, "businessName")}
                        />
                        <div className="input-field" />
                        <Input
                            form={state.form}
                            required={true}
                            label="Username (Alphanumeric)"
                            field="username"
                            type="text"
                            onChange={e => {
                                validateUserUnique(e.target.value);
                                onChangeValue(e, "username", (v: string) => filterValidateAlphaNumeric(v));
                            }}
                            error={errors?.username}
                        />
                        <Input
                            required={true}
                            label="Password (Min 8 Characters, 1 uppercase, 1 number)"
                            field="password"
                            type="password"
                            form={state.form}
                            onChange={e => onChangeValue(e, "password")}
                        />
                    </FormGroup>
                    <FormGroup title="Mailing Address">
                        <Input
                            required={true}
                            label="Address Line 1"
                            field="address1"
                            type="text"
                            form={state.form}
                            fullWidth={true}
                            onChange={e => onChangeValue(e, "address1")}
                        />
                        <Input
                            required={false}
                            label="Address Line 2"
                            field="address2"
                            type="text"
                            form={state.form}
                            fullWidth={true}
                            onChange={e => onChangeValue(e, "address2")}
                        />
                        <Input
                            required={true}
                            label="City"
                            field="city"
                            form={state.form}
                            type="text"
                            onChange={e => onChangeValue(e, "city")}
                        />
                        <Select
                            field="country"
                            label="Select Country"
                            required={true}
                            form={state.form}
                            options={countries.map((v, k) => {
                                return { value: v.code, label: v.name }
                            })}
                            onChange={e => {
                                onChangeValue(e, "country")
                                setCountry(e.target.value)
                                setStates(arr => ({...arr, ready: false, loading: false}))
                            }}
                        />
                        { states.ready? <Select
                            field="state"
                            label="Select State/Province"
                            required={false}
                            form={state.form}
                            options={Object.keys(states.states).map((v: string, k) => {
                                return { value: v, label: states.states[v as keyof typeof states.states] }
                            })}
                            onChange={e => onChangeValue(e, "state")}
                        /> : null }
                        <Input
                            required={true}
                            label="Post Code"
                            field="zipcode"
                            form={state.form}
                            type="text"
                            onChange={e => onChangeValue(e, "zipcode", (v: string) => filterValidateAlphaNumeric(v))}
                        />
                    </FormGroup>
                    <FormGroup title="Personal Information">
                        <Select
                            required={true}
                            label="Country Code"
                            field="countryCode"
                            form={state.form}
                            options={ transformCountryCodes() }
                            onChange={e => onChangeValue(e, "countryCode", (v: string) => filterValidateNumeric(v))}
                        />
                        <Input
                            required={true}
                            label="Phone Number"
                            field="phone"
                            form={state.form}
                            type="text"
                            onChange={e => onChangeValue(e, "phone", (v: string) => filterValidateNumeric(v))}
                            maxLength={25}
                        />
                        <Input
                            required={true}
                            label="Email"
                            field="email"
                            form={state.form}
                            type="text"
                            onChange={e => onChangeValue(e, "email")}
                        />
                        <Input
                            required={false}
                            label="Birth Date"
                            field="birthday"
                            form={state.form}
                            type="date"
                            onChange={ e => onChangeValue(e, "birthday") }
                        />
                        <Checkbox
                            label="Opt in to Vísi communications"
                            field={ "checkOpt" }
                            onChange={ e => onChangeValue(e, "checkOpt", (v: string, e: any) => e.target.checked) }
                        />
                    </FormGroup>
                    <FormGroup title="Your Vísi Partner">
                        <p>Vísi Partner <br />
                            <Tooltip title="This is the Visi Partner's ID who told you about the business.">
                                <span><InfoIcon fontSize="small" />Partner ID:</span>
                            </Tooltip>
                        </p>
                        {/* { (getSiteEnroller() !== state.form.enrollerID)?
                        <div style={{width: '100%'}}>
                            <button className="tag-button" onClick={(e) => {
                                e.preventDefault()
                                let form = state.form
                                form.enrollerID = getSiteEnroller()
                                setState? setState(arr => ({...arr, form: form })) : null
                                setForm ? setForm(p => ({ ...p, account: form })) : null
                            }} >Reset From Site</button>
                        </div> : null
                         } */}
                        <Input
                            required={true}
                            label="Placement"
                            field="enrollerID"
                            form={state.form}
                            type="text"
                            onChange={e => onChangeValue(e, "enrollerID", (v: any) => filterValidateNumeric(v))}
                        />
                    </FormGroup>
                    <FormGroup title="Privacy Notice">
                        <Checkbox
                            label="Our Privacy Notice explains what personal information we collect, process, store, disclose to third parties, and the associated purpose. To enroll, you must click to acknowledge that you have read and understand the Privacy Notice and you have read and agree to the Visi International Website Terms and Conditions."
                            field={"privacyNotice"}
                            required={true}
                            onChange={e => {
                                onChangeValue(e, "privacyNotice", (v: string, e: any) => e.target.checked)
                                checkTerm('mainPolicies', e.target.checked)
                            }}
                        />
                    </FormGroup>

                    <FormGroup title="My Contract">
                        <b className="mb-4">Agreements</b>
                        <div className="col-count-1 gapped pb-4 mb-2">
                            <div>
                                <Checkbox required={true} label={<a href={ docTermsConditions } target="_blank">Vísi Partner Agreement</a>} field="partner" onChange={(e) => checkTerm('partner', e.target.checked)} />
                            </div>
                            <div>
                                <Checkbox required={true} label={<a href={ agreeTerms } target="_blank">Policies &amp; Procedures</a>} field="policies" onChange={(e) => checkTerm('policies', e.target.checked)} />
                            </div>
                            <div>
                                <Checkbox required={true} label={<a href={ agreeRewards } target="_blank">Rewards Plan</a>} field="rewards" onChange={(e) => checkTerm('rewards', e.target.checked)} />
                            </div>
                            <div>
                                <Checkbox required={true} label={<a href={ agreeTerms } target="_blank">Terms of Use</a>} field="use" onChange={(e) => checkTerm('use', e.target.checked)} />
                            </div>
                            { (typeof terms.terms.bp !== "undefined")? 
                            <div>
                                <Checkbox required={true} label={<a href={ agreeTerms } target="_blank">10X Academy</a>} field="bp" onChange={ (e) => checkTerm('bp', e.target.checked) } />
                            </div> : null }
                        </div>
                        <p style={{ marginBottom: "0px" }}>
                            <b>If you sign this contract you have 30 days in which to get your money back. Digital products are excluded and are nonrefundable.</b><br />
                            By clicking "E-SIGN MY CONTRACT" below I certify I have read and agree to the terms of my contracts.
                        </p>
                    </FormGroup>

                    {isNumeric(state.form.enrollerID) ?
                        <div className="align-middle formfornewaccount">
                            { (error !== '') ? <AlertGeneral type="danger" content={error} /> : null }
                            { terms.ready?
                                <input disabled={
                                    error !== '' || !terms.ready || !terms.agreed
                                } type="submit" value="E-SIGN MY CONTRACT" name="continue" className="button" /> :
                                <CircularProgress /> }
                        </div> : null
                    }
                </form>
            </div>
        </div>
    )
}