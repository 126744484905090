export const countryPhoneCodes = [
    { label: 'Afghanistan', value: '93' },
    { label: 'Albania', value: '355' },
    { label: 'Algeria', value: '213' },
    { label: 'Andorra', value: '376' },
    { label: 'Angola', value: '244' },
    { label: 'Antigua and Barbuda', value: '1-268' },
    { label: 'Argentina', value: '54' },
    { label: 'Armenia', value: '374' },
    { label: 'Australia', value: '61' },
    { label: 'Austria', value: '43' },
    { label: 'Azerbaijan', value: '994' },
    { label: 'Bahamas', value: '1-242' },
    { label: 'Bahrain', value: '973' },
    { label: 'Bangladesh', value: '880' },
    { label: 'Barbados', value: '1-246' },
    { label: 'Belarus', value: '375' },
    { label: 'Belgium', value: '32' },
    { label: 'Belize', value: '501' },
    { label: 'Benin', value: '229' },
    { label: 'Bhutan', value: '975' },
    { label: 'Bolivia', value: '591' },
    { label: 'Bosnia and Herzegovina', value: '387' },
    { label: 'Botswana', value: '267' },
    { label: 'Brazil', value: '55' },
    { label: 'Brunei', value: '673' },
    { label: 'Bulgaria', value: '359' },
    { label: 'Burkina Faso', value: '226' },
    { label: 'Burundi', value: '257' },
    { label: 'Cabo Verde', value: '238' },
    { label: 'Cambodia', value: '855' },
    { label: 'Cameroon', value: '237' },
    { label: 'Canada / USA', value: '1' },
    { label: 'Central African Republic', value: '236' },
    { label: 'Chad', value: '235' },
    { label: 'Chile', value: '56' },
    { label: 'China', value: '86' },
    { label: 'Colombia', value: '57' },
    { label: 'Comoros', value: '269' },
    { label: 'Congo', value: '242' },
    { label: 'Costa Rica', value: '506' },
    { label: 'Croatia', value: '385' },
    { label: 'Cuba', value: '53' },
    { label: 'Cyprus', value: '357' },
    { label: 'Czech Republic', value: '420' },
    { label: 'Denmark', value: '45' },
    { label: 'Djibouti', value: '253' },
    { label: 'Dominica', value: '1-767' },
    { label: 'Dominican Republic', value: '1-809' },
    { label: 'Dominican Republic', value: '1-829' },
    { label: 'Dominican Republic', value: '1-849' },
    { label: 'East Timor', value: '670' },
    { label: 'Ecuador', value: '593' },
    { label: 'Egypt', value: '20' },
    { label: 'El Salvador', value: '503' },
    { label: 'Equatorial Guinea', value: '240' },
    { label: 'Eritrea', value: '291' },
    { label: 'Estonia', value: '372' },
    { label: 'Eswatini', value: '268' },
    { label: 'Ethiopia', value: '251' },
    { label: 'Fiji', value: '679' },
    { label: 'Finland', value: '358' },
    { label: 'France', value: '33' },
    { label: 'Gabon', value: '241' },
    { label: 'Gambia', value: '220' },
    { label: 'Georgia', value: '995' },
    { label: 'Germany', value: '49' },
    { label: 'Ghana', value: '233' },
    { label: 'Greece', value: '30' },
    { label: 'Grenada', value: '1-473' },
    { label: 'Guatemala', value: '502' },
    { label: 'Guinea', value: '224' },
    { label: 'Guinea-Bissau', value: '245' },
    { label: 'Guyana', value: '592' },
    { label: 'Haiti', value: '509' },
    { label: 'Honduras', value: '504' },
    { label: 'Hungary', value: '36' },
    { label: 'Iceland', value: '354' },
    { label: 'India', value: '91' },
    { label: 'Indonesia', value: '62' },
    { label: 'Iran', value: '98' },
    { label: 'Iraq', value: '964' },
    { label: 'Ireland', value: '353' },
    { label: 'Israel', value: '972' },
    { label: 'Italy', value: '39' },
    { label: 'Jamaica', value: '1-876' },
    { label: 'Japan', value: '81' },
    { label: 'Jordan', value: '962' },
    { label: 'Kazakhstan', value: '7' },
    { label: 'Kenya', value: '254' },
    { label: 'Kiribati', value: '686' },
    { label: 'Korea, North', value: '850' },
    { label: 'Korea, South', value: '82' },
    { label: 'Kosovo', value: '383' },
    { label: 'Kuwait', value: '965' },
    { label: 'Kyrgyzstan', value: '996' },
    { label: 'Laos', value: '856' },
    { label: 'Latvia', value: '371' },
    { label: 'Lebanon', value: '961' },
    { label: 'Lesotho', value: '266' },
    { label: 'Liberia', value: '231' },
    { label: 'Libya', value: '218' },
    { label: 'Liechtenstein', value: '423' },
    { label: 'Lithuania', value: '370' },
    { label: 'Luxembourg', value: '352' },
    { label: 'Madagascar', value: '261' },
    { label: 'Malawi', value: '265' },
    { label: 'Malaysia', value: '60' },
    { label: 'Maldives', value: '960' },
    { label: 'Mali', value: '223' },
    { label: 'Malta', value: '356' },
    { label: 'Marshall Islands', value: '692' },
    { label: 'Mauritania', value: '222' },
    { label: 'Mauritius', value: '230' },
    { label: 'Mexico', value: '52' },
    { label: 'Micronesia', value: '691' },
    { label: 'Moldova', value: '373' },
    { label: 'Monaco', value: '377' },
    { label: 'Mongolia', value: '976' },
    { label: 'Montenegro', value: '382' },
    { label: 'Morocco', value: '212' },
    { label: 'Mozambique', value: '258' },
    { label: 'Myanmar', value: '95' },
    { label: 'Namibia', value: '264' },
    { label: 'Nauru', value: '674' },
    { label: 'Nepal', value: '977' },
    { label: 'Netherlands', value: '31' },
    { label: 'New Zealand', value: '64' },
    { label: 'Nicaragua', value: '505' },
    { label: 'Niger', value: '227' },
    { label: 'Nigeria', value: '234' },
    { label: 'North Macedonia', value: '389' },
    { label: 'Norway', value: '47' },
    { label: 'Oman', value: '968' },
    { label: 'Pakistan', value: '92' },
    { label: 'Palau', value: '680' },
    { label: 'Palestine', value: '970' },
    { label: 'Panama', value: '507' },
    { label: 'Papua New Guinea', value: '675' },
    { label: 'Paraguay', value: '595' },
    { label: 'Peru', value: '51' },
    { label: 'Philippines', value: '63' },
    { label: 'Poland', value: '48' },
    { label: 'Portugal', value: '351' },
    { label: 'Qatar', value: '974' },
    { label: 'Romania', value: '40' },
    { label: 'Russia', value: '7' },
    { label: 'Rwanda', value: '250' },
    { label: 'Saint Kitts and Nevis', value: '1-869' },
    { label: 'Saint Lucia', value: '1-758' },
    { label: 'Saint Vincent and the Grenadines', value: '1-784' },
    { label: 'Samoa', value: '685' },
    { label: 'San Marino', value: '378' },
    { label: 'Sao Tome and Principe', value: '239' },
    { label: 'Saudi Arabia', value: '966' },
    { label: 'Senegal', value: '221' },
    { label: 'Serbia', value: '381' },
    { label: 'Seychelles', value: '248' },
    { label: 'Sierra Leone', value: '232' },
    { label: 'Singapore', value: '65' },
    { label: 'Slovakia', value: '421' },
    { label: 'Slovenia', value: '386' },
    { label: 'Solomon Islands', value: '677' },
    { label: 'Somalia', value: '252' },
    { label: 'South Africa', value: '27' },
    { label: 'South Sudan', value: '211' },
    { label: 'Spain', value: '34' },
    { label: 'Sri Lanka', value: '94' },
    { label: 'Sudan', value: '249' },
    { label: 'Suriname', value: '597' },
    { label: 'Sweden', value: '46' },
    { label: 'Switzerland', value: '41' },
    { label: 'Syria', value: '963' },
    { label: 'Taiwan', value: '886' },
    { label: 'Tajikistan', value: '992' },
    { label: 'Tanzania', value: '255' },
    { label: 'Thailand', value: '66' },
    { label: 'Togo', value: '228' },
    { label: 'Tonga', value: '676' },
    { label: 'Trinidad and Tobago', value: '1-868' },
    { label: 'Tunisia', value: '216' },
    { label: 'Turkey', value: '90' },
    { label: 'Turkmenistan', value: '993' },
    { label: 'Tuvalu', value: '688' },
    { label: 'Uganda', value: '256' },
    { label: 'Ukraine', value: '380' },
    { label: 'United Arab Emirates', value: '971' },
    { label: 'United Kingdom', value: '44' },
    { label: 'USA / Canada', value: '1' },
    { label: 'Uruguay', value: '598' },
    { label: 'Uzbekistan', value: '998' },
    { label: 'Vanuatu', value: '678' },
    { label: 'Vatican City', value: '379' },
    { label: 'Venezuela', value: '58' },
    { label: 'Vietnam', value: '84' },
    { label: 'Yemen', value: '967' },
    { label: 'Zambia', value: '260' },
    { label: 'Zimbabwe', value: '263' }
  ]  