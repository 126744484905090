import { useContext } from "react"
import { AlertGeneral } from "../../components/alert"
import { ResetPasswordProviderContext } from "../../providers/reset-password.provider"

export const PageLoginBlockAlerts = () => {
    const { msg, msgType } = useContext(ResetPasswordProviderContext)
    return (
        msg !== ''? <div className='col-count-1 gapped'>
            { msg? <AlertGeneral type={msgType} content={msg} /> : null }
        </div> : null
    )
}