import { memo, useContext, useState } from 'react'
import { NavLink } from "react-router-dom"
import { Button } from '@mui/material'
import { ShoppingCartEngineContext } from '../../../providers/shopping-cart-engine'
import { ShoppingCartContext } from '../../../providers/cart'
import { subscriptionActive } from '../../../services/cart'
import { ProductsGetBySkuService } from '../../../services/products.service'
import { IProduct } from '../../../interfaces/product.interface'

export const ButtonBuyCollagen = () => {
    return (
        <div className="align-middle">
            <NavLink to='/product/v6802cous' className='button corp'>SHOP COLLAGEN</NavLink>
        </div>
    )
}

interface IButtonAddToCart
{
    itemCode: string
    itemData?: IProduct
    qty?: number
    text?: string
    className?: string
    clickEvent?: any
}

export const ButtonAddToCart = memo(function ButtonAddToCart(props: IButtonAddToCart) {
    
    const [ ready, setReady ] = useState(false)
    const [ currentItem, setCurrentItem ] = useState<IProduct>({})
    const [ loading, setLoading ] = useState<boolean>()
    const {
        setItem,
        setItemcode,
        setQty,
        setSubscribe,
        setUpdateComp,
        setRefreshOrder,
        setOrderReady
    } = useContext(ShoppingCartEngineContext)

    const { reloadSite, setToggleUpdate, toggleSidebar } = useContext(ShoppingCartContext)

    const addToCart = (v: any, qty: number) => {
        setItem(v)
        setItemcode(v.ItemCode)
        setQty(qty)
        setSubscribe(subscriptionActive(v.ItemCode,''))
        setUpdateComp(true)
        setOrderReady(false)
        setToggleUpdate(true)
        toggleSidebar(true)
        reloadSite(true)
        setRefreshOrder(true)
    }

    if(!ready) {
        if(!loading) {
            setLoading(true)
            if(props?.itemData) {
                setCurrentItem(props.itemData)
                setReady(true)
                setLoading(false)
            } else {
                ProductsGetBySkuService(props.itemCode).then(r => {
                    if(r.success) {
                        setCurrentItem(r.data)
                    }
                    setReady(true)
                    setLoading(false)
                })
            }
        }
    }

    return (
        (ready && (currentItem?.ItemCode || false))? <Button variant="contained" className={ props.className || 'corp' } onClick={() => {
            addToCart(currentItem, props.qty || 1)
            if(props.clickEvent) {
                props.clickEvent()
            }
        } }>{ props.text || 'Add To Cart' }</Button> : null
    )
})