import { IAccount } from "../interfaces/account.interface"
import { IResponse } from "../interfaces/http.interface"
import HttpClient from "./http"

interface ISSOValidateLogin
{
    jwt: string
    account: IAccount
}

export const SSOValidateLogin = (token: string) => {
    return (new HttpClient()).post<IResponse<ISSOValidateLogin>>('sso', { token })
}