import { useContext, useEffect, useState } from "react"
import { Button, FormControlLabel, FormGroup, MenuItem, Select, TextField, Tooltip } from "@mui/material"
import { getItemQty, retail, subscriptionActive, updateCart, useSitePriceTypeStatus, wholesale } from "../../../services/cart"
import { VisiToggle } from "../../form"
import { IProduct } from "../../../interfaces/product.interface"
import { ShoppingCartContext } from "../../../providers/cart"
import { dollars } from "../../../helpers/currency"
import { getFirstOption, processProduct } from "../../../helpers/product"
import { ShoppingCartEngineContext } from "../../../providers/shopping-cart-engine"
import { useNavigate } from "react-router"
import { AccountContext } from "../../../providers/account"
import { cacheGet } from "../../../services/cache"
import InfoIcon from '@mui/icons-material/Info'
import '../../../media/visi_assets/css/productdetailsv.css'
import './styles/styles.scss'

const ShoppingCartProductBuy = (props: IProduct) =>
{
    const itemcode = props.ItemCode || ''
    const [ qty, setQty ] = useState<number>(0)
    const [ option, setOption ] = useState<string>(props.ItemCode || '')
    const [ updateComp, setUpdateComp ] = useState(false)
    const [ subscribe, setSubscribe ] = useState(subscriptionActive(itemcode, option))
    const { toggleSidebar, setToggleUpdate, reloadSite } = useContext(ShoppingCartContext)
    const { setRefreshOrder } = useContext(ShoppingCartEngineContext)
    const { account } = useContext(AccountContext)
    const { type } = useSitePriceTypeStatus()
    const nav = useNavigate()
    const shoppingCartPriceType = cacheGet('cart.setting.priceType') || type
    // useEffect(() => updateCartCount('cartcount'))
    // Runs the refresh for the page if the sidebar is updated with the itemcode qty
    useEffect(() => {
        const newQty = getItemQty(itemcode, option)
        setQty(newQty)
        setUpdateComp(true)
        // Resets the subscribe toggle if necessary
        setSubscribe(subscriptionActive(itemcode, option))
    }, [ getItemQty(itemcode, option) ])
    // ***This needs to be updated to push into the provider at some point***
    if(updateComp) {
        updateCart(processProduct(props, option, itemcode, qty, subscribe, shoppingCartPriceType))
        setUpdateComp(false)
    }
    // Sets the content for the global modal window
    // const modalAttrClass = new ModalAttrAddToCart(<ModalButtonsClose closeEvent={ () => setModal(false) } />)
    const diff = (props.PricesAll?.RE && props.PricesAll?.WH)? +props.PricesAll?.RE.Price - +props.PricesAll?.WH.Price : 0
    // This is required to make sure the toggle for AS is correctly set when AS is removed from item in sidebar
    if(subscriptionActive(itemcode, option) !== subscribe) {
        // If there is a product still in the cart
        if(getItemQty(itemcode, option) > 0) {
            // Make sure subscription matches the cache value
            setSubscribe(subscriptionActive(itemcode, option))
        }
    }
    const isWholesale = (shoppingCartPriceType === wholesale)
    const isSubscribable = [1,"1"].includes(typeof props.AllowOnAutoOrder !== "undefined"? props.AllowOnAutoOrder : 1)
    return (
        <div className="soothe soothe1 pad">
            <div className="container">
                <div className="pbar">
                    <div className="container">
                        <div className="col-count-3 col-c2-lg col-c1-md gapped">
                            <div className="span2 span1-lg">
                                <div className="d-flex justify-content-start">
                                    <ul className="pricedisplays col-count-4 gapped col-c2-lg col-c1-md">
                                        { (shoppingCartPriceType !== retail) && account?.customerID? 
                                        <li>
                                            <div className="pbox__meta v4015awusdnd">
                                                <strong>YOUR PRICE</strong>
                                                <h3>{ dollars(props.PricesAll.WH.Price) }</h3>
                                            </div>
                                        </li> : null }

                                        { !account?.customerID && props.PricesAll?.RE? 
                                        <li>
                                            <div className="pbox__meta v4015awusdnd">
                                                <strong>RETAIL PRICE</strong>
                                                <h3>{ dollars(props.PricesAll.RE.Price) }</h3>
                                            </div>
                                        </li> : null }
                                        { !account?.customerID?
                                        <li>
                                            <div className="pbox__meta">
                                                { (!subscribe && props.PricesAll?.RE && props.PricesAll?.WH)? 
                                                <div id="corePCText">
                                                    <strong>PC PRICE</strong>
                                                    <h3>{ dollars(props.PricesAll?.WH?.Price ) }</h3>
                                                </div> : null }
                                                { (subscribe && props.PricesAll?.RE && props.PricesAll?.WH)? 
                                                <div id="coreSSText">
                                                    <strong>S&amp;S PRICE <Tooltip title={<span>Calculated with Subscribe and Save discount. Receive your order every month!</span>}><InfoIcon fontSize="small" /></Tooltip></strong><h3>{ dollars(props.PricesAll?.WH.Price) }</h3>
                                                </div> : null }
                                            </div>
                                        </li> : null}
                                        { (!account?.customerID && props.PricesAll?.RE && props.PricesAll?.WH && (diff > 0))?
                                        <li>
                                            <div className="pbox__meta v4015awusdnd">
                                                <strong>YOU{subscribe? '\'LL' : ' COULD'} SAVE</strong>
                                                <h3>{ dollars(diff) }</h3>
                                            </div>
                                        </li> : null }

                                        { ['VP','PC'].includes(account?.customerTypeAbbrev || retail)?
                                        <li>
                                            <div className="pbox__meta">
                                                <strong>RV / CV</strong>
                                                <h3>{ props.CommissionableVolume }</h3>
                                            </div>
                                        </li> : null }
                                    </ul>
                                </div>
                            </div>
                            <div className="col-count-1 gapped">
                                <div className=" col-count-1 gapped">
                                    { (props.Options && props.Options.length > 0)? 
                                    <Select value={ option || getFirstOption(props)?.ItemCode } onChange={(e: any) => {
                                        setOption(e.target.value)
                                        setSubscribe(subscriptionActive(e.target.value, e.target.value))
                                        nav(`/product/${e.target.value}`)
                                    }}>
                                        <MenuItem value='none' selected={ option === 'none' }>Select Option</MenuItem>
                                        { props.Options? props.Options.map((v: any, k: any) => {
                                            return <MenuItem key={ k } value={ v.ItemCode }>{ v.Title }</MenuItem>
                                        }) : null }
                                    </Select> : null }
                                    <TextField value={qty} type="number" label="QTY" variant="outlined" onChange={(e) => {
                                        let v = parseInt(e.target.value)
                                        if((props.ShortDescription2?.cartLock || []).includes('qty')) {
                                            setQty(1)
                                        } else {
                                            setQty((v < 0 || !v)? 0 : v)
                                        }
                                    }} />
                                </div>
                                <div className="align-middle">
                                    <Button className="corp" variant="contained" onClick={() => {
                                        setUpdateComp(true)
                                        setToggleUpdate(true)
                                        toggleSidebar(true)
                                        reloadSite(true)
                                        setRefreshOrder(true)
                                    }}>{getItemQty(itemcode, option) > 0? 'Update' : 'Add to' } Cart</Button>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start subscribe-and-save-container">
                            { isSubscribable?
                            <FormGroup>
                                <FormControlLabel
                                    labelPlacement="start"
                                    control={ <VisiToggle checked={ subscribe } onChange={(e) => {
                                        if(getItemQty(itemcode, option) > 0) {
                                            updateCart(processProduct(props, option, itemcode, qty, e.target.checked, shoppingCartPriceType))
                                            toggleSidebar(true)
                                            reloadSite(true)
                                        }
                                        setSubscribe(e.target.checked)
                                    }} />}
                                    label="Subscribe and Save"
                                />
                            </FormGroup> : null }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShoppingCartProductBuy