import { ICountry } from "../interfaces/country.interface"
import { IResponse } from "../interfaces/http.interface"
import HttpClient from "./http"

export function SiteServiceGetCountries(): Promise<IResponse<ICountry[]>>
{
    return (new HttpClient()).get<IResponse<ICountry[]>>(`site/countries`)
}

export function SiteServiceGetEnrollerId(uname: string): Promise<IResponse<string>>
{
    return (new HttpClient()).get<IResponse<string>>(`customer/enroller-from-id/${uname}`)
}

export function SiteServiceGetRegions(country: string): Promise<IResponse<any>>
{
    return (new HttpClient()).get<IResponse<any>>(`account/regions/${country}`)
}