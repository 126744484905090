import { memo } from "react"
import { ButtonAddToCart } from "../../shopping-cart/button"
import { deleteFromCart, getItemQty, retail } from "../../../services/cart"
import { useNavigate } from "react-router"
import { environment } from "../../../environmentals/config"
import { cartCalculate, currentPriceType, hasQualifyingWHInCart } from "../../../helpers/cart"

interface IHeroJoinNowComponent
{
    contentOnPCTrue?: any
    contentOnVPTrue?: any
    contentOnEitherTrue?: any
    contentOnEitherFalse?: any
}

export const HeroJoinNowComponent = memo(function HeroJoinNowComponent(props?: IHeroJoinNowComponent) {
    const sitePriceType = currentPriceType()
    const orderTotal = ():number => {
        const totals = cartCalculate(sitePriceType)
        if(!totals)
            return 0
        return totals.subTotal
    }
    const threshold = orderTotal()
    // Wholesale and under the VP threshold
    const autoPC = (sitePriceType !== retail && threshold < environment.vpThreshold)
    // Wholesale with threshold
    const autoVP = (sitePriceType !== retail && threshold >= environment.vpThreshold)
    // Checks if use has added in an enrollment sku
    const hasPurchaseUpgrades = hasQualifyingWHInCart()
    const navigation = useNavigate()
    const checkoutPath = environment.checkoutPath

    const hasBoth = autoPC || autoVP || hasPurchaseUpgrades || getItemQty('bevp', '')

    return (
        <>
        { props?.contentOnEitherFalse && !hasBoth? props?.contentOnEitherFalse : null }
        { props?.contentOnEitherTrue && hasBoth? props?.contentOnEitherTrue : null }
        <div className="col-count-2 gapped col-c1-md mt-4 mb-4">
            <div className={`joinpanel ${ autoPC || autoVP || hasPurchaseUpgrades? 'disabled' : '' }`}>
                <div className="joinpcontent">
                    <h2 className="mb-4 jnhding">preferred customer</h2>
                    <p><b>Savings of 20% or more on all orders.</b></p>
                    <p><b>Enjoy discounts on your favorite products and more when you shop!<br />&nbsp;<br />&nbsp;</b></p>
                    <p className="mt-4"><span className="joinprice">FREE!</span><br /><span className="joina">Annual</span></p>
                    <ButtonAddToCart clickEvent={() => {
                        deleteFromCart('bevp', '', null)
                        navigation(checkoutPath)
                    } } className="corp brown" itemCode="bepc" qty={1} />
                    <p className="mt-4">Set up Subscribe &amp; Save to get your fee waived.<br />&nbsp;</p>
                </div>
            </div>
            <div className={`joinpanel ${ autoVP || getItemQty('bevp', '')? 'disabled' : '' }`}>
                <div className="joinpcontent">
                    <h2 className="mb-4 jnhding">partner pricing</h2>
                    <p><b>Savings of 20% or more on all orders.  Start earning on your own terms!</b></p>
                    <p><b>Share and sell your favorite health and digital services, earn commissions, get product discounts, bonuses and exclusive offers.</b></p>
                    <p className="mt-4"><span className="joinprice">FREE!</span><br /><span className="joina">Annual</span></p>
                    <ButtonAddToCart clickEvent={() => {
                        deleteFromCart('bepc', '', null)
                        navigation(checkoutPath)
                    } } className="corp brown" itemCode="bevp" qty={1} />
                    <p className="mt-4">Place an initial order and set up a Subscribe &amp; Save of 75 RV (approximately $100) to get your fee waived.</p>
                </div>
            </div>
        </div>
        </>
    )
})