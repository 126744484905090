import { memo } from "react";
import HeroMultiCurrencyWallet from "../../../components/hero/multicurrency-wallet";
import HeroSecurityAndPieceOfMind from "../../../components/hero/security-and-piece-of-mind";
import HeroSwapCrypto from "../../../components/hero/swap-crypto";
import HeroUseYourWallet from "../../../components/hero/use-your-card";
import HeroSetUpAccount from "../../../components/hero/set-up-account";
import HeroIntroducingSavvyWallet from "../../../components/hero/introducing-savvywallet";

const PageSavvyWalletHowItWorks = memo(function PageSavvyWalletHowItWorks(props: any) {
    return (
        <>
        <div className="main mt-4">
            <HeroMultiCurrencyWallet />
            <HeroSecurityAndPieceOfMind />
            <HeroSwapCrypto />
            <HeroUseYourWallet />
            <HeroSetUpAccount />
            <HeroIntroducingSavvyWallet />
        </div>      
        </>
    )
})

export default PageSavvyWalletHowItWorks