import { memo } from "react";
import inst from './images/footer/icn_instagram.png'
import fb from './images/footer/icn_facebook.png'
import tt from './images/footer/icn_tiktok.png'
import logo from './images/visi_logo.png'
import docReturnPolicy from '../../media/downloads/docs-return-policy.pdf'
import docTermsConditions  from '../../media/downloads/docs-partner-terms-conditions.pdf'
import { environment } from "../../environmentals/config";

const Footer = memo(function Footer(props?: any) {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-3">
                        <div className="footer__col">
                            <div className="footer__logo"><a href="/"><img src={ logo } alt="" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="footer__col">
                            <div className="footer__menu">
                                <ul>
                                    <li><a href="/products/all">Shop All Products</a></li>
                                    <li><a href="/about">About Us</a></li>
                                    <li><a href="/members">Social Media Course</a></li>
                                    <li><a href="/success-stories">Testimonials</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="footer__col">
                            <div className="footer__menu">
                                <ul>
                                    <li><a href="mailto:support@iamvisi.com">Contact Us</a></li>
                                    <li><a href="/login">Login</a></li>
                                    <li><a href="/membership/join">Join Now</a></li>
                                    <li><a href={ docTermsConditions } target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a></li>
                                    <li><a href={environment.policy} target="_blank" rel="noopener noreferrer">{ environment.policyName }</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="footer__col">
                            <div className="footer__soc">
                                <ul className="navmenu ftrsocial">
                                    <li><a href="#"><img src={inst} alt="" /></a></li>
                                    <li><a href="#"><img src={fb} alt="" /></a></li>
                                    <li><a href="#"><img src={tt} alt="" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
})

export default Footer