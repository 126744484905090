import { memo } from "react";

const HeroSecurityAndPieceOfMind = memo(function HeroSecurityAndPieceOfMind(props?: any) {
    return (
        <div className="wallet wallet1">
            <div className="container container--sm">
                <h2 className="title">security and peace of mind</h2>
                <p>Savvy powered wallet gives members access to a digital bank account for the in the decentralized world. </p>
                <p>Our global banking   partner Evolve Bank &amp; Trust is crypto-friendly.  Allowing the platform to with the ability to quickly and easily exchange crypto to fiat, fiat to crypto, and even crypto to crypto. They can even perform inbound and outbound wires and peer-to-peer transfers.</p>
                <p><strong>We are bridging the gap between crypto and fiat currencies.</strong></p>
            </div>
        </div>
    )
})

export default HeroSecurityAndPieceOfMind