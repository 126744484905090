import React, { ChangeEvent } from "react";
import { useClasses } from "../../hooks/useClasses";

interface IOption {
  label: string;
  value: string;
}

interface SelectProps {
  label: string;
  required: boolean;
  field: string;
  form: any;
  options: IOption[];
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
}

const Select = ({ label, required, field, form, options, onChange }: SelectProps) => {
  const style = useClasses(['select-field', {required: required}])
  return (
    <div className={style}>
      <label htmlFor={field}>
        {label}
      </label>
      <select id={field} onChange={onChange} value={form[field]}>
        <option value="" disabled>
          Select {label}
        </option>
        {options.map((el, k) => (
          <option key={k} value={el.value}>
            {el.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;