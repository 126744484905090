import { memo } from "react";
import basic from './images/basic.png'
import feature from './images/feature.png'
import standard from './images/standard.png'
import premier from './images/premier.png'
import img from './images/1.png'

const PageSavvyWalletCompare = memo(function PageSavvyWalletCompare(props: any) {
    return (
        <>
        <div className="banner3">
            <div className="container">
                <h1 className="title">wallet levels</h1>
                <p>Each digital membership offers its own unique set of benefits. Review the features below to find out which one is right for you.</p>
            </div>
        </div>

        <div className="lvl lvl1">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-md-6">
                        <img src={img} alt="" />
                    </div>
                    <div className="col-md-6">
                        <div className="lvl1__text">
                            <h2>premier</h2>
                            <ul className="ulbar">
                                <li>24k Gold Plated Debit Card </li>
                                <li>Visa approved spending</li>
                                <li>Free Crypto Wallet Activation</li>
                                <li>190+ Cryptocurrencies Available</li>
                                <li>Lowest Industry Crypto/Fiat Conversion Fees</li>
                                <li>Free Peer-to-Peer transfers</li>
                                <li>Unlimited BTC Daily Transfers</li>
                                <li>$30,000 Daily Cash Advance USD</li>
                            </ul>						
                            <span className="pbutton">
                                <button type="button" name="add" className="button" id="add-to-cart-btn" data-label="Add to Cart" onClick={() => {
                                    // add to cart v7022gwdg
                                }}><span className="text">BUY NOW</span></button>
                            </span>
                            <br /><br />							
                        </div>
                    </div>
                </div>
            </div>
        </div>

		<div className="lvl lvl2 pad--lg">
            <div className="container">
                <div className="tblimg desk">
                    <div><h2>compare levels:</h2></div>
                    <div className="tblimg__item"><img src={basic} alt="" /><h3>Basic</h3></div>
                    <div className="tblimg__item"><img src={standard} alt="" /><h3>Standard</h3></div>
                    <div className="tblimg__item"><img src={feature} alt="" /><h3>Feature</h3></div>
                    <div className="tblimg__item"><img src={premier} alt="" /><h3>Premier</h3></div>
                </div>
                <div className="tbl2 tbl2-wallet desk">
                    <div className="tbl2__title">
                        <h3>WALLET FEATURES</h3>
                    </div>
                    <div className="tbl2__row">
                        <div className="tbl2__col">Available in 160 Countries Globally</div>
                        <div className="tbl2__col">Yes</div>
                        <div className="tbl2__col">Yes</div>
                        <div className="tbl2__col">Yes</div>
                        <div className="tbl2__col">Yes</div>
                    </div>
                    <div className="tbl2__row">
                        <div className="tbl2__col">Crypto Conversion (Swap) Access</div>
                        <div className="tbl2__col">Yes</div>
                        <div className="tbl2__col">Yes</div>
                        <div className="tbl2__col">Yes</div>
                        <div className="tbl2__col">Yes</div>
                    </div>
                    <div className="tbl2__row">
                        <div className="tbl2__col">Internal Peer-to-Peer Transfers (2)</div>
                        <div className="tbl2__col">Free</div>
                        <div className="tbl2__col">Free</div>
                        <div className="tbl2__col">Free</div>
                        <div className="tbl2__col">Free</div>
                    </div>
                    <div className="tbl2__row">
                        <div className="tbl2__col">Peer-to-Peer Daily Transfer Limit USD</div>
                        <div className="tbl2__col">$2,500</div>
                        <div className="tbl2__col">$5,000</div>
                        <div className="tbl2__col">$10,000</div>
                        <div className="tbl2__col">$20,000</div>
                    </div>
                    <div className="tbl2__row">
                        <div className="tbl2__col">Peer-to-Peer Daily Transfer Limit BTC</div>
                        <div className="tbl2__col">Unlimited</div>
                        <div className="tbl2__col">Unlimited</div>
                        <div className="tbl2__col">Unlimited</div>
                        <div className="tbl2__col">Unlimited</div>
                    </div>
                    <div className="tbl2__row">
                        <div className="tbl2__col">Conversion Fee *</div>
                        <div className="tbl2__col">0.34%</div>
                        <div className="tbl2__col">0.20%</div>
                        <div className="tbl2__col">0.18%</div>
                        <div className="tbl2__col">0.16%</div>
                    </div>
                    <div className="tbl2__row">
                        <div className="tbl2__col">Cryptocurrencies Available </div>
                        <div className="tbl2__col">190+</div>
                        <div className="tbl2__col">190+</div>
                        <div className="tbl2__col">190+</div>
                        <div className="tbl2__col">190+</div>
                    </div>
                    <div className="tbl2__note">*Network fee not included with conversion fee</div>
                </div>

                <div className="tbl2 tbl2-card desk">
                    <div className="tbl2__title">
                        <h3>CARD FEATURES</h3>
                    </div>
                    <div className="tbl2__row">
                        <div className="tbl2__col">Savvy Debit Card Load Fee</div>
                        <div className="tbl2__col">-</div>
                        <div className="tbl2__col">$3.95 or 1.5%**</div>
                        <div className="tbl2__col">$3.95 or 1.25%**&lt;</div>
                        <div className="tbl2__col">$3.95 or 1%**&lt;</div>
                    </div>
                    <div className="tbl2__row">
                        <div className="tbl2__col">Virtual Card Load Fee</div>
                        <div className="tbl2__col">$3.95 or 1.5%**</div>
                        <div className="tbl2__col">$3.95 or 1.5%**</div>
                        <div className="tbl2__col">$3.95 or 1.25%**</div>
                        <div className="tbl2__col">$3.95 or 1%**</div>
                    </div>
                    <div className="tbl2__row">
                        <div className="tbl2__col">Daily Load Limit</div>
                        <div className="tbl2__col">-</div>
                        <div className="tbl2__col">$10,000</div>
                        <div className="tbl2__col">$25,000</div>
                        <div className="tbl2__col">$30,000</div>
                    </div>
                    <div className="tbl2__row">
                        <div className="tbl2__col">Maximum Card Load Balance </div>
                        <div className="tbl2__col">-</div>
                        <div className="tbl2__col">$10,000</div>
                        <div className="tbl2__col">$25,000</div>
                        <div className="tbl2__col">$30,000</div>
                    </div>
                    <div className="tbl2__row">
                        <div className="tbl2__col">POS Daily Limit </div>
                        <div className="tbl2__col">-</div>
                        <div className="tbl2__col">$10,000</div>
                        <div className="tbl2__col">$25,000</div>
                        <div className="tbl2__col">$30,000</div>
                    </div>
                    <div className="tbl2__row">
                        <div className="tbl2__col">POS Transaction Fee (per transaction)</div>
                        <div className="tbl2__col">-</div>
                        <div className="tbl2__col">$0</div>
                        <div className="tbl2__col">$0</div>
                        <div className="tbl2__col">$0</div>
                    </div>
                    <div className="tbl2__row">
                        <div className="tbl2__col">ATM Cash Withdrawal Limit<br /> (up to 3 transactions per day)</div>
                        <div className="tbl2__col">-</div>
                        <div className="tbl2__col">$10,000 (daily)</div>
                        <div className="tbl2__col">$10,000 (daily)</div>
                        <div className="tbl2__col">$10,000 (daily)</div>
                    </div>
                    <div className="tbl2__row">
                        <div className="tbl2__col">ATM  Withdrawal Fee USA</div>
                        <div className="tbl2__col">-</div>
                        <div className="tbl2__col">$2.50</div>
                        <div className="tbl2__col">$2.50</div>
                        <div className="tbl2__col">$2.50</div>
                    </div>
                    <div className="tbl2__row">
                        <div className="tbl2__col">ATM  Withdrawal Fee International </div>
                        <div className="tbl2__col">-</div>
                        <div className="tbl2__col">$5.00</div>
                        <div className="tbl2__col">$5.00</div>
                        <div className="tbl2__col">$5.00</div>
                    </div>
                    <div className="tbl2__row">
                        <div className="tbl2__col">ATM FX Fee</div>
                        <div className="tbl2__col">-</div>
                        <div className="tbl2__col">2.50%</div>
                        <div className="tbl2__col">2.50%</div>
                        <div className="tbl2__col">2.50%</div>
                    </div>
                    <div className="tbl2__row">
                        <div className="tbl2__col">Bank Verification (one-time)</div>
                        <div className="tbl2__col">$3.00</div>
                        <div className="tbl2__col">$3.00</div>
                        <div className="tbl2__col">$3.00</div>
                        <div className="tbl2__col">$3.00</div>
                    </div>
                    <div className="tbl2__row">
                        <div className="tbl2__col">Direct to Bank Payments (ACH in/out) </div>
                        <div className="tbl2__col">$4.95</div>
                        <div className="tbl2__col">$4.95</div>
                        <div className="tbl2__col">$4.95</div>
                        <div className="tbl2__col">$4.95</div>
                    </div>
                    <div className="tbl2__row">
                        <div className="tbl2__col">Wire Transfers </div>
                        <div className="tbl2__col">$30.00</div>
                        <div className="tbl2__col">$30.00</div>
                        <div className="tbl2__col">$30.00</div>
                        <div className="tbl2__col">$30.00</div>
                    </div>
                    <div className="tbl2__row">
                        <div className="tbl2__col">Close Account/Unload Funds</div>
                        <div className="tbl2__col">-</div>
                        <div className="tbl2__col">$8.95</div>
                        <div className="tbl2__col">$8.95</div>
                        <div className="tbl2__col">$8.95</div>
                    </div>
                    <div className="tbl2__row">
                        <div className="tbl2__col">Inactivity Free (charged after 3 months) </div>
                        <div className="tbl2__col">$4.95</div>
                        <div className="tbl2__col">$4.95</div>
                        <div className="tbl2__col">$4.95</div>
                        <div className="tbl2__col">$4.95</div>
                    </div>
                    <div className="tbl2__row">
                        <div className="tbl2__col">ATM Balance Check (per inquiry)</div>
                        <div className="tbl2__col">-</div>
                        <div className="tbl2__col">$0.95</div>
                        <div className="tbl2__col">$0.95</div>
                        <div className="tbl2__col">$0.95</div>
                    </div>
                    <div className="tbl2__row">
                        <div className="tbl2__col">Card Replacement Free<br /> (lost or damaged)</div>
                        <div className="tbl2__col">-</div>
                        <div className="tbl2__col">$3.95</div>
                        <div className="tbl2__col">$3.95</div>
                        <div className="tbl2__col">$3.95</div>
                    </div>

                    <div className="tbl2__note">**whichever is greater</div>

                    <div className="btn-center">
                        <a href="/memberships" className="button">SHOP WALLETS</a>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
})

export default PageSavvyWalletCompare