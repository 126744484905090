import { ReactNode, createContext, useState } from "react"
import { getCountries, storeCountries } from "../helpers/site"
import { SiteServiceGetCountries } from "../services/site.service"
import { ICountry } from "../interfaces/country.interface"

interface ISiteContext
{
    countries: ICountry[]
    countriesReady: boolean
}

export const SitetContext = createContext<ISiteContext>({ countries: [], countriesReady: false })

type ISiteContextChildren = {
    children: ReactNode
}

export const SiteProvider = ({ children }: ISiteContextChildren) => {
    const [ countries, setCountries ] = useState<ICountry[]>([])
    const [ countriesReady, setCountriesReady ] = useState(false)

    if(!countriesReady) {
        const c = getCountries()
        if(!c) {
            SiteServiceGetCountries().then(r => {
                if(r.success) {
                    storeCountries(r.data)
                    setCountries(r.data)
                    setCountriesReady(true)
                }
            })
        } else {
            setCountries(c)
            setCountriesReady(true)
        }
    }

    return (
        <SitetContext.Provider value={{countries, countriesReady}}>
            { children }
        </SitetContext.Provider>
    )
}