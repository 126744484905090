import HttpClient from "./http"
import { ILoginResponseExtended, LoginResponse } from '../interfaces/account.login.interface'
import { IResponseCount } from '../interfaces/http.interface'


export default function AuthService(username: string, password: string)
{
    return (new HttpClient).post<ILoginResponseExtended>('login', {username, password})
}

export function ResetPasswordService(username: string)
{
    return (new HttpClient).post<any>('reset-password', { username })
}