import React, { ReactNode } from "react";

interface FormGroupProps {
    title: string,
    children: ReactNode,
    className?: string
}

const FormGroup = ({title, children, className}: FormGroupProps) => {
    return (
        <div className="form-group">
            <h3 className="form-group__title">{title}</h3>
            <div className={ className || "form-group__content" }>
                {children}
            </div>
        </div>
    )
}

export default FormGroup;