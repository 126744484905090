import { useContext } from "react"
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { deleteFromCart, getCart, getSubscriptionCart, getUnitCount, retail, updateCartCount, updateSubscription } from "../../../services/cart"
import { IItemRow } from "../../../interfaces/shopping-cart-product.interface"
import { dollars } from "../../../helpers/currency"
import { ShoppingCartContext } from "../../../providers/cart"
import { dsUrl } from "../../../helpers/image"
import './styles.scss'
import { cartCalculate, getShoppingCartPriceType } from "../../../helpers/cart";
import { AccountContext } from "../../../providers/account";
import { ShoppingCartEngineContext } from "../../../providers/shopping-cart-engine";

export default function ShoppingCartSidebar(props: any)
{
    const { toggleSidebar, toggleSidebarStatus, reloadSite, toggleUpdate } = useContext(ShoppingCartContext)
    const { setRefreshOrder, setOrderReady } = useContext(ShoppingCartEngineContext)

    let orderSummary = cartCalculate()
    let cart = getCart()
    const units = getUnitCount()
    const cartCount: number = Object.keys(cart).length
    const showSideBarCart = (toggle: boolean) => {
        toggleSidebar(toggle)
    }

    // if(toggleUpdate) {
    //     updateCartCount('cartcount')
    // }

    return (
        <div id="cart-sidebar" className={ toggleSidebarStatus? `` : `disabled` }>
            <div className="cart-sidebar-header col-count-2">
                <h4 className="uppercase p-3 m-0">My Cart</h4>
                <a href="#" className="m-3 text-black" onClick={() => showSideBarCart(false)}><CloseIcon /></a>
            </div>
            <div className={`cart-sidebar-content p-3 ${cartCount > 0? `` : `align-middle`}`}>
                { (cartCount > 0)?
                <table className="cart-sidebar-small-thumb">
                    <tbody>{
                        Object.keys(cart).map((v, k) => {
                            return Object.keys(cart[v]['options']).map((val, key) => {
                                return (
                                    <ItemRow key={`${v}${key}`}
                                        itemcode={ v }
                                        selected={ val || '' }
                                        title={ cart[v]['options'][val].title }
                                        qty={ cart[v]['options'][val].qty }
                                        cv={ cart[v]['options'][val].cv }
                                        price={ cart[v]['options'][val].tiers }
                                        priceSubscribe={ cart[v]['options'][val].tiersSubscribe }
                                        as={ cart[v]['options'][val].autoship }
                                        image={ cart[v]['options'][val].image }
                                        onEvent={() => reloadSite(true) }
                                    />
                                )
                            })
                        }) }</tbody>
                </table> : 
                <div className="alert alert-info">Nothing Cart</div>
                }
            </div>
                { cartCount?
            <div className="cart-sidebar-footer p-3">
                <div className="cart-sidebar-subtotal col-count-2">
                    <div className="wg-exclude"><span className="wg-translate">Subtotal</span> ({units} <span className="wg-translate">item{units !== 1? `s` : ``}</span>)</div>
                    <div>{ dollars(orderSummary?.subTotal || 0) }</div>
                    <div>Total CV</div>
                    <div className="text-right txt-right">{ orderSummary?.totalCV }</div>
                </div>
                <div className="cart-sidebar-checkout p-3 align-middle">
                    <NavLink to='/checkout' onClick={() => {
                        setRefreshOrder(true)
                        toggleSidebar(false)
                        setOrderReady(false)
                    } }><Button variant="contained" className="corp uppercase">View Cart</Button></NavLink>
                </div>
            </div>
                : null }
        </div>
    )
}

function ItemRow(props: IItemRow)
{
    const { toggleSidebar, reloadSite } = useContext(ShoppingCartContext)
    // Determines if the user is getting warehouse pricing
    const { setRefreshOrder, setOrderReady } = useContext(ShoppingCartEngineContext)
    // If AutoShip toggled, return that price
    const pricing = () => (getShoppingCartPriceType() !== retail)? +props.priceSubscribe : +props.price
    return (
        <>
            <tr className="item-title-content">
                <td colSpan={4} className="item-title-title">
                    <h5 className="m-0">{ props.title }</h5>
                    { props.as? <p className="m-0">Subscribe &amp; Save&nbsp;<Button onClick={() => {
                        updateSubscription(props.itemcode, props.selected || '', false)
                        props.onEvent()
                        // Need to refresh the original order summary
                        setRefreshOrder(true)
                        setOrderReady(false)
                    }}><i className="far fa-trash-alt"></i></Button></p> : null }
                </td>
            </tr>
            <tr className="item-main-content">
                <td className="item-main-image wg-exclude"><a href={`/product/${ props.itemcode }`}><img src={ props.image } alt={`product image of ${ props.title }`} /></a></td>
                <td className="item-main-qty wg-exclude">&times;&nbsp;{ props.qty }&nbsp;QTY</td>
                <td className="item-main-price wg-exclude" >{ dollars(pricing() * +(props.qty? props.qty : 1)) } ({ (props.cv || 0) * +(props.qty? props.qty : 1) }CV)</td>
                <td className="item-main-buttons"><Button onClick={() => {
                    // Remove from cart
                    deleteFromCart(props.itemcode, props.selected || '', null)
                    // This will tell parent to reload
                    props.onEvent()
                    // Need to refresh the original order summary
                    setRefreshOrder(true)
                    setOrderReady(false)
                }}><i className="far fa-trash-alt"></i></Button></td>
            </tr>
        </>
    )
}