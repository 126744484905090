import ReportProblemIcon from '@mui/icons-material/ReportProblem'

export interface IAlertGeneral
{
    type: string
    content: any
    className?: string
}

export const AlertGeneral = (props: IAlertGeneral) => {
    return (
        <div className={`alert alert-${ props.type } ${props.className || ''}`}>
            { props.content }
        </div>
    )
}

export const AlertExternalUse = () => {
    return (
        <AlertGeneral content={ <><ReportProblemIcon /> Not for internal use. If pregnant, nursing, taking medication, or have a medical condition, consult your physician before use. Avoid eyes, mucous membranes, and sensitive skin. Not intended for use on infants and young children. Keep out of reach of children.</> } type="info" />
    )
}