import { memo } from "react"
import img from './images/3.png'
import './styles/styles.scss'

const HeroSwapCrypto = memo(function HeroSwapCrypto(props?: any) {
    return (
    <div className="wallet wallet2 pad--lg">
        <div className="container container--sm">
            <h2 className="title title--center">swap crypto the savvy way</h2>
            <div className="whitebox">
                <div className="row align-items-center">
                    <div className="col-md-4">
                        <div className="whitebox__text">
                            <h5>FIAT TO CRYPTO</h5>
                            <p>Want to convert your local currency into crypto and take advantage of exchange rate fluctuations? With the click of a few buttons, you can convert your fiat into crypto and hold, or put it to use!</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="whitebox__img">
                            <img src={img} alt="" />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="whitebox__text">
                            <h5>CRYPTO TO FIAT</h5>
                            <p>You don’t need to worry about whether a store, retail location or shop accepts crypto for payment, as you can easily convert your crypto to fiat (local currency) and utilize it to purchase goods and services.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
})

export default HeroSwapCrypto