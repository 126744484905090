import { IResponse } from "../interfaces/http.interface"
import { getCart } from "./cart"
import HttpClient from "./http"

export type IPromoItems = {
    [key: string]: IPromotionsService
}

export interface IPromotionsService
{
    notifyInCart: boolean
    totalAfterDiscount: number
    totalDiscount: number
    unitAfterDiscount: number
    unitDiscount: number
}

export function PromotionsService() {
    return (new HttpClient).post<IResponse<IPromoItems>>(`promotions`, getCart())
}