import { ReactNode, createContext, useState } from 'react'
import { IModal } from '../router'
import { CircularProgress } from '@mui/material'
import { setProductFilterWH } from '../helpers/site'

export interface IShoppingCartContext
{
    toggleSidebar?: any
    toggleSidebarStatus?: boolean
    setToggleUpdate?: any
    toggleUpdate?: boolean
    reloadSite?: any
    reloaded?: boolean
    setModal?: any
    modal?: boolean
    setModalAttr?: IModal | any
    modalAttr?: any
}
// Create a new context
export const ShoppingCartContext = createContext<IShoppingCartContext>({});
// Remove ts error
type IShoppingCartChildren = {
    children: ReactNode
}
// Create a provider component
export const ShoppingCartProvider = ({ children }: IShoppingCartChildren) =>
{
    const [ loadPage, setLoadPage ] = useState<boolean>(false)
    const [ toggleSidebar, setToggleSidebar ] = useState<boolean>(false)
    const [ toggleUpdate, setToggleUpdate ] = useState<boolean>(false)
    const [ modal, setModal ] = useState<boolean>(false)
    const [ modalAttr, setModalAttr ] = useState<IModal>({ title: '', content: '', buttons: '', thinking: false })
    const [ defWHFilter, setDefWHFilter ] = useState<boolean>(false)

    const filterList = ["bepc", "bevp"]
    // This keeps the page always updated
    // Issues occur when this doesn't fire, like the sidebar not communicating with the
    // products pages "add-to-cart/buy" update
    if(loadPage) {
        setLoadPage(false)
    }
    if(!defWHFilter) {
        setProductFilterWH(filterList)
        setDefWHFilter(true)
    }
    return (
        defWHFilter? <ShoppingCartContext.Provider value={{
            toggleSidebar: setToggleSidebar,
            toggleSidebarStatus: toggleSidebar,
            setToggleUpdate: setToggleUpdate,
            toggleUpdate: toggleUpdate,
            reloadSite: setLoadPage,
            reloaded: loadPage,
            setModal: setModal,
            modal: modal,
            setModalAttr: setModalAttr,
            modalAttr: modalAttr,
        }}>
            { children }
        </ShoppingCartContext.Provider> : <CircularProgress />
    )
}